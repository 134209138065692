import { SimpleGrid, Text } from "@mantine/core";
import { CustomLink, MedicionesCard } from "../../components";
import { useAuth } from "../../context";
import { TMedicion } from "../../types";
import { useStyles } from ".";
import { MedicionesCardSkeleton } from "./_medicionesCardSkeleton";
import { useTallyUrl } from "../../hooks/useTallyUrl";

type MedicionesPageCardProps = {
  mediciones: TMedicion[];
};

export const MedicionesPageCards = ({ mediciones }: MedicionesPageCardProps) => {
  const { loading } = useAuth();
  const tallyUrl = useTallyUrl();

  return (
    <>
      {!mediciones || loading || !tallyUrl ? (
        <MedicionesCardSkeleton />
      ) : (
        <>
          {mediciones.length === 0 ? (
            <Text size="md" color="dimmed">
              En este momento no cuentas con una medición activa. Para iniciar
              tu siguiente medición ELSA,{" "}
              <CustomLink to={tallyUrl} target="_blank" fontSize="14">
                contáctanos aquí
              </CustomLink>
              .
            </Text>
          ) : (
            <SimpleGrid
              cols={3}
              spacing="lg"
              breakpoints={[
                { maxWidth: "xl", cols: 2 },
                { maxWidth: "md", cols: 1 },
              ]}
            >
              {mediciones.map((medicion) => (
                <MedicionesCard key={medicion.id} medicion={medicion} />
              ))}
            </SimpleGrid>
          )}
        </>
      )}
    </>
  );
};
