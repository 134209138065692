import { Flex } from "@mantine/core";
import { BenchmarkBarrerasGeneralesCard } from "./BenchmarkBarrerasGeneralesCard";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { colorRojoClaro1 } from "../../utils";
import { ResultadosCard } from "../Resultados";
import { useTranslation } from "react-i18next";

export const BenchmarkBarrerasCard = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard
            title={t("benchmark.barriers.title")}
            description={t("benchmark.barriers.description")}
        >
            <Flex
                direction={{ base: "column", lg: "row" }}
                gap="md"
                justify="center"
                align="center"
            >
                <Flex
                    direction={{ base: "column", lg: "row" }}
                    gap="lg"
                    align="center"
                >
                    <Flex direction="column" gap="xs">
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#f9d6d6", colorRojoClaro1]}
                            title={t("benchmark.barriers.cardTitleFear")}
                            subtitles={[
                                t("benchmark.barriers.wasEmbarrassed"),
                                t("benchmark.barriers.troublemaker"),
                                t("benchmark.barriers.fearOfRetaliation")
                            ]}
                            percentages={[
                                generalBenchmark.benchmarks.RB_0151,
                                generalBenchmark.benchmarks.RB_0152,
                                generalBenchmark.benchmarks.RB_0153,
                            ]}
                        />
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#a4d7d4", "#109089"]}
                            title={t("benchmark.barriers.cardTitleLackOfInfo")}
                            subtitles={[
                                t("benchmark.barriers.notSure"),
                                t("benchmark.barriers.noChannel")
                            ]}
                            percentages={[
                                generalBenchmark.benchmarks.RB_0154,
                                generalBenchmark.benchmarks.RB_0155,
                            ]}
                        />
                    </Flex>
                    <Flex direction="column" gap="xs">
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#add4f0", "#4fa0db"]}
                            title={t("benchmark.barriers.cardTitleExogenousFactors")}
                            subtitles={[
                                t("benchmark.barriers.coupleProblems"),
                            ]}
                            percentages={[generalBenchmark.benchmarks.RB_0156]}
                        />
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#c2c3c8", "#626161"]}
                            title={t("benchmark.barriers.cardTitleLackOfTrust")}
                            subtitles={[
                                t("benchmark.barriers.dontThinkItWillHelp"),
                            ]}
                            percentages={[generalBenchmark.benchmarks.RB_0157]}
                        />
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#dccfe7", "#500f82"]}
                            title={t("benchmark.barriers.cardTitleConsiderationsWithHarasser")}
                            subtitles={[
                                t("benchmark.barriers.noBadIntentions"),
                                t("benchmark.barriers.dontWantToCauseProblems"),
                            ]}
                            percentages={[
                                generalBenchmark.benchmarks.RB_0158,
                                generalBenchmark.benchmarks.RB_0159,
                            ]}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </ResultadosCard>
    );
}