import { Button, Popover, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AiOutlineInfoCircle } from "react-icons/ai";

const useStyles = createStyles((theme) => ({
  infoButton: {
    "&:hover": {
      backgroundColor: "white",
    },
    margin: 0,
    padding: 0,
  },
}));

type Props = {
  tooltipText: React.ReactNode;
  width?: number;
  position?: "top" | "bottom" | "left" | "right";
  size?: "sm" | "md" | "lg" | "xl";
};

export const InfoTooltip = ({
  tooltipText,
  width = 300,
  position = "top",
  size = "sm",
}: Props) => {
  const { classes } = useStyles();
  const [opened, { close, open }] = useDisclosure(false);

  const shouldShowTooltip = tooltipText !== "" && tooltipText !== null;

  return (
    <Popover
      width={width}
      position={position}
      shadow="md"
      opened={opened && shouldShowTooltip}
    >
      <Popover.Target>
        <Button
          onMouseEnter={() => shouldShowTooltip && open()}
          onMouseLeave={close}
          compact
          variant="subtle"
          size={size}
          className={classes.infoButton}
        >
          <AiOutlineInfoCircle />
        </Button>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          pointerEvents: "none",
        }}
      >
        {tooltipText}
      </Popover.Dropdown>
    </Popover>
  );
};
