import {
  createStyles,
  Card,
  Text,
  Badge,
  Flex,
  Divider,
  Progress,
  Container,
  Button,
  Center,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { TMedicion } from "../../types";
import { useMedicionActual } from "../../context";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

type Props = {
  medicion: TMedicion;
};

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },
  titulo: {
    fontWeight: 700,
    lineHeight: 1,
  },
  lead: {
    fontWeight: 500,
    lineHeight: 1,
  },
  subtitulo: {
    fontWeight: 500,
    fontSize: 16,
  },
  titleSection: {
    backgroundColor: theme.white,
  },
}));

type MedicionesProgressProps = {
  value: number;
};

const MedicionesProgress = ({ value }: MedicionesProgressProps) => {
  const minShownPercentage = 15;
  return (
    <Progress
      value={
        value < minShownPercentage && value > 0 ? minShownPercentage : value
      }
      label={
        value < minShownPercentage && value > 0
          ? `<${minShownPercentage}%`
          : value.toString() + "%"
      }
      size="xl"
      radius="xl"
      mt="xl"
      color={value < 100 ? "orange" : "green"}
    />
  );
};

export const MedicionesCard = ({ medicion }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setMedicionActualCookie } = useMedicionActual();

  const onClick = () => {
    setMedicionActualCookie(medicion);
    navigate(`/medicion/${medicion.code}`);
  };

  interface ConditionColors {
    [key: string]: string;
  }
  
  const colorConditions: ConditionColors = {
    "En preparación": "yellow",
    "En curso": "blue",
    "Completada": "green",
    "Pasada": "gray",
  };
  
  function getColorForCondition(condition: keyof ConditionColors): string {
    return colorConditions[condition] || "black"; // Default to black for unknown conditions
  }

  const mapCondition: { [key: string]: { [key: string]: string } } = {
    "es": {
      "En preparación": "En preparación",
      "En curso": "En curso",
      "Completado": "Completado",
    },
    "en": {
      "En preparación": "In preparation",
      "En curso": "In progress",
      "Completado": "Completed",
    },
  };

  return (
    <Card withBorder p="xl" radius="md" className={classes.card}>
      <Card.Section className={classes.titleSection}>
        <Container px="xl" py="lg">
          <Flex
            justify="space-between"
            align="center"
            style={{ height: "55px" }}
          >
            <Text size="xl" className={classes.titulo}>
              {medicion.title}
            </Text>
            <Flex style={{ display: "inline-block" }} justify="flex-end">
              <Badge
                color={
                  getColorForCondition(medicion.condition as keyof ConditionColors)
                }
              >
                {medicion.condition ? mapCondition[i18n.language][medicion.condition] : ""}
              </Badge>
            </Flex>
          </Flex>
        </Container>
      </Card.Section>

      <Card.Section>
        <Divider />
      </Card.Section>

      <Card.Section>
        <Container px="xl" py="lg">
          <Flex
            gap="sm"
            align="center"
            justify="space-between"
            style={{ height: "50px" }}
          >
            <Text className={classes.subtitulo}>{t("orgSurvey")}</Text>
            <Badge
              color={
                medicion.organizational_survey.is_completed ? "green" : "red"
              }
            >
              {medicion.organizational_survey.is_completed
                ? t("status.completedFemale")
                : t("status.notCompletedFemale")}
            </Badge>
          </Flex>

          <Divider mt="lg" />

          <Text className={classes.subtitulo} mt="lg">
            {t("employeeSurvey")}
          </Text>

          <Flex gap="sm" mt="lg" justify="space-evenly">
            <Flex direction="column">
              <Text className={classes.lead}>
                {medicion.staff_survey.launched_at || t("notEstablished")}
              </Text>
              <Text size="xs" color="dimmed">
                {t("startDate")}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text className={classes.lead}>
                {medicion.staff_survey.ended_at || t("notEstablished")}
              </Text>
              <Text size="xs" color="dimmed">
                {t("endDate")}
              </Text>
            </Flex>
          </Flex>

          <MedicionesProgress
            value={medicion.staff_survey.minimum_sample_progress * 100}
          />
          <Text size="xs" color="dimmed">
            {t("progressMinimumSample")}
          </Text>

          {/* <MedicionesProgress
            value={medicion.staff_survey.total_population_progress}
          />
          <Text size="xs" color="dimmed">
            trabajadores que han llenado la encuesta
          </Text> */}

          <Center mt="lg">
            <Button color="teal" variant="outline" onClick={onClick}>
              {t("viewDetail")}
            </Button>
          </Center>
        </Container>
      </Card.Section>
    </Card>
  );
};
