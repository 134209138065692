import { Badge, Flex, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { GeneralBenchmarkProps } from "../GeneralBenchmarkProps";
import { indexColors, roundToOneDecimal } from "../../../utils";
import { ResultadosCard, Tacometro } from "../../Resultados";
import { InfoTooltip } from "../../Miscellaneous";
import { useTranslation } from "react-i18next";

export const BenchmarkIndice = ({
  generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
  const { t } = useTranslation();
  const [nivelIndice, setNivelIndice] = useState<{
    color: string;
    nombre: string;
  }>(null!);

  useEffect((): void => {
    const processIndexLevel = (indice: number) => {
      indice = indice * 100;
      if (indice <= 60) {
        setNivelIndice({ color: indexColors.inicial, nombre: t("level.initial") });
      } else if (indice <= 70) {
        setNivelIndice({ color: indexColors.intermedio, nombre: t("level.intermediate") });
      } else if (indice <= 80) {
        setNivelIndice({
          color: indexColors.intermedioAlto,
          nombre: t("level.upperIntermediate"),
        });
      } else if (indice <= 90) {
        setNivelIndice({ color: indexColors.alto, nombre: t("level.high") });
      } else {
        setNivelIndice({ color: indexColors.muyAlto, nombre: t("level.veryHigh") });
      }
    };
    processIndexLevel(generalBenchmark.benchmarks.IRB_0001);
  }, [generalBenchmark]);

  return (
    <ResultadosCard alignSelf="center">
      <Flex direction="column" align="center">
        <Title order={5}>{t("benchmark.aggregatedScore")}</Title>
        <Tacometro
          value={roundToOneDecimal(generalBenchmark.benchmarks.IRB_0001 * 100)}
          big
        />
        <Flex direction="column" align="center">
          <Flex align="center" gap={5}>
            <Title order={5}>{t("level.title")}</Title>
            <InfoTooltip
              tooltipText={
                <>
                  <li>{t("level.initial")} (0 - 60 {t("points")})</li>
                  <li>{t("level.intermediate")} (61 - 70 {t("points")})</li>
                  <li>{t("level.upperIntermediate")} (71 - 80)</li>
                  <li>{t("level.high")} (81 - 90)</li>
                  <li>{t("level.veryHigh")} (91 - 100)</li>
                </>
              }
            />
          </Flex>
          {nivelIndice && (
            <Badge
              sx={{
                backgroundColor: nivelIndice.color,
                color: "white",
              }}
            >
              {nivelIndice.nombre}
            </Badge>
          )}
        </Flex>
      </Flex>
    </ResultadosCard>
  );
};
