import { useTranslation } from "react-i18next";
import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkWithResponsiveStyleProps } from "./GeneralBenchmarkProps";
import { splitTextWithMaxLength } from "../../utils/splitTextWithMaxLength";

export const BenchmarkReaccionesCard = ({
    generalBenchmark,
    responsiveStyle
}: GeneralBenchmarkWithResponsiveStyleProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard
            title={t("benchmark.reactions.title")}
            description={t("benchmark.reactions.description")}
        >
            <BarChart
                height={500}
                width={650}
                responsive={responsiveStyle}
                categories={[
                    splitTextWithMaxLength(t("benchmark.reactions.categories.avoided")),
                    splitTextWithMaxLength(t("benchmark.reactions.categories.confronted")),
                    splitTextWithMaxLength(t("benchmark.reactions.categories.toldColleague")),
                    splitTextWithMaxLength(t("benchmark.reactions.categories.keptSilent")),
                    splitTextWithMaxLength(t("benchmark.reactions.categories.toldLeader")),
                    splitTextWithMaxLength(t("benchmark.reactions.categories.changedJobPlace"), 20),
                    splitTextWithMaxLength(t("benchmark.reactions.categories.reported")),
                    splitTextWithMaxLength(t("benchmark.reactions.categories.toldHR")),
                ]}
                seriesNames={[t("percentage") + "(%)"]}
                seriesData={[
                    [
                        generalBenchmark.benchmarks.RB_0143,
                        generalBenchmark.benchmarks.RB_0144,
                        generalBenchmark.benchmarks.RB_0145,
                        generalBenchmark.benchmarks.RB_0146,
                        generalBenchmark.benchmarks.RB_0147,
                        generalBenchmark.benchmarks.RB_0148,
                        generalBenchmark.benchmarks.RB_0149,
                        generalBenchmark.benchmarks.RB_0150,
                    ],
                ]}
                max={100}
            />
        </ResultadosCard>
    );
}