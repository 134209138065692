import {
  Title,
  Flex,
  Grid,
  Text,
  Button,
  ScrollArea,
  Center,
} from "@mantine/core";
import { openModal } from "@mantine/modals";
import { useEffect, useState } from "react";

import { TPrevalence } from "../../types";
import {
  BarChart,
  BarrerasGeneralesCard,
  Heatmap,
  PieChart,
  ResultadosCard,
  SectionTitle,
  GenderLabLoader,
  PrevalenciaTooltip,
} from "../../components";
import {
  colorAmarillo1,
  colorAzul1,
  colorCeleste1,
  colorRojoClaro1,
  parsePercentage,
} from "../../utils";
import {
  PrevalenceMetricsChart,
  SelectCortesData,
  TitleCard,
} from "../../components/Resultados";
import { useTranslation } from "react-i18next";
import { splitTextWithMaxLength } from "../../utils/splitTextWithMaxLength";

type Props = {
  prevalence: TPrevalence | null;
};

export const PrevalenciaResultados = ({ prevalence }: Props) => {
  const { t } = useTranslation();
  const [prevalenceCategories, setPrevalenceCategories] = useState<
    string[] | null
  >(null);
  const [prevalenceData, setPrevalenceData] = useState<number[] | null>(null);

  const [totalManifestaciones, setTotalManifestaciones] = useState<{
    name: string;
    data: number[];
  } | null>(null);

  const [heatmapData, setHeatmapData] = useState<{
    [key: string]: { [key: string]: number };
  } | null>(null);

  const situationHeatmapCategories = [
    "M1",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "M7",
    "M8",
    "M9",
    "M10",
    "M11",
    "M12",
  ];

  const responsiveBarStylesPrevalencia = [
    {
      breakpoint: 768,
      options: {
        chart: {
          width: 300,
        },
      },
    },
  ];

  useEffect(() => {
    if (prevalence) {
      setTotalManifestaciones({
        name: t("total"),
        data: Object.values(prevalence.situations.situations).map((value) =>
          parsePercentage(value)
        ),
      });
      setHeatmapData(prevalence.situations.gender);
      setPrevalenceCategories([
        t("total"),
        ...Object.keys(prevalence.frequency.prevalence_by_gender),
      ]);
      setPrevalenceData([
        prevalence.frequency.technical_harassment_prevalence,
        ...Object.values(prevalence.frequency.prevalence_by_gender),
      ]);
    }
  }, [prevalence]);

  const onClickOtrasReacciones = () => {
    openModal({
      title: <Title order={4}>Otras barreras para la denuncia</Title>,
      children: (
        <ScrollArea style={{ maxHeight: 500 }}>
          <ul>
            {prevalence?.actions.non_reporting_reasons.others.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </ScrollArea>
      ),
      size: "xl",
    });
  };

  const onSelectChangeHeatmap = (value: string | null) => {
    if (prevalence) {
      switch (value) {
        case "genero":
          setHeatmapData(prevalence.situations.gender);
          break;
        case "edad":
          setHeatmapData(prevalence.situations.age);
          break;
        case "orientacion-sexual":
          setHeatmapData(prevalence.situations.sexual_orientation);
          break;
        case "grupo-etnico-racial":
          setHeatmapData(prevalence.situations.ethnicity);
          break;
        case "puesto-de-trabajo":
          setHeatmapData(prevalence.situations.job);
          break;
        case "area":
          setHeatmapData(prevalence.situations.area);
          break;
        case "centro-de-trabajo":
          setHeatmapData(prevalence.situations.workplace);
          break;
        case "turno":
          setHeatmapData(prevalence.situations.work_shift);
          break;
      }
    }
  };

  const onSelectChangePrevalence = (value: string | null) => {
    if (prevalence) {
      switch (value) {
        case "genero":
          setPrevalenceCategories([
            t("total"),
            ...Object.keys(prevalence.frequency.prevalence_by_gender),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_gender),
          ]);
          break;
        case "edad":
          setPrevalenceCategories([
            t("total"),
            ...Object.keys(prevalence.frequency.prevalence_by_age),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_age),
          ]);
          break;
        case "orientacion-sexual":
          setPrevalenceCategories([
            t("total"),
            ...Object.keys(
              prevalence.frequency.prevalence_by_sexual_orientation
            ),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(
              prevalence.frequency.prevalence_by_sexual_orientation
            ),
          ]);
          break;
        case "grupo-etnico-racial":
          setPrevalenceCategories([
            t("total"),
            ...Object.keys(prevalence.frequency.prevalence_by_ethnicity),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_ethnicity),
          ]);
          break;
        case "puesto-de-trabajo":
          setPrevalenceCategories([
            t("total"),
            ...Object.keys(prevalence.frequency.prevalence_by_job),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_job),
          ]);
          break;
        case "area":
          setPrevalenceCategories([
            t("total"),
            ...Object.keys(prevalence.frequency.prevalence_by_area),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_area),
          ]);
          break;
        case "centro-de-trabajo":
          setPrevalenceCategories([
            t("total"),
            ...Object.keys(prevalence.frequency.prevalence_by_workplace),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_workplace),
          ]);
          break;
        case "turno":
          setPrevalenceCategories([
            t("total"),
            ...Object.keys(prevalence.frequency.prevalence_by_work_shift),
          ]);
          setPrevalenceData([
            prevalence.frequency.technical_harassment_prevalence,
            ...Object.values(prevalence.frequency.prevalence_by_work_shift),
          ]);
          break;
      }
    }
  };

  if (!prevalence || !totalManifestaciones || !heatmapData) {
    return <GenderLabLoader />;
  } else
    return (
      <Flex direction="column" gap="md">
        <SectionTitle
          title={t("tabs.prevalence")}
          description="Nivel de incidencia, reacciones e impacto del hostigamiento o acoso sexual laboral en tu organización."
        />
        <Flex direction={{ base: "column", md: "row" }} gap="md">
          <ResultadosCard
            title={t("tabs.prevalence")}
            description="Porcentaje de personas que experimentó acoso u hostigamiento sexual en el último año."
            titleChildren={<PrevalenciaTooltip />}
          >
            <Flex justify="center">
              <PrevalenceMetricsChart
                currentTechHarrassment={
                  prevalence.frequency.technical_harassment_prevalence
                }
                currentDeclaredHarrassment={
                  prevalence.frequency.declared_harassment_prevalence
                }
              />
            </Flex>
          </ResultadosCard>
          <ResultadosCard
            title="Frecuencia"
            description="Frecuencia con la que se reportó que estas situaciones se presentaban."
          >
            <PieChart
              height={400}
              labels={prevalence.frequency.frequency_technical_harassment.map(
                (obj) => obj.description
              )}
              series={prevalence.frequency.frequency_technical_harassment.map(
                (obj) => obj.count
              )}
            />
          </ResultadosCard>
        </Flex>

        <ResultadosCard
          title="Prevalencia en la organización por perfil"
          description="Nivel de incidencia de acoso sexual laboral por cada grupo poblacional."
          titleChildren={
            <SelectCortesData
              onSelectChange={onSelectChangePrevalence}
              genderDisabled={!prevalence.situations?.gender}
              ageDisabled={!prevalence.situations?.age}
              sexualOrientationDisabled={
                !prevalence.situations?.sexual_orientation
              }
              ethnicityDisabled={!prevalence.situations?.ethnicity}
              jobDisabled={!prevalence.situations?.job}
              areaDisabled={!prevalence.situations?.area}
              workplaceDisabled={!prevalence.situations?.workplace}
              workShiftDisabled={!prevalence.situations?.work_shift}
            />
          }
        >
          <BarChart
            height={500}
            width={600}
            max={
              prevalenceData
                ? Math.min(
                    Math.ceil((Math.max(...prevalenceData) * 100) / 5) * 5,
                    100
                  )
                : 100
            }
            responsive={responsiveBarStylesPrevalencia}
            categories={prevalenceCategories || []}
            horizontal={true}
            seriesData={prevalenceData ? [prevalenceData] : []}
          />
        </ResultadosCard>
        <ResultadosCard
          title="Principales manifestaciones que impactan en la organización"
          description="Situaciones identificadas por las personas que han vivido hostigamiento o acoso sexual laboral."
          titleChildren={
            <SelectCortesData
              onSelectChange={onSelectChangeHeatmap}
              genderDisabled={!prevalence.situations?.gender}
              ageDisabled={!prevalence.situations?.age}
              sexualOrientationDisabled={
                !prevalence.situations?.sexual_orientation
              }
              ethnicityDisabled={!prevalence.situations?.ethnicity}
              jobDisabled={!prevalence.situations?.job}
              areaDisabled={!prevalence.situations?.area}
              workplaceDisabled={!prevalence.situations?.workplace}
              workShiftDisabled={!prevalence.situations?.work_shift}
            />
          }
        >
          <Heatmap
            data={[
              totalManifestaciones,
              ...Object.keys(heatmapData).map((name) => ({
                name: name,
                data: Object.values(heatmapData[name]).map((value) =>
                  parsePercentage(value)
                ),
              })),
            ]}
            categories={situationHeatmapCategories}
          />
          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M1: Comentarios o bromas de contenido sexual o sexista.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M2: Comentarios o bromas sobre de su orientación sexual o
                identidad de género.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M3: Comentarios sobre su apariencia física o cuerpo que le
                generaron incomodidad, incluyendo también gestos, silbidos o
                sonidos.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M4: Invitaciones reiteradas a salir a pesar de que no ofreció
                respuesta o no dio respuesta positiva.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M5: Contactos reiterados a través de mensajes electrónicos,
                chats, WhatsApp, Facebook, entre otros, con fines no laborales
                que le incomodaron.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M6: Solicitudes o envíos sin consentimiento de fotos o videos de
                material sexual explícito o de connotación sexual.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M7: Acercamientos físicos o tocamientos (rozar, sobar, masajear,
                etc.) sin necesidad en alguna parte del cuerpo como hombros,
                espalda baja, rodilla u otras partes.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M8: Tocamientos y/o rozamientos en zonas privadas del cuerpo,
                como senos, genitales, etc.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M9: Miradas persistentes con connotación sexual.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M10: Insinuaciones o invitaciones a salir de un jefe/a a pesar
                de que de alguna manera le expresó su incomodidad.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M11: Insinuaciones o propuestas de mejoras en su situación
                laboral a cambio de aceptar invitaciones, acercamientos o
                encuentros sexuales.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M12: Amenazas o insinuaciones de despido, retiro de beneficios o
                tomar alguna represalia por no aceptar invitaciones,
                acercamientos, o encuentros sexuales.
              </Text>
            </Grid.Col>
          </Grid>
        </ResultadosCard>
        <TitleCard
          title="Métricas asociadas a Acoso u Hostigamiento Sexual Laboral Declarado"
          description="Calculadas en base a las personas que reconocieron haber vivido acoso sexual laboral y las acciones que tomaron, costos asociados, barreras para la denuncia y perfil de las personas que realizaron estas conductas."
        />
        <Flex direction={{ base: "column", lg: "row" }} gap="md">
          <ResultadosCard
            title={t("benchmark.reactions.title")}
            description={t("benchmark.reactions.description")}
          >
            <BarChart
              height={500}
              width={600}
              responsive={responsiveBarStylesPrevalencia}
              categories={prevalence.actions.taken_actions_by_victims.map(
                (action) =>
                  action[0]
                    .split(" ")
                    .reduce((acc: string[], word, index, words) => {
                      if (index % 4 === 0)
                        acc.push(words.slice(index, index + 4).join(" "));
                      return acc;
                    }, [])
              )}
              seriesNames={[t("percentage") + "(%)"]}
              seriesData={[
                prevalence.actions.taken_actions_by_victims.map(
                  (action) => action[1]
                ),
              ]}
              max={100}
            />
          </ResultadosCard>
          <ResultadosCard
            title="Costos asociados al hostigamiento o acoso sexual laboral"
            description="Afectaciones en el bienestar y la productividad de quienes han sufrido acoso sexual laboral."
          >
            <BarChart
              height={500}
              width={600}
              responsive={responsiveBarStylesPrevalencia}
              colors={[colorAmarillo1, colorCeleste1]}
              categories={[
                "Sintió estrés",
                "Sintió ansiedad",
                ["Experimentó dificultades", " para concentrarse"],
                "Redujo su productividad",
                "Faltó al trabajo",
              ]}
              max={100}
              seriesNames={["Hostigamiento o acoso sexual laboral declarado"]}
              seriesData={[
                [
                  prevalence.costs.stress.declared,
                  prevalence.costs.anxiety.declared,
                  prevalence.costs.concentration_problems.declared,
                  prevalence.costs.decreased_productivity.declared,
                  prevalence.costs.absenteeism.declared,
                ],
              ]}
            />
          </ResultadosCard>
        </Flex>
        <ResultadosCard
          title={t("benchmark.barriers.title")}
          description={t("benchmark.barriers.description")}
        >
          <Flex
            direction={{ base: "column", lg: "row" }}
            gap="md"
            justify="center"
            align="center"
          >
            <Flex
              direction={{ base: "column", lg: "row" }}
              gap="lg"
              align="center"
            >
              <Flex direction="column" gap="xs">
                <BarrerasGeneralesCard
                  colors={["#f9d6d6", colorRojoClaro1, colorRojoClaro1]}
                  title={t("benchmark.barriers.cardTitleFear")}
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .fear
                  }
                  subtitles={[
                    t("benchmark.barriers.wasEmbarrassed"),
                    t("benchmark.barriers.troublemaker"),
                    t("benchmark.barriers.fearOfRetaliation")
                  ]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "Tenía vergüenza."
                    ],
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No quería ser vista como una persona problemática."
                    ],
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "Tenía miedo de peder su trabajo o recibir represalias."
                    ],
                  ]}
                />
                <BarrerasGeneralesCard
                  colors={["#cde9e7", "#7cc5c1", "#109089"]}
                  title={t("benchmark.barriers.cardTitleLackOfInfo")}
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .misinformation
                  }
                  subtitles={[
                    t("benchmark.barriers.notSure"),
                    t("benchmark.barriers.noChannel")
                  ]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No estaba seguro/a de que fuera un caso de acoso sexual laboral."
                    ],
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No sabía o no existe un canal de denuncia."
                    ],
                  ]}
                />
              </Flex>
              <Flex direction="column" gap="xs">
                <BarrerasGeneralesCard
                  colors={["#ddedf8", "#add4f0", "#4fa0db"]}
                  title={t("benchmark.barriers.cardTitleExogenousFactors")}
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .others_reasons
                  }
                  subtitles={[
                    t("benchmark.barriers.coupleProblems"),
                  ]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "Tenía miedo de tener problemas con mi pareja."
                    ],
                  ]}
                />
                <BarrerasGeneralesCard
                  colors={["#e7e7e9", "#c2c3c8", "#626161"]}
                  title={t("benchmark.barriers.cardTitleLackOfTrust")}
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .distrust
                  }
                  subtitles={[
                    t("benchmark.barriers.dontThinkItWillHelp"),
                  ]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "Piensa que no sirve denunciar."
                    ],
                  ]}
                />
                <BarrerasGeneralesCard
                  colors={["#dccfe7", "#a482be", "#500f82"]}
                  title="Consideraciones con acosador/a"
                  percentage={
                    prevalence.actions.non_reporting_reasons.categories_rank
                      .consideration_with_stalker
                  }
                  subtitles={[
                    t("benchmark.barriers.noBadIntentions"),
                    t("benchmark.barriers.dontWantToCauseProblems"),
                  ]}
                  percentages={[
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No les pareció de mala intención."
                    ],
                    prevalence.actions.non_reporting_reasons.reasons_rank[
                      "No quería ocasionarle problemas a la otra persona."
                    ],
                  ]}
                />
              </Flex>
            </Flex>
          </Flex>
          <Center mt="md">
            <Button variant="light" onClick={onClickOtrasReacciones} size="lg">
              Ver otras barreras
            </Button>
          </Center>
        </ResultadosCard>
        <ResultadosCard
          title={t("benchmark.harasserProfile.title")}
          description={t("benchmark.harasserProfile.description")}
        >
          <Flex
            direction={{ base: "column", xl: "row" }}
            gap="md"
            justify="space-evenly"
            align="center"
          >
            <BarChart
              width={600}
              colors={[colorAzul1]}
              categories={[
                t("men"),
                t("women"),
              ]}
              responsive={responsiveBarStylesPrevalencia}
              title={t("benchmark.harasserProfile.genderTitle")}
              seriesData={[
                [
                  prevalence.stalker_profile.gender_of_stalker.men,
                  prevalence.stalker_profile.gender_of_stalker.women,
                ],
              ]}
            />
            <BarChart
              width={600}
              categories={[
                t("benchmark.harasserProfile.categories.colleague"),
                t("benchmark.harasserProfile.categories.boss"),
                t("benchmark.harasserProfile.categories.subordinate"),
                splitTextWithMaxLength(t("benchmark.harasserProfile.categories.otherWorker")),
                t("benchmark.harasserProfile.categories.client"),
            ]}
              colors={[colorAzul1]}
              responsive={responsiveBarStylesPrevalencia}
              title={t("benchmark.harasserProfile.relationshipTitle")}
              seriesData={[
                [
                  prevalence.stalker_profile.relationship_with_stalker.coworker,
                  prevalence.stalker_profile.relationship_with_stalker.boss,
                  prevalence.stalker_profile.relationship_with_stalker
                    .subordinate,
                  prevalence.stalker_profile.relationship_with_stalker.supplier,
                  prevalence.stalker_profile.relationship_with_stalker.client,
                ],
              ]}
            />
          </Flex>
        </ResultadosCard>
      </Flex>
    );
};
