export function splitTextWithMaxLength(text: string, maxLength: number = 23): string[] {
    const words = text.split(' ');
    const result: string[] = [];
    let currentLine = '';

    words.forEach(word => {
        if (currentLine.length + word.length + 1 > maxLength) {
            result.push(currentLine.trim());
            currentLine = word;
        } else {
            currentLine += (currentLine ? ' ' : '') + word;
        }
    });

    if (currentLine) {
        result.push(currentLine.trim());
    }

    return result;
}