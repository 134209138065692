import { Flex } from "@mantine/core";
import { PorcentajeCardSmall, ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { colorAzul1 } from "../../utils";
import { useTranslation } from "react-i18next";

export const BenchmarkTestigos = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard
            title={t("benchmark.witness.title")}
            description={t("benchmark.witness.description")}
        >
            <Flex
                direction={{ base: "column", md: "row" }}
                gap="sm"
                justify="space-evenly"
                align="center"
            >
                <PorcentajeCardSmall
                    description={t("benchmark.witness.percentageCardDescription")}
                    percentage={generalBenchmark.benchmarks.RB_0167}
                    color={colorAzul1}
                />
            </Flex>
        </ResultadosCard>
    );
}