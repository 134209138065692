import { useTranslation } from "react-i18next";
import { splitTextWithMaxLength } from "../../utils/splitTextWithMaxLength";
import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";

export const BenchmarkConceptoChart = ({
  generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ResultadosCard
      title={t("benchmark.toleranceBarChartTitle")}
      description={t("benchmark.toleranceBarChartDescription")}
    >
      <BarChart
        categories={[
          splitTextWithMaxLength(t("answerCategories.expressRejection"), 16),
          splitTextWithMaxLength(t("answerCategories.happenMoreThanOnce"), 16),
          splitTextWithMaxLength(t("answerCategories.intentionToHarass"), 16),
          splitTextWithMaxLength(t("answerCategories.comingFromBoss"), 16),
        ]}
        seriesData={[
          [
            generalBenchmark.benchmarks.RB_0006,
            generalBenchmark.benchmarks.RB_0005,
            generalBenchmark.benchmarks.RB_0007,
            generalBenchmark.benchmarks.RB_0008,
          ],
        ]}
        seriesNames={["Benchmark"]}
        height={400}
        width={600}
      />
    </ResultadosCard>
  );
};
