import {
  Avatar,
  Image,
  Text,
  useMantineTheme,
  Menu,
  UnstyledButton,
  Flex,
  MediaQuery,
} from "@mantine/core";

import { FiLogOut } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context";
import { useTranslation } from "react-i18next";

export function User() {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  if (user) {
    return (
      <Menu position="top" offset={20} width={215} radius="lg">
        <Menu.Target>
          <UnstyledButton
            sx={{
              display: "block",
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.black,

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.gray[0],
              },
            }}
          >
            <Flex direction="row" align="center" gap="sm" justify="flex-end">
              <Avatar src={user.logo} radius="xl" size={40} />
              {user.tradeName && (
                <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                  <Text size="lg">{user.tradeName}</Text>
                </MediaQuery>
              )}
              <Image src="/img/dots.png" width={20} />
            </Flex>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown sx={() => ({ marginLeft: "-10px" })}>
          <Menu.Item
            onClick={() => navigate("/mi-cuenta")}
            icon={<AiOutlineUser size={16} />}
          >
            {t("appShell.profile")}
          </Menu.Item>
          <Menu.Item onClick={logout} icon={<FiLogOut size={16} />}>
            {t("appShell.logout")}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  } else {
    return <></>;
  }
}
