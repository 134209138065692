import {
  Text,
  Flex,
  Title,
  Progress,
  createStyles,
  Divider,
} from "@mantine/core";
import { TSample } from "../../types";
import {
  BarChart,
  DistribucionPerfilLaboral,
  PieChart,
  PieChartPerfilPersonal,
  ResultadosCard,
  SectionTitle,
  TasaDeParticipacionTooltip,
  TitleCard,
  GenderLabLoader,
} from "../../components";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  perfilLaboralContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.sm,
  },
  perfilLaboralItem: {
    flex: `0 1 calc(100%)`,
  },

  twoRowContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: theme.spacing.sm,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  twoRowItem: {
    flex: `0 1 calc(50% - ${theme.spacing.sm}px)`,

    [theme.fn.smallerThan("sm")]: {
      flex: `0 1 calc(100% - ${theme.spacing.sm}px)`,
    },
  },
}));

type Props = {
  sample: TSample | null;
};

export const MuestraResultados = ({ sample }: Props) => {
  const { t } = useTranslation();
  const poblacionGrayColor = "#626161";

  const { classes } = useStyles();

  type TPerfilLaboralDistribucion =
    | "area_distribution"
    | "workplace_distribution"
    | "job_distribution";

  const perfilLaboralDistribucionData: {
    name: string;
    key: TPerfilLaboralDistribucion;
  }[] = [
    { name: "Puesto de trabajo", key: "job_distribution" },
    { name: "Área", key: "area_distribution" },
    { name: "Centro de trabajo", key: "workplace_distribution" },
  ];

  const isLarge = () => {
    if (sample)
      return (
        sample.sexual_orientation_distribution ||
        sample.age_distribution ||
        sample.ethnicity_distribution
      );
  };

  return (
    <>
      {sample ? (
        <Flex direction="column" gap="md">
          <SectionTitle
            title={t("tabs.sample")}
            description="Composición de la muestra y tasa de participación según el perfil personal y el perfil laboral de las personas encuestadas."
          />
          <ResultadosCard title="Población">
            <Flex
              justify="space-evenly"
              align={{ base: "center", sm: "flex-start" }}
              gap="md"
              direction={{ base: "column", sm: "row" }}
            >
              <Flex direction="column" align="center">
                <Title order={5}>Población total</Title>
                <Flex justify="center" align="center">
                  <Text fz={18} color={poblacionGrayColor}>
                    {sample.total_population}
                  </Text>
                  <Text pl={4} color={poblacionGrayColor} fz={14}>
                    personas
                  </Text>
                </Flex>
              </Flex>
              <Flex justify="center">
                <Flex direction="column" align="center">
                  <Title order={5}>Respuestas alcanzadas</Title>
                  <Flex justify="center" align="center">
                    <Text fz={18} color={poblacionGrayColor}>
                      {sample.answers_count}
                    </Text>
                    <Text pl={4} color={poblacionGrayColor} fz={14}>
                      personas
                    </Text>
                  </Flex>
                  <Progress
                    value={Math.round(sample.total_population_progress * 100)}
                    sx={{ alignSelf: "stretch" }}
                    color="green"
                    label={`${Math.round(
                      sample.total_population_progress * 100
                    )}%`}
                    size="xl"
                  />
                </Flex>
              </Flex>
              <Flex direction="column" align="center">
                <Title order={5}>Muestra mínima</Title>
                <Flex justify="center" align="center">
                  <Text fz={18} color={poblacionGrayColor}>
                    {sample.minimum_sample_size}
                  </Text>
                  <Text pl={4} color={poblacionGrayColor} fz={14}>
                    personas
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </ResultadosCard>

          <Divider />

          {isLarge() && (
            <TitleCard
              title="Perfil personal"
              description="Género, pertenencia a la comunidad LGBTIQ+, edad y autoidentificación étnico-racial."
            />
          )}
          <ResultadosCard title={t("gender")}>
            <Flex
              direction={{ base: "column", sm: "row" }}
              justify={{ base: "space-evenly" }}
            >
              <Flex direction="column" gap="md">
                <Text align="center" size="sm" fz={16} fw={500}>
                  Distribución de respuestas por género
                </Text>
                <PieChart
                  labels={[
                    t("men"),
                    t("women"),
                    t("nonBinary")
                  ]}
                  height={325}
                  series={[
                    sample.gender_distribution.men.count,
                    sample.gender_distribution.women.count,
                    sample.gender_distribution.non_binary.count,
                  ]}
                />
              </Flex>
              <Flex direction="column" gap="md">
                <TasaDeParticipacionTooltip title="Tasa de participación por género" />
                <BarChart
                  width={500}
                  categories={[
                    t("men"),
                    t("women"),
                    t("nonBinary")
                  ]}
                  max={150}
                  responsive={[
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: "100%",
                        },
                      },
                    },
                  ]}
                  seriesNames={["Personas (%)"]}
                  seriesData={[
                    [
                      Math.min(sample.gender_distribution.men.progress, 1.5),
                      Math.min(sample.gender_distribution.women.progress, 1.5),
                      Math.min(
                        sample.gender_distribution.non_binary.progress,
                        1.5
                      ),
                    ],
                  ]}
                />
              </Flex>
            </Flex>
          </ResultadosCard>
          {(sample.sexual_orientation_distribution ||
            sample.age_distribution ||
            sample.ethnicity_distribution) && (
            <Flex direction={{ base: "column", md: "row" }} gap="sm">
              {sample.sexual_orientation_distribution && (
                <ResultadosCard title={t("lgbtCommunity")}>
                  <PieChartPerfilPersonal
                    distribution={sample.sexual_orientation_distribution}
                  />
                </ResultadosCard>
              )}
              {sample.age_distribution && (
                <ResultadosCard title={t("ageRange")}>
                  <PieChartPerfilPersonal
                    distribution={sample.age_distribution}
                  />
                </ResultadosCard>
              )}
              {sample.ethnicity_distribution && (
                <ResultadosCard title={t("ethnic.ethnicRacialSelfIdentification")}>
                  <PieChartPerfilPersonal
                    distribution={sample.ethnicity_distribution}
                  />
                </ResultadosCard>
              )}
            </Flex>
          )}

          <Divider />

          {isLarge() && (
            <TitleCard
              title="Perfil laboral"
              description="Conoce el nivel de respuesta de tu personal según área, puesto, centro de trabajo, antigüedad y turno de las personas."
            />
          )}
          <div className={classes.perfilLaboralContainer}>
            {perfilLaboralDistribucionData.map((dist, idx) => {
              if (!sample[dist.key]) return null;
              return (
                <div key={idx}>
                  <DistribucionPerfilLaboral
                    title={dist.name}
                    barChartData={Object.keys(sample[dist.key]).map(
                      (label) => sample[dist.key][label].progress
                    )}
                    categories={Object.keys(sample[dist.key])}
                    pieChartData={Object.keys(sample[dist.key]).map(
                      (key) => sample[dist.key][key].count
                    )}
                  />
                </div>
              );
            })}
            {sample.work_shift_distribution &&
              sample.antiquity_distribution && (
                <div className={classes.perfilLaboralItem}>
                  <div className={classes.twoRowContainer}>
                    <div className={classes.twoRowItem}>
                      <ResultadosCard title="Distribución de respuestas por turno">
                        <PieChart
                          labels={sample.work_shift_distribution.map(
                            (ws) => ws.description
                          )}
                          height={300}
                          series={sample.work_shift_distribution.map(
                            (ws) => ws.count
                          )}
                        />
                      </ResultadosCard>
                    </div>
                    <div className={classes.twoRowItem}>
                      <ResultadosCard title="Distribución de respuestas por antigüedad">
                        <PieChart
                          labels={
                            sample.antiquity_distribution &&
                            sample.antiquity_distribution.map(
                              (ant) => ant.description
                            )
                          }
                          height={300}
                          series={
                            sample.antiquity_distribution &&
                            sample.antiquity_distribution.map(
                              (ant) => ant.count
                            )
                          }
                        />
                      </ResultadosCard>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </Flex>
      ) : (
        <GenderLabLoader />
      )}
    </>
  );
};
