import { Container, Flex, NumberInput, Table } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { clientService } from "../../../api";
import { FormErrorText } from "../../../components";
import { useAuth, useMedicionActual } from "../../../context";
import { TPersonalizationTableData } from "../../../types";
import { GuardarButton } from "./__guardarButton";
import { useTranslation } from "react-i18next";

type Props = {
  rows: TPersonalizationTableData[];
  disabled?: boolean;
};

export const TablaPuestoSmallMedium = ({ rows, disabled }: Props) => {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      lista: rows,
    },

    validate: {
      lista: {
        description: (value) => !value && "Campo requerido",
        tag: (value) => !value && "Campo requerido",
        men_population: (value) => value === undefined && "Campo requerido",
        women_population: (value) => value === undefined && "Campo requerido",
        non_binary_population: (value) =>
          value === undefined && "Campo requerido",
      },
    },
  });

  const [triedToSave, setTriedToSave] = useState(false);
  const { setLoading } = useAuth();
  const { medicionActualCookie } = useMedicionActual();

  const checkRowPopulationDifferentFromZero = (index: number) =>
    form.values.lista[index].men_population +
      form.values.lista[index].women_population +
      form.values.lista[index].non_binary_population !==
    0;

  const guardarFila = async (index: number) => {
    if (
      !form.validate().hasErrors &&
      checkRowPopulationDifferentFromZero(index)
    ) {
      setTriedToSave(false);
      setLoading(true);
      await clientService.measurements.personalization.jobs.put(
        medicionActualCookie?.code,
        form.values.lista[index].id,
        {
          tag: form.values.lista[index].tag,
          description: form.values.lista[index].description,
          men_population: form.values.lista[index].men_population,
          women_population: form.values.lista[index].women_population,
          non_binary_population: form.values.lista[index].non_binary_population,
        }
      );
      setLoading(false);
      showNotification({
        color: "green",
        message: `Se ha actualizado el registro`,
      });
    } else {
      setTriedToSave(true);
    }
  };

  return (
    <Table mt="lg">
      <thead>
        <tr>
          <th scope="col" style={{ width: "20%" }}>
            Descripción
          </th>
          <th scope="col">{t("men")}</th>
          <th scope="col">{t("women")}</th>
          <th scope="col">{t("nonBinary")}</th>
          <th scope="col" style={{ width: "10%" }} />
        </tr>
      </thead>
      <tbody>
        {form.values.lista.map((row, index) => (
          <tr key={index}>
            <td>{row.description}</td>
            <td>
              <NumberInput
                min={0}
                disabled={disabled}
                {...form.getInputProps(`lista.${index}.men_population`)}
              />
            </td>
            <td>
              <NumberInput
                min={0}
                disabled={disabled}
                {...form.getInputProps(`lista.${index}.women_population`)}
              />
            </td>
            <td>
              <NumberInput
                min={0}
                disabled={disabled}
                {...form.getInputProps(`lista.${index}.non_binary_population`)}
              />
            </td>
            <td>
              <Flex direction="column">
                <Container>
                  <GuardarButton
                    onClick={() => guardarFila(index)}
                    disabled={disabled}
                  />
                </Container>
                {!checkRowPopulationDifferentFromZero(index) && triedToSave && (
                  <FormErrorText>El total debe ser mayor que 0.</FormErrorText>
                )}
              </Flex>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
