import { Flex, Text } from "@mantine/core";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { BenchmarkPorcentajeCard } from "./BenchmarkPorcentajeCard";
import { useTranslation } from "react-i18next";

export const BenchmarkReaccionesTestigos = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard
            title={t("benchmark.witness.reactionsTitle")}
            description={t("benchmark.witness.reactionsDescription")}
            alignSelf="flex-start"
        >
            <Flex justify="center">
                <Flex direction="column" gap="xs" maw={600}>
                    <Flex justify="flex-end" gap={10}>
                        <div style={{ width: 80 }}>
                            <Text fz={12} sx={{ fontWeight: 600 }}>
                                {t("percentage")} (%)
                            </Text>
                        </div>
                    </Flex>

                    {Object.entries(generalBenchmark.benchmarks)
                        .filter(([key]) =>
                            [
                                "RB_0021",
                                "RB_0022",
                                "RB_0023",
                                "RB_0024",
                                "RB_0025",
                                "RB_0026",
                                "RB_0027",
                                "RB_0028",
                            ].includes(key)
                    )
                    .sort(([, a], [, b]) => b - a)
                    .map(([key, percentage]) => {
                        const descriptions: { [key: string]: string } = {
                            RB_0021:
                                t("benchmark.witness.distraction"),
                            RB_0022:
                                t("benchmark.witness.didNotKnow"),
                            RB_0023:
                                t("benchmark.witness.confronted"),
                            RB_0024:
                                t("benchmark.witness.intervenedLaterHarasser"),
                            RB_0025:
                                t("benchmark.witness.intervenedLaterVictim"),
                            RB_0026:
                                t("benchmark.witness.keptRecord"),
                            RB_0027: t("benchmark.witness.didNotIntervene"),
                            RB_0028: t("benchmark.witness.informed"),
                        };

                        return (
                            <BenchmarkPorcentajeCard
                                key={key}
                                description={descriptions[key]}
                                percentage={percentage}
                                sx={{
                                    backgroundColor: "#eaddf3",
                                    color: "#6f4dbc",
                                }}
                            />
                        );
                    })}
                </Flex>
            </Flex>
        </ResultadosCard>
    );
}