import { Flex } from "@mantine/core";

import { useAuth } from "../../context/AuthContext";
import "./InicioPage.css";
import { tokenService } from "../../api";
import {
  DashboardInicio,
  ClientSelector,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
} from "../../components";
import { useTranslation } from "react-i18next";

export const InicioPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const access_role = tokenService.getUserFromToken()?.access_role;

  return (
    <GenderLabAppShell>
      {(access_role === "superuser" ||
        access_role === "partner" ||
        access_role === "economic_group_manager") && (
        <Flex gap="md" align="center">
          <GenderLabBreadcrumbs breadcrumbs={[{ link: "", title: t("appShell.home") }]} />
          <ClientSelector access_role={access_role} />
        </Flex>
      )}
      {!user ? (
        <GenderLabLoader />
      ) : (
        <DashboardInicio tradeName={user.tradeName} />
      )}
    </GenderLabAppShell>
  );
};
