import { Button, Card, Container, Divider, Flex, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
  PlyrComponent,
} from "../../components";
import { aulaService, clientService } from "../../api/services";
import { Profile, TAvailableCourse } from "../../types";
import { formatDate } from "../../utils";
import { useTallyUrl } from "../../hooks/useTallyUrl";
import { useTranslation } from "react-i18next";

export const CursoDisponiblePage = () => {
  const { t } = useTranslation();
  const [courseData, setCourseData] = useState<TAvailableCourse>(null!);
  const [profileData, setProfileData] = useState<Profile>(null!);

  const { code } = useParams();
  const tallyUrl = useTallyUrl();

  useEffect(() => {
    const getCourseData = async () => {
      if (code) {
        const response = await aulaService.getAvailableCourseById(
          parseInt(code)
        );
        const courseData: TAvailableCourse = response.data;
        setCourseData(courseData);
      }
    };
    const getUserProfileData = async () => {
      const response = await clientService.profile.get();
      setProfileData(response.data);
    };

    getUserProfileData();
    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "/aula-virtual", title: "Aula Virtual" }]}
      />

      {!courseData || !profileData || !tallyUrl ? (
        <GenderLabLoader />
      ) : (
        <>
          <Card py="xs" radius="md" withBorder>
            <Text fz={26} align="center">
              {courseData.name}
            </Text>

            <Card.Section>
              <Divider my="sm" />
            </Card.Section>

            <Flex
              justify="space-evenly"
              direction={{ base: "column", sm: "row" }}
              gap="xs"
              mt="sm"
            >
              <Flex direction="column" align="center">
                <Text fz={24} sx={{ lineHeight: 1 }}>
                  Marlene Molero
                </Text>
                <Text fz={14} color="dimmed">
                  docente
                </Text>
              </Flex>
              <Flex direction="column" align="center">
                <Text fz={24} sx={{ lineHeight: 1 }}>
                  {formatDate(courseData.release_date || "No definido")}
                </Text>
                <Text fz={14} color="dimmed">
                  fecha de publicación
                </Text>
              </Flex>
            </Flex>

            <Card.Section>
              <Divider mt="md" mb="sm" />
            </Card.Section>

            <p>
              {courseData.description ||
                "El curso no cuenta con una descripción."}
            </p>
          </Card>

          <Container style={{ maxWidth: "650px" }} mt="md">
            <PlyrComponent videoId={courseData.video_url} provider="youtube" />
          </Container>
          <Flex mt="md" justify="center" gap="md">
            <Button
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={courseData.syllabus}
              size="sm"
              mt="md"
              color="blue"
              disabled={!courseData.syllabus}
            >
              {t("aula.viewSyllabus")}
            </Button>
            <Button
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={tallyUrl}
              size="sm"
              mt="md"
              color="blue"
            >
              Solicitar información del curso
            </Button>
          </Flex>
        </>
      )}
    </GenderLabAppShell>
  );
};
