import { useEffect, useState } from "react";
import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
  ResultadosCard,
  BenchmarkConceptoChart,
  BenchmarkToleranciaHeatmap,
  BenchmarkSistemaPrevencionChart,
  BenchmarkAcosoTecnicoYDeclaradoPrevalencia,
  BenchmarkPrincipalesManifestaciones,
  TitleCard,
  SectionTitle,
  BenchmarkPercepcionPositiva,
  BenchmarkIndice,
  BenchmarkSubindice,
  BenchmarkDistribucionPieChartCard,
  BenchmarkPrevalenciaPerfilPersonal,
  BenchmarkReaccionesCard,
  BenchmarkBarrerasCard,
  BenchmarkPerfilAcosador,
  BenchmarkTestigos,
  BenchmarkReaccionesTestigos,
  BenchmarkIncertidumbreCard,
  BenchmarkResolucionDeCasosCard,
  BenchmarkMitosYSesgos,
  AccesoRestringidoCard,
} from "../../components";
import { useAuth } from "../../context";
import { createStyles, Text, Flex, Tabs } from "@mantine/core";
import { TGeneralBenchmark } from "../../types";
import { clientService } from "../../api";
import { useTranslation } from "react-i18next";
import { ZIndex } from "../../utils/zIndex";

const useStyles = createStyles((theme) => ({
  title: {
    position: "sticky",
    zIndex: ZIndex.base,
    backgroundColor: theme.colors.gray[0],
    top: 0,
  },
  container: {
    maxWidth: 700,
  },
  conocimientoContainer: {
    maxWidth: 1400,
    margin: "0 auto",
  },
  tabs: {
    position: "sticky",
    zIndex: ZIndex.base,
    backgroundColor: theme.colors.gray[0],
    top: 80,
  },
}));

const responsiveBarStylesPrevalencia = [
  {
    breakpoint: 768,
    options: {
      chart: {
        width: 300,
      },
    },
  },
];

export const BenchmarkPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { classes } = useStyles();
  const [generalBenchmark, setGeneralBenchmark] =
    useState<TGeneralBenchmark | null>(null!);

  const breadcrumbs = () => (
    <div className={classes.title}>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "", title: t("appShell.benchmark") }]}
      />
    </div>
  );

  const loader = () => (
    <GenderLabAppShell>
      {breadcrumbs()}
      <GenderLabLoader />
    </GenderLabAppShell>
  );

  useEffect(() => {
    if (user?.hasRegionalBenchmark) {
      const getGeneralBenchmark = async () => {
        const response = await clientService.benchmark.general.get();
        setGeneralBenchmark(response.data);
      };
      getGeneralBenchmark();
    }
  }, [user]);

  if (!user || (user.hasRegionalBenchmark && !generalBenchmark)) {
    return loader();
  }

  return (
    <GenderLabAppShell>
      {breadcrumbs()}
      {user.hasRegionalBenchmark && generalBenchmark ? (
        <>
          <Flex direction="column" gap="xl" sx={{ marginBottom: 20 }}>
            <Text color="dimmed" sx={{ textAlign: "justify" }}>
              {t("benchmark.description")} {generalBenchmark.year - 1}.
            </Text>
          </Flex>
          <Tabs defaultValue="indice">
            <div className={classes.tabs}>
              <Tabs.List grow>
                <Tabs.Tab value="indice">{t("tabs.index")}</Tabs.Tab>
                <Tabs.Tab value="muestra">{t("tabs.sample")}</Tabs.Tab>
                <Tabs.Tab value="sistema-prevencion">
                  {t("tabs.preventionSystem")}
                </Tabs.Tab>
                <Tabs.Tab value="tolerancia">{t("tabs.tolerance")}</Tabs.Tab>
                <Tabs.Tab value="prevalencia">{t("tabs.prevalence")}</Tabs.Tab>
                <Tabs.Tab value="testigos">{t("tabs.witnesses")}</Tabs.Tab>
                <Tabs.Tab value="clima-orga">{t("tabs.orgClimate")}</Tabs.Tab>
                <Tabs.Tab value="liderazgo">{t("tabs.leadership")}</Tabs.Tab>
              </Tabs.List>
            </div>

            <Tabs.Panel value="indice" pt="md">
              <Flex direction="column" gap="md">
                <SectionTitle
                  title="Índice ELSA"
                  description="El índice ELSA es una metodología basada en los datos de una medición. Este otorga puntajes a las organizaciones según su nivel de prevención de acoso sexual laboral. A continuación puedes ver los resultados agregados en el Índice ELSA de todas las organizaciones que se midieron."
                />
                <BenchmarkIndice generalBenchmark={generalBenchmark} />
                <Flex gap="xs">
                  <BenchmarkSubindice generalBenchmark={generalBenchmark} />
                </Flex>
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="muestra" pt="md">
              <Flex direction="column" gap="md">
                <SectionTitle
                  title={t("tabs.sample")}
                  description={t("benchmark.sampleDescription")}
                />
                <Flex gap="md" direction={{ base: "column", md: "row" }}>
                  <BenchmarkDistribucionPieChartCard
                    title={t("gender")}
                    labels={[
                      t("men"),
                      t("women"),
                      t("nonBinary"),
                    ]}
                    series={[
                      generalBenchmark.benchmarks.RB_0186,
                      generalBenchmark.benchmarks.RB_0187,
                      generalBenchmark.benchmarks.RB_0188,
                    ]}
                  />
                  <BenchmarkDistribucionPieChartCard
                    title={t("belongsLgbtCommunity")}
                    labels={[
                      t("lgbtCommunity"),
                      t("not") + " " + t("lgbtCommunity"),
                      t("preferNotToAnswer")
                    ]}
                    series={[
                      generalBenchmark.benchmarks.RB_0189,
                      generalBenchmark.benchmarks.RB_0190,
                      generalBenchmark.benchmarks.RB_0191,
                    ]}
                  />
                </Flex>
                <Flex gap="md" direction={{ base: "column", md: "row" }}>
                  <BenchmarkDistribucionPieChartCard
                    title={t("ageRange")}
                    labels={[
                      "18 - 29 " + t("years"),
                      "30 - 49 " + t("years"),
                      "50 - 59 " + t("years"),
                      "60 " + t("years") + " " + t("andOlder"),
                      t("preferNotToAnswer"),
                    ]}
                    series={[
                      generalBenchmark.benchmarks.RB_0192,
                      generalBenchmark.benchmarks.RB_0193,
                      generalBenchmark.benchmarks.RB_0194,
                      generalBenchmark.benchmarks.RB_0195,
                      generalBenchmark.benchmarks.RB_0196,
                    ]}
                  />
                  <BenchmarkDistribucionPieChartCard
                    title={t("ethnic.ethnicRacialSelfIdentification")}
                    labels={[
                      t("ethnic.asian"),
                      t("ethnic.mestizo"),
                      t("ethnic.indigenous"),
                      t("ethnic.afrodescendant"),
                      t("ethnic.white"),
                      t("other"),
                      t("preferNotToAnswer"),
                    ]}
                    series={[
                      generalBenchmark.benchmarks.RB_0197,
                      generalBenchmark.benchmarks.RB_0198,
                      generalBenchmark.benchmarks.RB_0199,
                      generalBenchmark.benchmarks.RB_0200,
                      generalBenchmark.benchmarks.RB_0201,
                      generalBenchmark.benchmarks.RB_0202,
                      generalBenchmark.benchmarks.RB_0203,
                    ]}
                  />
                </Flex>
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="sistema-prevencion" pt="md">
              <Flex direction="column" gap="md">
                <SectionTitle
                  title={t("tabs.preventionSystem")}
                  description={t("benchmark.preventionSystemDescription")}
                />
                <BenchmarkSistemaPrevencionChart
                  generalBenchmark={generalBenchmark}
                  classes={classes}
                />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="tolerancia" pt="md">
              <Flex direction="column" gap="md">
                <SectionTitle
                  title={t("tabs.tolerance")}
                  description={t("benchmark.toleranceDescription")}
                />
                <BenchmarkToleranciaHeatmap generalBenchmark={generalBenchmark} />
                <BenchmarkConceptoChart generalBenchmark={generalBenchmark} />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="prevalencia" pt="md">
              <Flex direction="column" gap="md">
                <SectionTitle
                  title={t("tabs.prevalence")}
                  description={t("benchmark.prevalenceDescription")}
                />
                <BenchmarkAcosoTecnicoYDeclaradoPrevalencia
                  technicalHarassmentPrevalence={
                    generalBenchmark.benchmarks.RB_0040
                  }
                  declaredHarassmentPrevalence={generalBenchmark.benchmarks.RB_0039}
                />
                <BenchmarkPrevalenciaPerfilPersonal
                  generalBenchmark={generalBenchmark}
                  responsiveStyle={responsiveBarStylesPrevalencia}
                />
                <BenchmarkPrincipalesManifestaciones
                  generalBenchmark={generalBenchmark}
                />

                <TitleCard
                  title={t("benchmark.metricsTitle")}
                  description={t("benchmark.metricsDescription")}
                />
                <BenchmarkReaccionesCard
                  generalBenchmark={generalBenchmark}
                  responsiveStyle={responsiveBarStylesPrevalencia}
                />
                <BenchmarkBarrerasCard generalBenchmark={generalBenchmark} />
                <BenchmarkPerfilAcosador
                  generalBenchmark={generalBenchmark}
                  responsiveStyle={responsiveBarStylesPrevalencia}
                />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="testigos" pt="md">
              <Flex direction="column" gap="md">
                <SectionTitle
                  title={t("tabs.witnesses")}
                  description={t("benchmark.witnessesDescription")}
                />
                <BenchmarkTestigos generalBenchmark={generalBenchmark} />
                <BenchmarkReaccionesTestigos generalBenchmark={generalBenchmark} />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="clima-orga" pt="md">
              <Flex direction="column" gap="md">
                <SectionTitle
                  title={t("tabs.orgClimate")}
                  description={t("benchmark.orgClimate.description")}
                />
                <BenchmarkIncertidumbreCard generalBenchmark={generalBenchmark} />
                <BenchmarkPercepcionPositiva generalBenchmark={generalBenchmark} />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="liderazgo" pt="md">
              <Flex direction="column" gap="md">
                <SectionTitle
                  title={t("tabs.leadership")}
                  description={t("benchmark.leadership.description")}
                />
                <Flex
                  direction={{ base: "column", md: "row" }}
                  justify="space-evenly"
                  gap="md"
                >
                  <ResultadosCard title={t("sectionAbout")}>
                    <Text align="justify">
                      {t("benchmark.leadership.sectionAboutDescription")}
                    </Text>
                  </ResultadosCard>
                  <ResultadosCard title={t("reachedSectionAbout")}>
                    <Text align="justify">
                      {t("benchmark.leadership.whoIsForDescription")}
                    </Text>
                  </ResultadosCard>
                </Flex>
                <Flex direction="column" gap="md">
                  <BenchmarkResolucionDeCasosCard
                    generalBenchmark={generalBenchmark}
                  />
                  <BenchmarkMitosYSesgos generalBenchmark={generalBenchmark} />
                </Flex>
              </Flex>
            </Tabs.Panel>
          </Tabs>
        </>
      ) : (
        <AccesoRestringidoCard name={t("appShell.benchmark")} />
      )}
    </GenderLabAppShell>
  );
};
