import { useTranslation } from "react-i18next";
import { colorRojoClaro1 } from "../../utils";
import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { splitTextWithMaxLength } from "../../utils/splitTextWithMaxLength";

export const BenchmarkMitosYSesgos = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard title={t("myths") + " " + t("and") + " " + t("biases")}>
            <BarChart
                categories={[
                    splitTextWithMaxLength(t("benchmark.leadership.categories.victimsResponsibility"), 16),
                    splitTextWithMaxLength(t("benchmark.leadership.categories.naturalizedHeterosexuality"), 16),
                    splitTextWithMaxLength(t("benchmark.leadership.categories.exaggeration"), 16),
                    splitTextWithMaxLength(t("benchmark.leadership.categories.ulteriorMotives"), 16),
                    splitTextWithMaxLength(t("benchmark.leadership.categories.intentionality"), 16),
                ]}
                seriesNames={[t("percentage") + " (%)"]}
                seriesData={[
                    [
                        generalBenchmark.benchmarks.RB_0222,
                        generalBenchmark.benchmarks.RB_0223,
                        generalBenchmark.benchmarks.RB_0224,
                        generalBenchmark.benchmarks.RB_0225,
                        generalBenchmark.benchmarks.RB_0226,
                    ],
                ]}
                colors={[colorRojoClaro1]}
                width={500}
            />
        </ResultadosCard>
    );
};