import { Paper, Skeleton, Table, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { clientService } from "../../../api";
import { GenderLabAppShell, GenderLabBreadcrumbs } from "../../../components";
import { useAuth, useMedicionActual } from "../../../context";
import {
  TDistribucion,
  TMedicionActualSetup,
  TPersonalizacionTags,
  TPersonalizationTableData,
} from "../../../types";
import { TablaPersonalizacion } from "./_tablaPersonalizacion";
import { TablaPuestoSmallMedium } from "./_tablaPuestoSmallMedium";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

export const PersonalizacionPage = () => {
  const { t } = useTranslation();
  const { medicionActualCookie } = useMedicionActual();
  const { authCallbackOnPageLoad } = useAuth();

  const [distribucion, setDistribucion] = useState<TDistribucion>(null!);
  const [tags, setTags] = useState<TPersonalizacionTags>(null!);
  const [puestos, setPuestos] = useState<TPersonalizationTableData[]>(null!);
  const [centros, setCentros] = useState<TPersonalizationTableData[]>(null!);
  const [areas, setAreas] = useState<TPersonalizationTableData[]>(null!);
  const [turnos, setTurnos] = useState<TPersonalizationTableData[]>(null!);
  const [medicionActualSetup, setMedicionActualSetup] =
    useState<TMedicionActualSetup>(null!);

  const calculateTotalPopulation = () => {
    if (distribucion)
      return (
        distribucion.men_population +
        distribucion.women_population +
        distribucion.non_binary_population
      );
    return 0;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await clientService.measurements.getByCode(
          medicionActualCookie?.code
        );
        setMedicionActualSetup(response.data);
        if (response.data.staff_survey.status === "LAUNCHED") {
          showNotification({
            title: "Ya no puedes editar estos campos.",
            message: "La encuesta ya fue lanzada.",
            color: "red",
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!medicionActualSetup) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    authCallbackOnPageLoad(() => {
      const getData = async () => {
        const { data: distributionRes } =
          await clientService.measurements.distribution.get(
            medicionActualCookie?.code
          );

        const size = distributionRes.size;
        setDistribucion(distributionRes);

        const { data: tagsRes } =
          await clientService.measurements.personalization.tags.get(
            medicionActualCookie?.code
          );
        setTags(tagsRes);

        const { data: jobsRes } =
          await clientService.measurements.personalization.jobs.get(
            medicionActualCookie?.code
          );
        setPuestos(jobsRes.jobs);

        if (size === "medium" || size === "large") {
          const { data: workplacesRes } =
            await clientService.measurements.personalization.workplaces.get(
              medicionActualCookie?.code
            );
          setCentros(workplacesRes.workplaces);

          const { data: areasRes } =
            await clientService.measurements.personalization.areas.get(
              medicionActualCookie?.code
            );
          setAreas(areasRes.areas);
        } else {
          setCentros([]);
          setAreas([]);
        }

        if (size === "large") {
          const { data: workShiftsRes } =
            await clientService.measurements.personalization.workShifts.get(
              medicionActualCookie?.code
            );
          setTurnos(workShiftsRes.work_shifts);
        } else {
          setTurnos([]);
        }
      };

      if (!distribucion) getData();
    });
  }, [medicionActualCookie?.code, authCallbackOnPageLoad, distribucion]);

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[
          { link: "/mediciones", title: "Mediciones" },
          {
            link: "/medicion/" + medicionActualCookie?.code,
            title: medicionActualCookie?.title || "",
          },
          { link: "", title: "Personalización" },
        ]}
      />

      {distribucion && (
        <>
          <Paper p="md" shadow="md" radius="lg">
            {distribucion.size === "small" && (
              <>
                En esta sección deberás llenar el siguiente cuadro para hacer
                seguimiento a las respuestas de tu encuesta al personal.
              </>
            )}
            {(distribucion.size === "medium" ||
              distribucion.size === "large") && (
              <>
                En esta sección podrás customizar las alternativas de algunas
                preguntas y llenar el cuadro de distribución. Podrás editar las
                descripciones, agregar opciones o eliminar. Los cambios que
                realices aquí se verán reflejados en tu encuesta al personal y
                la distribución permitirá hacer seguimiento a las respuestas.
              </>
            )}
          </Paper>
        </>
      )}

      <Skeleton visible={!distribucion} mt="lg">
        <Paper p="md" shadow="md" radius="lg">
          <Table>
            <thead>
              <tr>
                <th>{t("men")}</th>
                <th>{t("women")}</th>
                <th>{t("nonBinary")}</th>
                <th>{t("total")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{distribucion?.men_population}</td>
                <td>{distribucion?.women_population}</td>
                <td>{distribucion?.non_binary_population}</td>
                <td>{calculateTotalPopulation()}</td>
              </tr>
            </tbody>
          </Table>
        </Paper>
      </Skeleton>

      {distribucion && tags && puestos && centros && areas && turnos ? (
        <>
          <Title order={3} mt="lg">
            Puestos
          </Title>
          {(distribucion.size === "small" ||
            distribucion.size === "medium") && (
            <TablaPuestoSmallMedium
              rows={puestos}
              disabled={medicionActualSetup.staff_survey.status === "LAUNCHED"}
            />
          )}

          {distribucion.size === "large" && (
            <TablaPersonalizacion
              size={distribucion.size}
              disabled={medicionActualSetup.staff_survey.status === "LAUNCHED"}
              tags={tags.job_tags}
              addButtonCallback={(data) =>
                clientService.measurements.personalization.jobs.post(
                  medicionActualCookie?.code,
                  data
                )
              }
              deleteButtonCallback={(id: number) =>
                clientService.measurements.personalization.jobs.delete(
                  medicionActualCookie?.code,
                  id
                )
              }
              putButtonCallback={(id: number, data) =>
                clientService.measurements.personalization.jobs.put(
                  medicionActualCookie?.code,
                  id,
                  data
                )
              }
              rows={puestos}
              name="puesto"
              minQuantityOfRegisters={2}
            />
          )}

          {(distribucion.size === "medium" ||
            distribucion.size === "large") && (
            <>
              <Title order={3} mt="lg">
                Centros de trabajo
              </Title>
              <TablaPersonalizacion
                size={distribucion.size}
                disabled={
                  medicionActualSetup.staff_survey.status === "LAUNCHED"
                }
                tags={tags.workplace_tags}
                addButtonCallback={(data) =>
                  clientService.measurements.personalization.workplaces.post(
                    medicionActualCookie?.code,
                    data
                  )
                }
                deleteButtonCallback={(id: number) =>
                  clientService.measurements.personalization.workplaces.delete(
                    medicionActualCookie?.code,
                    id
                  )
                }
                putButtonCallback={(id: number, data) =>
                  clientService.measurements.personalization.workplaces.put(
                    medicionActualCookie?.code,
                    id,
                    data
                  )
                }
                rows={centros}
                name="centro de trabajo"
                minQuantityOfRegisters={1}
              />

              <Title order={3} mt="lg">
                Áreas
              </Title>
              <TablaPersonalizacion
                size={distribucion.size}
                disabled={
                  medicionActualSetup.staff_survey.status === "LAUNCHED"
                }
                tags={tags.area_tags}
                addButtonCallback={(data) =>
                  clientService.measurements.personalization.areas.post(
                    medicionActualCookie?.code,
                    data
                  )
                }
                deleteButtonCallback={(id: number) =>
                  clientService.measurements.personalization.areas.delete(
                    medicionActualCookie?.code,
                    id
                  )
                }
                putButtonCallback={(id: number, data) =>
                  clientService.measurements.personalization.areas.put(
                    medicionActualCookie?.code,
                    id,
                    data
                  )
                }
                rows={areas}
                name="área"
                minQuantityOfRegisters={2}
              />
            </>
          )}

          {distribucion.size === "large" && (
            <>
              <Title order={3} mt="lg">
                Turnos
              </Title>
              <TablaPersonalizacion
                size={distribucion.size}
                disabled={
                  medicionActualSetup.staff_survey.status === "LAUNCHED"
                }
                tags={tags.work_shift_tags}
                addButtonCallback={(data) =>
                  clientService.measurements.personalization.workShifts.post(
                    medicionActualCookie?.code,
                    data
                  )
                }
                deleteButtonCallback={(id: number) =>
                  clientService.measurements.personalization.workShifts.delete(
                    medicionActualCookie?.code,
                    id
                  )
                }
                putButtonCallback={(id: number, data) =>
                  clientService.measurements.personalization.workShifts.put(
                    medicionActualCookie?.code,
                    id,
                    data
                  )
                }
                rows={turnos}
                name="turno"
                minQuantityOfRegisters={1}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Title order={3} mt="lg">
            Puestos
          </Title>
          <Skeleton height={200} />
          {/* <Title order={3} mt="lg">
            Centros de trabajo
          </Title>
          <Skeleton height={200} />
          <Title order={3} mt="lg">
            Áreas
          </Title>
          <Skeleton height={200} />
          <Title order={3} mt="lg">
            Turnos
          </Title>
          <Skeleton height={200} /> */}
        </>
      )}
    </GenderLabAppShell>
  );
};
