import { Flex, Title, Text } from "@mantine/core";
import { PorcentajeCardSmall, ResultadosCard } from "../Resultados";
import { TextWithInfoTooltip } from "../Miscellaneous";
import { colorAmarillo1, colorCeleste1 } from "../../utils";
import { useTranslation } from "react-i18next";

type Props = {
  technicalHarassmentPrevalence: number;
  declaredHarassmentPrevalence: number;
};

export const BenchmarkAcosoTecnicoYDeclaradoPrevalencia = ({
  technicalHarassmentPrevalence,
  declaredHarassmentPrevalence,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ResultadosCard title={t("benchmark.prevalenceSubtitleOne")}>
      <Flex
        direction={{ base: "column", sm: "row" }}
        justify="space-evenly"
        gap="xs"
      >
        <Flex direction={{ base: "column" }} align="center">
          <TextWithInfoTooltip
            text="Hostigamiento o acoso sexual laboral técnico"
            tooltipText={
              <>
                <Title order={5}>Acoso Sexual Laboral Técnico</Title>
                <Text align="justify">
                  Porcentaje de personas que han pasado por al menos una
                  situación de acoso sexual laboral en los últimos 12 meses;
                  independientemente de si reconocieron o no la situación como
                  acoso sexual laboral. Esta cifra refleja de mejor manera lo
                  que realmente ocurre en las organizaciones.
                </Text>
              </>
            }
            width={500}
          />

          <PorcentajeCardSmall
            description={t("benchmark.prevalencePercentageOne")}
            percentage={technicalHarassmentPrevalence}
            decimalPlaces={0}
            color={colorAmarillo1}
          />
        </Flex>
        <Flex direction={{ base: "column" }} align="center">
          <TextWithInfoTooltip
            text="Hostigamiento o acoso sexual laboral declarado"
            tooltipText={
              <>
                <Flex direction="column" gap="sm">
                  <Title order={5}>Acoso Sexual Laboral Declarado</Title>
                  <Text align="justify">
                    Porcentaje de personas que reconocen haber pasado al menos
                    una situación de acoso sexual laboral en los últimos 12
                    meses. Esta cifra tiende a ser menor que la de acoso sexual
                    laboral técnico, ya que las personas tienden a no reconocer
                    la situación como acoso sexual laboral a pesar de haber
                    pasado por ella.
                  </Text>
                </Flex>
              </>
            }
            width={500}
          />
          <PorcentajeCardSmall
            description="Pasó por una situación de hostigamiento o acoso sexual laboral y además, lo reconoció como tal."
            percentage={declaredHarassmentPrevalence}
            decimalPlaces={0}
            color={colorCeleste1}
          />
        </Flex>
      </Flex>
    </ResultadosCard>
  );
};
