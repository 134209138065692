import { Text, Flex, Title } from "@mantine/core";
import {
  PuntajeBonusPenalidad,
  ResultadosCard,
  Tacometro,
} from "../../Resultados";
import { GeneralBenchmarkProps } from "../GeneralBenchmarkProps";
import { roundToOneDecimal } from "../../../utils";
import { TextWithInfoTooltip } from "../../Miscellaneous";
import { useTranslation } from "react-i18next";

export const BenchmarkSubindice = ({
  generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ResultadosCard>
      <Flex
        justify="space-around"
        align="center"
        gap="xl"
        direction={{ base: "column", md: "row" }}
      >
        <Flex direction="column" align="center" gap="xs">
          <Title order={5}>{t("subindexes")}</Title>
          <Flex justify="space-around" gap={30} align="center">
            <Flex direction="column" align="center">
              <Tacometro
                value={roundToOneDecimal(
                  generalBenchmark.benchmarks.IRB_0002 * 100
                )}
              />
              <TextWithInfoTooltip
                text={t("tabs.preventionSystem")}
                tooltipText={
                  <>
                    <Title order={5} sx={{ marginBottom: "10px" }}>
                      {t("tabs.preventionSystem")}
                    </Title>
                    <Text align="justify">
                      {t("benchmark.preventionSystemTooltip")}
                    </Text>
                  </>
                }
                width={300}
                fw={400}
              />
            </Flex>
            <Flex direction="column" align="center">
              <Tacometro
                value={roundToOneDecimal(
                  generalBenchmark.benchmarks.IRB_0003 * 100
                )}
              />
              <TextWithInfoTooltip
                text={t("awareness")}
                tooltipText={
                  <>
                    <Title order={5} sx={{ marginBottom: "10px" }}>
                      {t("awareness")}
                    </Title>
                    <Text align="justify">
                      {t("benchmark.awarenessTooltip")}
                    </Text>
                  </>
                }
                width={300}
                fw={400}
              />
            </Flex>
            <Flex direction="column" align="center">
              <Tacometro
                value={roundToOneDecimal(
                  generalBenchmark.benchmarks.IRB_0004 * 100
                )}
              />
              <TextWithInfoTooltip
                text={t("tabs.tolerance")}
                tooltipText={
                  <>
                    <Title order={5} sx={{ marginBottom: "10px" }}>
                      {t("tabs.tolerance")}
                    </Title>
                    <Text align="justify">
                      {t("benchmark.toleranceTooltip")}
                    </Text>
                  </>
                }
                width={300}
                fw={400}
              />
            </Flex>
            <Flex direction="column" align="center">
              <Tacometro
                value={roundToOneDecimal(
                  generalBenchmark.benchmarks.IRB_0005 * 100
                )}
              />
              <TextWithInfoTooltip
                text={t("tabs.orgClimate")}
                tooltipText={
                  <>
                    <Title order={5} sx={{ marginBottom: "10px" }}>
                      {t("tabs.orgClimate")}
                    </Title>
                    <Text align="justify">
                      {t("benchmark.orgClimateTooltip")}
                    </Text>
                  </>
                }
                width={300}
                fw={400}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" align="center" gap="sm">
          <Title order={5}>{t("bonus")}</Title>
          <PuntajeBonusPenalidad
            value={roundToOneDecimal(
              generalBenchmark.benchmarks.IRB_0006 * 100
            )}
            isBonus
          />
          <TextWithInfoTooltip
            text={t("tabs.witnesses")}
            tooltipText={
              <>
                <Title order={5} sx={{ marginBottom: "10px" }}>
                  {t("tabs.witnesses")}
                </Title>
                <Text align="justify">
                  {t("benchmark.witnessesTooltip")}
                </Text>
              </>
            }
            width={300}
            fw={400}
          />
        </Flex>

        <Flex direction="column" align="center" gap="sm">
          <Title order={5}>{t("penalty")}</Title>
          <PuntajeBonusPenalidad
            value={roundToOneDecimal(
              generalBenchmark.benchmarks.IRB_0007 * 100
            )}
            isPenalty
          />
          <TextWithInfoTooltip
            text={t("myths") + " " + t("and") + " " + t("biases")}
            tooltipText={
              <>
                <Title order={5} sx={{ marginBottom: "10px" }}>
                  {t("myths") + " " + t("and") + " " + t("biases")}
                </Title>
                <Text align="justify">
                  {t("benchmark.mythsAndBiasesTooltip")}
                </Text>
              </>
            }
            width={300}
            fw={400}
          />
        </Flex>
      </Flex>
    </ResultadosCard>
  );
};
