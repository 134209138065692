import { Button, Flex, Modal, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { validateStringField } from "../../utils";
import { useForm } from "@mantine/form";
import { clientService } from "../../api";
import { useTranslation } from "react-i18next";

type Props = {
  callback: () => void;
};

export const AddUserButton = ({ callback }: Props) => {
  const { t } = useTranslation();
  const [addUserOpened, { open: openUser, close: closeUser }] =
    useDisclosure(false);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      newUser: {
        email: "",
        firstName: "",
        lastName: "",
      },
    },
    validate: {
      newUser: {
        email: validateStringField,
        firstName: validateStringField,
        lastName: validateStringField,
      },
    },
  });

  const resetNewUser = () => {
    form.setFieldValue("newUser.email", "");
    form.setFieldValue("newUser.firstName", "");
    form.setFieldValue("newUser.lastName", "");
  };

  const addUser = async () => {
    setLoading(true);
    try {
      form.validateField("newUser.email");
      form.validateField("newUser.firstName");
      form.validateField("newUser.lastName");
      if (
        form.isValid("newUser.email") &&
        form.isValid("newUser.firstName") &&
        form.isValid("newUser.lastName")
      ) {
        await clientService.users.post({
          email: form.values.newUser.email,
          first_name: form.values.newUser.firstName,
          last_name: form.values.newUser.lastName,
        });
        showNotification({
          title: "Éxito",
          message: "Usuario creado exitosamente.",
          color: "green",
        });
        closeUser();
        resetNewUser();
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        showNotification({
          title: "Error",
          message: "No se pudo crear el usuario.",
          color: "red",
        });
      }
    }
    callback();
    setLoading(false);
  };

  return (
    <>
      <Button onClick={openUser} disabled={loading}>
        {t("actions.add")} {t("myAccount.user")}
      </Button>{" "}
      <Modal
        opened={addUserOpened}
        onClose={() => {
          closeUser();
          resetNewUser();
        }}
        title={`${t("actions.add")} ${t("myAccount.user")}`}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addUser();
          }}
        >
          <Flex gap="sm" direction="column">
            <TextInput
              label={t("login.email")}
              disabled={loading}
              {...form.getInputProps("newUser.email")}
            />
            <TextInput
              label={t("myAccount.names")}
              disabled={loading}
              {...form.getInputProps("newUser.firstName")}
            />
            <TextInput
              label={t("myAccount.surnames")}
              disabled={loading}
              {...form.getInputProps("newUser.lastName")}
            />
            <Flex gap="sm" mt="md" justify="center">
              <Button fullWidth disabled={loading} type="submit">
                {t("actions.add")} {t("myAccount.user")}
              </Button>
            </Flex>
          </Flex>
        </form>
      </Modal>
    </>
  );
};
