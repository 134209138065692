import { Grid, Text } from "@mantine/core";
import { parsePercentage } from "../../utils";
import { Heatmap } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { useTranslation } from "react-i18next";

export const BenchmarkPercepcionPositiva = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard
            title={t("benchmark.orgClimate.positivePerceptionTitle")}
            description={t("benchmark.orgClimate.stronglyAgree")}
        >
            <Heatmap
                categories={["A1", "A2"]}
                data={[
                    {
                    name: t("total"),
                    data: [
                        parsePercentage(generalBenchmark.benchmarks.RB_0033),
                        parsePercentage(generalBenchmark.benchmarks.RB_0034),
                    ],
                    },
                    {
                    name: t("nonBinary"),
                    data: [
                        parsePercentage(generalBenchmark.benchmarks.RB_0184),
                        parsePercentage(generalBenchmark.benchmarks.RB_0185),
                    ],
                    },
                    {
                    name: t("female"),
                    data: [
                        parsePercentage(generalBenchmark.benchmarks.RB_0178),
                        parsePercentage(generalBenchmark.benchmarks.RB_0179),
                    ],
                    },
                    {
                    name: t("male"),
                    data: [
                        parsePercentage(generalBenchmark.benchmarks.RB_0172),
                        parsePercentage(generalBenchmark.benchmarks.RB_0173),
                    ],
                    },
                ]}
            />

            <Grid gutter="md">
                <Grid.Col span={6}>
                    <Text color="dimmed" size="xs">
                        A1: Se están tomando acciones efectivas contra el
                        hostigamiento o acoso sexual en mi trabajo.
                    </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text color="dimmed" size="xs">
                        A2: El hostigamiento o acoso sexual no es tolerado en mi
                        ambiente laboral.
                    </Text>
                </Grid.Col>
            </Grid>
        </ResultadosCard>
    );
}