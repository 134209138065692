import { ApiWithToken } from "../axios-instance";

export const adminService = {
  partner: {
    getClientList: async () =>
      ApiWithToken.get("/api/client/partner_clients/"),
    updateAssignedClient: async (clientId: string) =>
      ApiWithToken.put(`/api/client/partner_clients/${clientId}/`, {
        client: clientId,
      }),
    },
  economicGroupManager: {
    getClientList: async () =>
      ApiWithToken.get("/api/client/economic_group_manager_clients/"),
    updateAssignedClient: async (clientId: string) =>
      ApiWithToken.put(`/api/client/economic_group_manager_clients/${clientId}/`, {
        client: clientId,
      }),
    },
  superuser: {
    getClientList: async () =>
      ApiWithToken.get("/api/administrator/client/"),
    updateAssignedClient: async (clientId: string) =>
      ApiWithToken.put("/api/administrator/update_assigned_client/", {
        client: clientId,
      }),
    },
    
};
