import {
  Button,
  Card,
  Flex,
  Overlay,
  RingProgress,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { BsDownload } from "react-icons/bs";
import { PlanDeAccionCard } from "../PlanDeAccionCard";
import { useTranslation } from "react-i18next";

export const PlanDeAccionBlurred = () => {
  const { t } = useTranslation();
  const images = [
    "sistema-de-prevencion.png",
    "conocimiento.png",
    "tolerancia.png",
    "clima-organizacional.png",
    "prevalencia.png",
    "testigos.png",
  ];

  const titles: string[] = [
    "Sistema de prevención",
    "Conocimiento",
    "Tolerancia",
    "Clima organizacional",
    "Prevalencia",
    "Testigos",
  ];

  return (
    <div>
      <div style={{ position: "relative" }}>
        <Stack spacing="md" px="sm">
          <Flex gap="sm" pb="md" align="flex-end">
            <Select
              maw={200}
              label={t("measurement")}
              data={[{ value: "Elsa 2024", label: "Elsa 2024" }]}
              value="Elsa 2024"
            />
            <Button color="blue" size="xs">
              <Flex gap="xs" align="center">
                <BsDownload /> {t("actions.download")} {t("plan")}
              </Flex>
            </Button>
          </Flex>
          <Text>
            {t("actionPlan.description")}
          </Text>
          <Card withBorder>
            <Title order={5}>{t("yourProgress")}</Title>
            <Flex justify="space-around" align="center">
              <RingProgress
                size={150}
                sections={[
                  { value: 70, color: "gray" },
                  { value: 10, color: "yellow" },
                  { value: 20, color: "green" },
                ]}
                label={
                  <Stack align="center">
                    <Text align="center" fw={400} fz="lg">
                      20%
                    </Text>
                    <Text color="gray" mt={-15}>
                      completado
                    </Text>
                  </Stack>
                }
              />
              <Text fw={500}>100% {t("status.pending")}</Text>
              <Text fw={500}>0% {t("status.inProgress")}</Text>
              <Text fw={500}>0% {t("status.completed")}</Text>
              <Stack spacing="xs" align="center">
                <Text fw={500}>{t("estimatedCompletionDate")}</Text>
                <Text color="gray">01/01/2030</Text>
              </Stack>
            </Flex>
          </Card>

          {images.map((image, index) => (
            <PlanDeAccionCard
              key={index}
              image={`/img/pda/${image}`}
              title={titles[index]}
              completedRecommendations={0}
              totalRecommendations={5}
              recommendations={[
                {
                  id: 1,
                  status: t("status.inProgress"),
                  action: {
                    name: "Lorem ipsum dolor sit amet",
                    category: "Lorem ipsum dolor sit amet",
                    problem: "Lorem ipsum dolor sit amet",
                    solution: "Lorem ipsum dolor sit amet",
                  },
                },
              ]}
              onSelectChange={() => Promise.resolve()}
              defaultOpened={index === 0}
              code={""}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              vestibulum, tortor ut ultricies tincidunt, nunc nisl ultricies
              nunc, nec tincidunt nisl nunc sit amet libero. Integer sit amet
              urna nec
            </PlanDeAccionCard>
          ))}
        </Stack>
        <Overlay color="#f8f9fa" opacity={0.05} blur={1.75} />
      </div>
    </div>
  );
};
