import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
};

const labelsLanguage: Record<keyof typeof resources, string> = {
  en: 'English',
  es: 'Español',
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'es',
    debug: true,
    supportedLngs: ['en', 'es'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", 
    },
  });

export const languageOptions = (Object.keys(resources) as Array<keyof typeof resources>).map((lng) => ({
  label: labelsLanguage[lng],
  value: lng,
}));

export default i18n;
