import { useEffect, useState } from "react";
import { clientService } from "../api";
import { Profile } from "../types";

export const useTallyUrl = () => {
  const [profileData, setProfileData] = useState<Profile | null>(null);

  useEffect(() => {
    const getUserProfileData = async () => {
      try {
        const response = await clientService.profile.get();
        setProfileData(response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
        setProfileData(null); // En caso de error, asegura un estado consistente.
      }
    };

    getUserProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!profileData) return "";

  const countryPhone = profileData.countries.find(
    (country) => country.id === profileData.profile.contact_phone_country
  );

  const countryName = profileData.countries.find(
    (country) => country.id === profileData.profile.country
  );

  const phonePrefix = countryPhone?.phone_prefix || "";
  const countryNameValue = countryName?.name || "";

  return `https://tally.so/r/3Nlopl?email=${
    profileData.profile.contact_email
  }&first_name=${profileData.profile.contact_name}&last_name=${
    profileData.profile.contact_lastname
  }&business_name=${profileData.profile.business_name}&phone=${
    phonePrefix + profileData.profile.contact_phone
  }&country=${countryNameValue}`;
};
