import {
  Button,
  Center,
  createStyles,
  Flex,
  Group,
  Image,
  Stack,
} from "@mantine/core";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { useEffect, useState } from "react";

const useStyles = createStyles((theme) => ({
  certificadoContainer: {
    width: 1280,
    height: 720,
    border: `1px solid ${theme.colors.gray[4]}`,
    position: "relative",
    overflow: "hidden",
  },
  decoracionIzquierda: {
    position: "absolute",
  },
  decoracionDerechaInferior: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  content: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    top: 75,
    left: 400,
    justifyContent: "center",
    alignItems: "center",
    width: 800,
  },
  certificadoEntregadoAText: {
    fontFamily: "Kollektif",
    textTransform: "uppercase",
    fontSize: 30,
    fontWeight: 700,
    letterSpacing: 2,
    marginTop: 20,
  },
  nombreEmpresa: {
    fontFamily: "Kollektif",
    textTransform: "uppercase",
    fontSize: 50,
    fontWeight: 700,
    letterSpacing: 1.5,
  },
  descripcion: {
    fontFamily: "Montserrat",
    fontSize: 20,
    textAlign: "center",
  },
  nombreFirma: {
    fontFamily: "Montserrat",
    fontWeight: 900,
    fontSize: 22,
    marginTop: -10,
  },
  detalleFirma: {
    fontFamily: "Montserrat",
    fontSize: 22,
    marginTop: -20,
  },
  fecha: {
    position: "absolute",
    top: 625,
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 900,
  },
  downloadCertificate: {
    position: "absolute",
    top: 50,
    right: theme.spacing.md,
    zIndex: 1,
  },
}));

type Props = {
  nombreEmpresa: string;
  nombreCurso: string;
  customHeader?: string;
  fecha?: string;
  children: React.ReactNode;
};

export const CertificadoShell = ({
  nombreEmpresa,
  nombreCurso,
  customHeader,
  fecha,
  children,
}: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [fechaStyle, setFechaStyle] = useState({ left: "1050px" });

  useEffect(() => {
    if (!fecha || (fecha && fecha.length <= 10)) {
      setFechaStyle({ left: "1150px" }); // Ajusta el valor de "left" según lo que necesites
    } else {
      setFechaStyle({ left: "1050px" });
    }
  }, [fecha]);

  const downloadCertificate = async () => {
    const doc = new jsPDF({
      orientation: "l",
      unit: "mm",
      format: [1280, 720],
      putOnlyUsedFonts: true,
    });

    const content = document.getElementById("certificado")!;
    await html2canvas(content, {
      scale: 2,
      logging: true,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      doc.addImage(imgData, "png", 0, 0, 1280, 720);
    });
    doc.save(`Certificado-${nombreEmpresa}-${nombreCurso}.pdf`);
  };

  return (
    <>
      <Center my="md">
        <div className={classes.certificadoContainer} id="certificado">
          <Image
            src="/img/certificados/decoracion-izquierda.png"
            alt="Decoración izquierda"
            className={classes.decoracionIzquierda}
            width={380}
          />
          <Image
            src="/img/certificados/decoracion-derecha-inferior.png"
            alt="Decoración derecha inferior"
            className={classes.decoracionDerechaInferior}
            width={250}
          />
          <div className={classes.content}>
            <Image
              src="/img/certificados/elsa-black.png"
              alt="Elsa"
              width={250}
            />
            <div className={classes.certificadoEntregadoAText}>
              {customHeader
                ? customHeader
                : "constancia corporativa otorgada a:"}
            </div>
            <div className={classes.nombreEmpresa}>{nombreEmpresa}</div>
            <div className={classes.descripcion}>
              {children}
              <Stack spacing="md" align="center" mt="md">
                <Image
                  src={"/img/certificados/firma.png"}
                  alt="Firma"
                  width={225}
                />
                <div className={classes.nombreFirma}>Marlene Molero</div>
                <div className={classes.detalleFirma}>
                  CEO & Co-founder ELSA
                </div>
              </Stack>
            </div>
          </div>
          <div className={classes.fecha} style={fechaStyle}>
            {fecha || new Date().toLocaleDateString("es-ES")}
          </div>
        </div>
      </Center>{" "}
      <Group position="center">
        <Button
          onClick={downloadCertificate}
          className={classes.downloadCertificate}
          color="blue"
        >
          <Flex gap={5}>
            {t("actions.download") + " " + t("certificate")} <FaDownload />
          </Flex>
        </Button>
      </Group>
    </>
  );
};
