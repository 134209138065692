import {
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Image,
  Radio,
  Select,
  TextInput,
  Title,
  Text,
  Skeleton,
  Table,
  Paper,
  Tabs,
  Alert,
  Anchor,
} from "@mantine/core";
import { closeAllModals, openConfirmModal, openModal } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { format } from "date-fns";
import { BsInfoCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import {
  AddUserButton,
  ClientSelector,
  EliminarButton,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  LabelText,
  ImageCropper,
} from "../../components";
import { SelectItemWithIcon } from "../../components/SelectItemWithIcon/index";
import { linkColor, onChangeCleanFields } from "../../utils";
import { useAuth } from "../../context";
import { TUserClient } from "../../types";
import { tokenService, clientService } from "../../api";
import { useTranslation } from "react-i18next";
import { languageOptions } from "../../i18n";
import { userService } from "../../api/services/user-service";

const DEFAULT_LOGO =
  "https://genderlab-diagnosis-dev.s3.amazonaws.com/media/clients/default_logo.png";

type MyAccountData = {
  informacionDeLaOrganizacion: {
    nombreComercial: string;
    razonSocial: string;
    perteneceAGrupoEconomico: string;
    grupoEconomico: string;
    ubicacion: string;
    tipo: string;
    sectorEconomico: string;
    idioma: string;
  };
  informacionDeContacto: {
    nombres: string;
    apellidos: string;
    correo: string;
    codigoPais: string;
    telefono: string;
    cargo: string;
  };
  paises: {
    id: number;
    nombre: string;
    fotoUrl: string;
    codigoTelefonoPais: string;
  }[];
  tiposDeOrganizacion: {
    id: number;
    nombre: string;
  }[];
  sectoresEconomicos: {
    id: number;
    nombre: string;
  }[];
};

export const MiCuentaPage = () => {
  const { t } = useTranslation();
  const { loading, setLoading, user, authRequest, setUser } = useAuth();
  const [loadingTwo, setLoadingTwo] = useState(true);
  const [myAccountData, setMyAccountData] = useState<MyAccountData | null>(
    null
  );
  const [selectedLogo, setSelectedLogo] = useState("");
  const [users, setUsers] = useState<TUserClient[]>([]);
  const access_role = tokenService.getUserFromToken()?.access_role;

  useEffect(() => {
    if (user) setSelectedLogo(user.logo);
  }, [user]);

  const getUsers = async () => {
    const response = await clientService.users.get();
    setUsers(response.data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const navigate = useNavigate();

  const deleteLogo = async () => {
    openConfirmModal({
      title: "¿Estás seguro que deseas borrar tu logo?",
      children: "Esta acción no se puede deshacer.",
      labels: {
        confirm: "Borrar",
        cancel: "Cancelar",
      },
      onConfirm: async () => {
        setLoading(true);
        await authRequest(clientService.info.delete);
        setLoading(false);
        navigate(0);
      },
    });
  };

  const openImageModal = () =>
    openModal({
      title: t("actions.upload") + " " + t("myAccount.orgLogo"),
      children: <ImageCropper callback={uploadImage} />,
    });

  const form = useForm({
    initialValues: {
      informacionDeLaOrganizacion: {
        nombreComercial: "",
        razonSocial: "",
        perteneceAGrupoEconomico: "",
        grupoEconomico: "",
        ubicacion: "",
        tipo: "",
        sectorEconomico: "",
        idioma: "",
      },
      informacionDeContacto: {
        nombres: "",
        apellidos: "",
        correo: "",
        codigoPais: "",
        telefono: "",
        cargo: "",
      },
    },

    validate: {
      informacionDeLaOrganizacion: {
        nombreComercial: (value) => !value && "Campo requerido",
        razonSocial: (value) => !value && "Campo requerido",
        perteneceAGrupoEconomico: (value) => !value && "Campo requerido",
        grupoEconomico: (value) => {
          if (
            form.values.informacionDeLaOrganizacion.perteneceAGrupoEconomico ===
            "si"
          ) {
            return value === "" && "Campo requerido";
          }
          return null;
        },
        ubicacion: (value) => !value && "Campo requerido",
        tipo: (value) => !value && "Campo requerido",
        sectorEconomico: (value) => !value && "Campo requerido",
        idioma: (value) => !value && "Campo requerido",
      },
      informacionDeContacto: {
        nombres: (value) => !value && "Campo requerido",
        apellidos: (value) => !value && "Campo requerido",
        correo: (value) => !value && "Campo requerido",
        codigoPais: (value) => !value && "Campo requerido",
        telefono: (value) => !value && "Campo requerido",
        cargo: (value) => !value && "Campo requerido",
      },
    },
  });

  const myProfileForm = useForm({
    initialValues: {
      nombres: "",
      apellidos: "",
      idioma: "",
      correo: "",
    },
    validate: {
      nombres: (value) => !value && "Campo requerido",
      apellidos: (value) => !value && "Campo requerido",
      idioma: (value) => !value && "Campo requerido",
      correo: (value) => !value && "Campo requerido",
    },
  });

  useEffect(() => {
    const fetchAndSetFormData = async () => {
      setLoading(true);
      const responseClientProfile = await clientService.profile.get();
      const data = responseClientProfile.data;
      const accData: MyAccountData = {
        informacionDeLaOrganizacion: {
          nombreComercial: data.profile.trade_name,
          razonSocial: data.profile.business_name,
          perteneceAGrupoEconomico: data.profile.is_in_economic_group
            ? "si"
            : "no",
          grupoEconomico: data.profile.economic_group_name,
          idioma: data.profile.preferred_language,
          ubicacion: data.profile.country?.toString(),
          tipo: data.profile.company_type?.toString(),
          sectorEconomico: data.profile.economic_sector?.toString(),
        },
        informacionDeContacto: {
          nombres: data.profile.contact_name,
          apellidos: data.profile.contact_lastname,
          correo: data.profile.contact_email,
          codigoPais: data.profile.contact_phone_country?.toString(),
          telefono: data.profile.contact_phone,
          cargo: data.profile.contact_role,
        },
        paises: data.countries.map((c: any) => ({
          id: c.id,
          nombre: c.name,
          fotoUrl: c.flag_url,
          codigoTelefonoPais: c.phone_prefix,
        })),
        tiposDeOrganizacion: data.company_types.map((c: any) => ({
          id: c.id,
          nombre: c.name,
        })),
        sectoresEconomicos: data.economic_sectors.map((c: any) => ({
          id: c.id,
          nombre: c.name,
        })),
      };
      setMyAccountData(accData);
      form.setValues({
        informacionDeLaOrganizacion: accData.informacionDeLaOrganizacion,
        informacionDeContacto: accData.informacionDeContacto,
      });
      setLoading(false);
    }

    if (!myAccountData) {
      fetchAndSetFormData();
    }
  }, [setLoading, myAccountData, form, authRequest]);

  useEffect(() => {
    const fetchAndSetFormData = async () => {
      setLoadingTwo(true);
      const response = await userService.get();
      myProfileForm.setValues({
        nombres: response.data.first_name,
        apellidos: response.data.last_name,
        idioma: response.data.preferred_language,
        correo: response.data.email,
      });
      setLoadingTwo(false);
    };

    if (user) {
      fetchAndSetFormData();
    }
  }, [user]);

  const uploadImage = async (dataUrl: string) => {
    const imgResponse = await fetch(dataUrl);
    const buffer = await imgResponse.arrayBuffer();
    const imageType = dataUrl.match(/^data:image\/([a-z]+);base64,/);
    if (!imageType) throw new Error("Tipo de imagen inválido.");
    const file = new File([buffer], `logo.${imageType[1]}`, {
      type: `image/${imageType[1]}`,
    });
    const formData = new FormData();
    formData.append("logo", file);
    setLoading(true);
    await authRequest(clientService.info.put, formData);
    setLoading(false);
    navigate(0);
  };

  const acceptSavedProfile = () => {
    if (user) {
      navigate(0);
      if (user.hasMeasurementProcess) {
        navigate("/mediciones");
      } else if (user.hasAULA) {
        navigate("/aula-virtual");
      } else {
        navigate("/inicio");
      }
      const updatedUser = user;
      updatedUser.completedProfile = true;
      setUser(updatedUser);
      closeAllModals();
    } else {
      showNotification({
        title: "Error",
        message: "Error al guardar los datos",
        color: "red",
      });
    }
  };

  const saveMyProfile = async () => {
    if (myProfileForm.validate().hasErrors) {
      showNotification({
        title: "Error",
        message: "Por favor, revise los campos marcados en rojo",
        color: "red",
      });
    } else {
      setLoading(true);
      try {
        await userService.put({
          preferred_language: myProfileForm.values.idioma,
          first_name: myProfileForm.values.nombres,
          last_name: myProfileForm.values.apellidos,
          email: myProfileForm.values.correo,
        });
        setLoading(false);
        openModal({
          title: "Datos guardados",
          children: (
            <>
              <Text size="sm">Los cambios han sido guardados con éxito.</Text>
              <Button fullWidth onClick={() => acceptSavedProfile()} mt="md">
                Aceptar
              </Button>
            </>
          ),
          onClose() {
            acceptSavedProfile();
          },
        });
      } catch {
        showNotification({
          title: "Error",
          message: "Error al guardar los datos",
          color: "red",
        });
        setLoading(false);
      }
    }
  };

  const saveProfile = async () => {
    if (form.validate().hasErrors) {
      showNotification({
        title: "Error",
        message: "Por favor, revise los campos marcados en rojo",
        color: "red",
      });
    } else {
      setLoading(true);
      try {
        await clientService.profile.put({
          contact_name: form.values.informacionDeContacto.nombres,
          contact_lastname: form.values.informacionDeContacto.apellidos,
          contact_role: form.values.informacionDeContacto.cargo,
          contact_email: form.values.informacionDeContacto.correo,
          contact_phone: form.values.informacionDeContacto.telefono,
          contact_phone_country: parseInt(
            form.values.informacionDeContacto.codigoPais
          ),
          trade_name: form.values.informacionDeLaOrganizacion.nombreComercial,
          business_name: form.values.informacionDeLaOrganizacion.razonSocial,
          is_in_economic_group:
            form.values.informacionDeLaOrganizacion.perteneceAGrupoEconomico ===
            "si",
          economic_group_name:
            form.values.informacionDeLaOrganizacion.grupoEconomico || null,
          company_type: parseInt(form.values.informacionDeLaOrganizacion.tipo),
          economic_sector: parseInt(
            form.values.informacionDeLaOrganizacion.sectorEconomico
          ),
          country: parseInt(form.values.informacionDeLaOrganizacion.ubicacion),
          preferred_language: form.values.informacionDeLaOrganizacion.idioma,
        });
        setLoading(false);
        openModal({
          title: "Datos guardados",
          children: (
            <>
              <Text size="sm">Los cambios han sido guardados con éxito.</Text>
              <Button fullWidth onClick={() => acceptSavedProfile()} mt="md">
                Aceptar
              </Button>
            </>
          ),
          onClose() {
            acceptSavedProfile();
          },
        });
      } catch {
        showNotification({
          title: "Error",
          message: "Error al guardar los datos",
          color: "red",
        });
        setLoading(false);
      }
    }
  };

  const deleteUser = async (id: number, email: string) => {
    openConfirmModal({
      title: <b>{t("actions.delete")} {email}</b>,
      children: t("myAccount.confirmDelete") + " " + t("myAccount.thisUser") + "?",
      labels: {
        confirm: t("actions.delete"),
        cancel: t("actions.cancel"),
      },
      onConfirm: async () => {
        setLoading(true);
        await clientService.users.delete(id);
        setLoading(false);
        setUsers(users.filter((u) => u.id !== id));
      },
    });
  };

  return (
    <GenderLabAppShell>
      <Flex gap="md" align="center">
        <GenderLabBreadcrumbs
          breadcrumbs={[{ link: "/mi-cuenta", title: t("appShell.profile") }]}
        />
        {(access_role === "superuser" ||
          access_role === "partner" ||
          access_role === "economic_group_manager") && (
          <ClientSelector access_role={access_role} />
        )}{" "}
      </Flex>

      <Alert color="yellow" icon={<BsInfoCircle />} title={t("myAccount.support")} mb="md">
        <Text>
          {t("mailSupport")}{" "}
          <Anchor
            href="mailto:support@elsa.la"
            sx={{
              color: linkColor,
              textDecoration: "underline",
            }}
          >
            support@elsa.la
          </Anchor>
          .
        </Text>
      </Alert>

      <Tabs defaultValue="perfil-de-la-org">
        <Tabs.List>
          <Tabs.Tab value="perfil-de-la-org">
            {t("myAccount.orgProfile")}
          </Tabs.Tab>
          <Tabs.Tab value="usuarios">{t("myAccount.users")}</Tabs.Tab>
          <Tabs.Tab value="mi-perfil">{t("myAccount.myProfile")}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="perfil-de-la-org">
          <form>
            <Grid mt="md">
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Title order={4}>{t("myAccount.orgInfo")}</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Center>
                  <LabelText>{t("myAccount.orgLogo")}</LabelText>
                </Center>
              </Grid.Col>
              {selectedLogo ? (
                <Grid.Col span={12}>
                  <Center style={{ position: "relative" }}>
                    <Image
                      src={selectedLogo}
                      alt={t("myAccount.orgLogo")}
                      width={250}
                      radius="md"
                    />
                  </Center>
                </Grid.Col>
              ) : (
                <Grid.Col span={12}>
                  <Center>
                    <Skeleton height={250} circle />
                  </Center>
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Center>
                  <Flex gap="xs">
                    <Button size="xs" color="green" onClick={openImageModal}>
                      <AiOutlineEdit color="white" />
                    </Button>
                    {user?.logo !== DEFAULT_LOGO && (
                      <Button
                        size="xs"
                        color="red"
                        onClick={deleteLogo}
                        disabled={loading}
                      >
                        <AiOutlineDelete color="white" />
                      </Button>
                    )}
                  </Flex>
                </Center>
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("myAccount.tradeName")}
                  disabled={loading}
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.nombreComercial"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("myAccount.businessLegalName")}
                  disabled={loading}
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.razonSocial"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <Select
                  label={t("countryLocation")}
                  disabled={loading}
                  itemComponent={SelectItemWithIcon}
                  data={
                    myAccountData?.paises
                      .map((p) => ({
                        image: p.fotoUrl,
                        label: p.nombre,
                        value: p.id.toString(),
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label)) || []
                  }
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.ubicacion"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <Select
                  label={t("myAccount.orgEconomicSector")}
                  disabled={loading}
                  data={
                    myAccountData?.sectoresEconomicos.map((p) => ({
                      label: p.nombre,
                      value: p.id.toString(),
                    })) || []
                  }
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.sectorEconomico"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <Select
                  label={t("myAccount.orgType")}
                  disabled={loading}
                  data={
                    myAccountData?.tiposDeOrganizacion.map((p) => ({
                      label: p.nombre,
                      value: p.id.toString(),
                    })) || []
                  }
                  {...form.getInputProps("informacionDeLaOrganizacion.tipo")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <Radio.Group
                  label={t("myAccount.belongsEconomicGroup")}
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.perteneceAGrupoEconomico"
                  )}
                  onChange={(value) =>
                    onChangeCleanFields(
                      value,
                      "informacionDeLaOrganizacion.perteneceAGrupoEconomico",
                      ["informacionDeLaOrganizacion.grupoEconomico"],
                      "no",
                      form
                    )
                  }
                >
                  <Radio value="si" label={t("yes")} />
                  <Radio value="no" label={t("no")} />
                </Radio.Group>
              </Grid.Col>
              <Grid.Col span={3} md={1.5}>
                <Select
                  label={t("myAccount.language")}
                  disabled={loadingTwo}
                  data={languageOptions}
                  {...form.getInputProps("informacionDeLaOrganizacion.idioma")}
                />
              </Grid.Col>
              {form.values.informacionDeLaOrganizacion
                .perteneceAGrupoEconomico === "si" && (
                <Grid.Col span={12} md={6}>
                  <TextInput
                    disabled={
                      form.values.informacionDeLaOrganizacion
                        .perteneceAGrupoEconomico !== "si" || loading
                    }
                    label={t("myAccount.economicGroupName")}
                    {...form.getInputProps(
                      "informacionDeLaOrganizacion.grupoEconomico"
                    )}
                  />
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
            </Grid>
            <Grid mt="md">
              <Grid.Col span={12}>
                <Title order={4}>{t("myAccount.contactInfo")}</Title>
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("myAccount.names")}
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.nombres")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("myAccount.surnames")}
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.apellidos")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("login.email")}
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.correo")}
                />
              </Grid.Col>
              <Grid.Col span={3} md={1.5}>
                <Select
                  label={t("myAccount.phoneCode")}
                  disabled={loading}
                  itemComponent={SelectItemWithIcon}
                  data={
                    myAccountData?.paises
                      .map((p) => ({
                        image: p.fotoUrl,
                        label: p.codigoTelefonoPais,
                        value: p.id.toString(),
                      }))
                      .sort((a, b) => parseInt(a.label) - parseInt(b.label)) ||
                    []
                  }
                  {...form.getInputProps("informacionDeContacto.codigoPais")}
                />
              </Grid.Col>
              <Grid.Col span={9} md={4.5}>
                <TextInput
                  label={t("myAccount.phone")}
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.telefono")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("myAccount.position")}
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.cargo")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
            </Grid>
          </form>
          <Button onClick={saveProfile} disabled={loading} my="md">
            {t("actions.save")}
          </Button>
        </Tabs.Panel>

        <Tabs.Panel value="usuarios">
          <Title order={4} my="md">
            {t("myAccount.usersList")}
          </Title>
          <Paper my="md">
            <Table withBorder withColumnBorders striped>
              <thead>
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("login.email")}</th>
                  <th>{t("creationDate")}</th>
                  <th scope="col" style={{ width: "5%" }} />
                </tr>
              </thead>
              <tbody>
                {users.map((u) => (
                  <tr key={u.id}>
                    <td>
                      {u.first_name} {u.last_name}
                    </td>
                    <td>{u.email}</td>
                    <td>{format(u.created_at, "dd/MM/yyyy")}</td>
                    <td>
                      <Center>
                        <EliminarButton
                          onClick={() => deleteUser(u.id, u.email)}
                        />
                      </Center>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
          <Divider my="md" />
          <Flex gap="sm">
            <AddUserButton callback={async () => await getUsers()} />
          </Flex>
        </Tabs.Panel>


        <Tabs.Panel value="mi-perfil">
          <form>
            <Title order={4} my="md">
              {t("myAccount.myProfile")}
            </Title>
            <Grid mt="md">
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("myAccount.names")}
                  disabled={loadingTwo}
                  {...myProfileForm.getInputProps("nombres")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("myAccount.surnames")}
                  disabled={loadingTwo}
                  {...myProfileForm.getInputProps("apellidos")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label={t("login.email")}
                  disabled={loadingTwo}
                  {...myProfileForm.getInputProps("correo")}
                />
              </Grid.Col>
              <Grid.Col span={3} md={1.5}>
                <Select
                  label={t("myAccount.language")}
                  disabled={loadingTwo}
                  data={languageOptions}
                  {...myProfileForm.getInputProps("idioma")}
                />
              </Grid.Col>
            </Grid>
          </form>
          <Button onClick={saveMyProfile} disabled={loadingTwo} my="md">
            {t("actions.save")}
          </Button>
        </Tabs.Panel>
      </Tabs>
    </GenderLabAppShell>
  );
};
