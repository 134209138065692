import { Flex } from "@mantine/core";
import { TTolerance, TToleranceCategory } from "../../types";
import {
  ToleranciaHeatmap,
  ResultadosCard,
  SectionTitle,
  TarjetaTolerancia,
  GenderLabLoader,
  SelectCortesData,
  ConceptoHeatmap,
  ToleranciaGrid
} from "../../components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  tolerance: TTolerance | null;
};

export const ToleranciaResultados = ({ tolerance }: Props) => {
  const { t } = useTranslation();
  const [toleranceHeatmapData, setToleranceHeatmapData] = useState<{
    [key: string]: TToleranceCategory;
  } | null>(null);
  const [conceptHeatmapData, setConceptHeatmapData] = useState<{
    [key: string]: TToleranceCategory;
  } | null>(null);

  useEffect(() => {
    if (tolerance) {
      setToleranceHeatmapData(tolerance.gender);
      setConceptHeatmapData(tolerance.gender);
    }
  }, [tolerance]);

  const onSelectChange = (
    value: string | null,
    setData: React.Dispatch<
      React.SetStateAction<{
        [key: string]: TToleranceCategory;
      } | null>
    >
  ) => {
    if (tolerance) {
      switch (value) {
        case "genero":
          setData(tolerance.gender);
          break;
        case "edad":
          setData(tolerance.age);
          break;
        case "orientacion-sexual":
          setData(tolerance.sexual_orientation);
          break;
        case "grupo-etnico-racial":
          setData(tolerance.ethnicity);
          break;
        case "puesto-de-trabajo":
          setData(tolerance.job);
          break;
        case "area":
          setData(tolerance.area);
          break;
        case "centro-de-trabajo":
          setData(tolerance.workplace);
          break;
        case "turno":
          setData(tolerance.work_shift);
          break;
      }
    }
  };

  const select = (
    setData: React.Dispatch<
      React.SetStateAction<{
        [key: string]: TToleranceCategory;
      } | null>
    >
  ) => (
    <SelectCortesData
      onSelectChange={(value) => onSelectChange(value, setData)}
      genderDisabled={!tolerance?.gender}
      ageDisabled={!tolerance?.age}
      sexualOrientationDisabled={!tolerance?.sexual_orientation}
      ethnicityDisabled={!tolerance?.ethnicity}
      jobDisabled={!tolerance?.job}
      areaDisabled={!tolerance?.area}
      workplaceDisabled={!tolerance?.workplace}
      workShiftDisabled={!tolerance?.work_shift}
    />
  );

  if (!tolerance || !toleranceHeatmapData || !conceptHeatmapData) {
    return <GenderLabLoader />;
  } else {
    return (
      <Flex direction="column" gap="md">
        <SectionTitle
          title={t("tabs.tolerance")}
          description="Percepciones de las personas encuestadas acerca de situaciones de hostigamiento o acoso sexual laboral."
        />
        <Flex gap="md" direction={{ base: "column", md: "row" }}>
          <ResultadosCard title="Hostigamiento o acoso de género">
            Comportamientos verbales y no verbales que transmiten hostilidad,
            objetivación, exclusión o estatus de inferioridad sobre miembros de
            un género. Es el tipo más común de acoso sexual y el más
            naturalizado por la sociedad.
          </ResultadosCard>
          <ResultadosCard title="Atención sexual no deseada">
            Avances sexuales verbales o físicos no deseados como expresiones de
            interés romántico o sexual que son incómodas u ofensivas para la
            persona que las recibe o conductas relacionadas con la intimidad de
            una persona (independientemente del deseo sexual).
          </ResultadosCard>
          <ResultadosCard title="Coerción sexual">
            Tratamientos laborales favorables o desfavorables que están
            condicionados a la actividad sexual. Es la forma más explícita de
            hostigamiento o acoso sexual laboral.
          </ResultadosCard>
        </Flex>
        <TarjetaTolerancia tolerance={tolerance.overview} />

        <ResultadosCard
          title={t("benchmark.toleranceHeatmapTitle")}
          description={t("benchmark.toleranceHeatmapDescription")}
          titleChildren={select(setToleranceHeatmapData)}
        >
          <ToleranciaHeatmap
            data={toleranceHeatmapData}
            total={tolerance.situations}
          />
          {ToleranciaGrid()}
        </ResultadosCard>

        <ResultadosCard
          title="Concepto de hostigamiento o acoso sexual laboral"
          description="Porcentaje que cree que estas características son necesarias para que haya acoso sexual."
          titleChildren={select(setConceptHeatmapData)}
        >
          <ConceptoHeatmap
            data={conceptHeatmapData}
            total={tolerance.sexual_harassment_knowledge}
          />
        </ResultadosCard>
      </Flex>
    );
  }
};
