import { Grid, Text } from "@mantine/core";
import { parsePercentage } from "../../utils";
import { Heatmap } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { useTranslation } from "react-i18next";

export const BenchmarkPrincipalesManifestaciones = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard
          title={t("benchmark.mainManifestationsTitle")}
          description={t("benchmark.mainManifestationsDescription")}
        >
          <Heatmap
            data={[
              {
                name: t("total"),
                data: [
                  parsePercentage(generalBenchmark.benchmarks.RB_0095),
                  parsePercentage(generalBenchmark.benchmarks.RB_0096),
                  parsePercentage(generalBenchmark.benchmarks.RB_0097),
                  parsePercentage(generalBenchmark.benchmarks.RB_0098),
                  parsePercentage(generalBenchmark.benchmarks.RB_0099),
                  parsePercentage(generalBenchmark.benchmarks.RB_0100),
                  parsePercentage(generalBenchmark.benchmarks.RB_0101),
                  parsePercentage(generalBenchmark.benchmarks.RB_0102),
                  parsePercentage(generalBenchmark.benchmarks.RB_0103),
                  parsePercentage(generalBenchmark.benchmarks.RB_0104),
                  parsePercentage(generalBenchmark.benchmarks.RB_0105),
                  parsePercentage(generalBenchmark.benchmarks.RB_0106),
                ],
              },
              {
                name: t("nonBinary"),
                data: [
                  parsePercentage(generalBenchmark.benchmarks.RB_0131),
                  parsePercentage(generalBenchmark.benchmarks.RB_0132),
                  parsePercentage(generalBenchmark.benchmarks.RB_0133),
                  parsePercentage(generalBenchmark.benchmarks.RB_0134),
                  parsePercentage(generalBenchmark.benchmarks.RB_0135),
                  parsePercentage(generalBenchmark.benchmarks.RB_0136),
                  parsePercentage(generalBenchmark.benchmarks.RB_0137),
                  parsePercentage(generalBenchmark.benchmarks.RB_0138),
                  parsePercentage(generalBenchmark.benchmarks.RB_0139),
                  parsePercentage(generalBenchmark.benchmarks.RB_0140),
                  parsePercentage(generalBenchmark.benchmarks.RB_0141),
                  parsePercentage(generalBenchmark.benchmarks.RB_0142),
                ],
              },
              {
                name: t("female"),
                data: [
                  parsePercentage(generalBenchmark.benchmarks.RB_0119),
                  parsePercentage(generalBenchmark.benchmarks.RB_0120),
                  parsePercentage(generalBenchmark.benchmarks.RB_0121),
                  parsePercentage(generalBenchmark.benchmarks.RB_0122),
                  parsePercentage(generalBenchmark.benchmarks.RB_0123),
                  parsePercentage(generalBenchmark.benchmarks.RB_0124),
                  parsePercentage(generalBenchmark.benchmarks.RB_0125),
                  parsePercentage(generalBenchmark.benchmarks.RB_0126),
                  parsePercentage(generalBenchmark.benchmarks.RB_0127),
                  parsePercentage(generalBenchmark.benchmarks.RB_0128),
                  parsePercentage(generalBenchmark.benchmarks.RB_0129),
                  parsePercentage(generalBenchmark.benchmarks.RB_0130),
                ],
              },
              {
                name: t("male"),
                data: [
                  parsePercentage(generalBenchmark.benchmarks.RB_0107),
                  parsePercentage(generalBenchmark.benchmarks.RB_0108),
                  parsePercentage(generalBenchmark.benchmarks.RB_0109),
                  parsePercentage(generalBenchmark.benchmarks.RB_0110),
                  parsePercentage(generalBenchmark.benchmarks.RB_0111),
                  parsePercentage(generalBenchmark.benchmarks.RB_0112),
                  parsePercentage(generalBenchmark.benchmarks.RB_0113),
                  parsePercentage(generalBenchmark.benchmarks.RB_0114),
                  parsePercentage(generalBenchmark.benchmarks.RB_0115),
                  parsePercentage(generalBenchmark.benchmarks.RB_0116),
                  parsePercentage(generalBenchmark.benchmarks.RB_0117),
                  parsePercentage(generalBenchmark.benchmarks.RB_0118),
                ],
              },
            ]}
            categories={[
              "M1",
              "M2",
              "M3",
              "M4",
              "M5",
              "M6",
              "M7",
              "M8",
              "M9",
              "M10",
              "M11",
              "M12",
            ]}
          />
          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M1: Comentarios o bromas de contenido sexual o sexista.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M2: Comentarios o bromas sobre de su orientación sexual o
                identidad de género.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M3: Comentarios sobre su apariencia física o cuerpo que le
                generaron incomodidad, incluyendo también gestos, silbidos o
                sonidos.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M4: Invitaciones reiteradas a salir a pesar de que no ofreció
                respuesta o no dio respuesta positiva.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M5: Contactos reiterados a través de mensajes electrónicos,
                chats, WhatsApp, Facebook, entre otros, con fines no laborales
                que le incomodaron.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M6: Solicitudes o envíos sin consentimiento de fotos o videos de
                material sexual explícito o de connotación sexual.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M7: Acercamientos físicos o tocamientos (rozar, sobar, masajear,
                etc.) sin necesidad en alguna parte del cuerpo como hombros,
                espalda baja, rodilla u otras partes.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M8: Tocamientos y/o rozamientos en zonas privadas del cuerpo,
                como senos, genitales, etc.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M9: Miradas persistentes con connotación sexual.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M10: Insinuaciones o invitaciones a salir de un jefe/a a pesar
                de que de alguna manera le expresó su incomodidad.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M11: Insinuaciones o propuestas de mejoras en su situación
                laboral a cambio de aceptar invitaciones, acercamientos o
                encuentros sexuales.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                M12: Amenazas o insinuaciones de despido, retiro de beneficios o
                tomar alguna represalia por no aceptar invitaciones,
                acercamientos, o encuentros sexuales.
              </Text>
            </Grid.Col>
          </Grid>
        </ResultadosCard>
    );
}