import { Carousel, Embla } from "@mantine/carousel";
import { chunk } from "lodash";
import { CursoCard } from "../CursoCard";
import { Center, Grid, Pagination, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { TCourse } from "../../../types";
import { useTranslation } from "react-i18next";

type Props = {
  courses: TCourse[];
  type: "purchased" | "available" | "inactive";
};

export const CursoCarousel = ({ courses, type }: Props) => {
  const { t } = useTranslation();
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [gridColSpan, setGridColSpan] = useState<number>(3);
  const [activePage, setActivePage] = useState<number>(1);
  const [coursesPerSlide, setCoursesPerSlide] = useState<number>(6);

  useEffect(() => {
    const detectSize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowWidth]);

  const [coursesToShow, setCoursesToShow] = useState<any[]>(
    chunk(courses, coursesPerSlide)
  );

  useEffect(() => {
    if (windowWidth > 1024) {
      setGridColSpan(4);
      setCoursesToShow(chunk(courses, 6));
      setCoursesPerSlide(6);
    } else if (windowWidth <= 1024 && windowWidth > 768) {
      setGridColSpan(6);
      setCoursesToShow(chunk(courses, 4));
      setCoursesPerSlide(4);
    } else {
      setGridColSpan(12);
      setCoursesToShow(chunk(courses, 1));
      setCoursesPerSlide(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  useEffect(() => {
    if (embla) embla.scrollTo(activePage - 1, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  return (
    <>
      {courses.length === 0 ? (
        <Text color="dimmed">{t("noCourses")}</Text>
      ) : (
        <>
          <Carousel
            getEmblaApi={setEmbla}
            withControls={false}
            slideSize="100%"
            align="start"
            draggable={false}
            mx="auto"
            sx={{ width: "100%" }}
          >
            {coursesToShow.map((chunk: typeof courses, index: number) => (
              <Carousel.Slide key={index}>
                <Grid>
                  {chunk.map((curso, index: number) => (
                    <Grid.Col span={gridColSpan} key={index}>
                      <CursoCard
                        key={index}
                        title={curso.name}
                        image={curso.img_url}
                        type={type}
                        id={curso.id}
                      />
                    </Grid.Col>
                  ))}
                </Grid>
              </Carousel.Slide>
            ))}
          </Carousel>
          <Center mt="md">
            <Pagination
              page={activePage}
              onChange={setActivePage}
              total={coursesToShow.length}
              color="blue"
            />
          </Center>
        </>
      )}
    </>
  );
};
