import { Grid, Paper, Title, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export type BienvenidaInicioProps = {
  tradeName: string;
};

export const BienvenidaInicio = ({
  tradeName,
}: BienvenidaInicioProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Grid.Col span={12} sx={{ marginTop: "1rem" }}>
      <Paper radius="md" withBorder p="md">
        <Title order={3}>{t("hello")}, {tradeName}!</Title>
        <Text size="md" color="dimmed" mt="sm">
          {t("welcome")}
        </Text>
      </Paper>
    </Grid.Col>
  );
};
