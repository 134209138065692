import { Grid, SimpleGrid } from "@mantine/core";
import FeatureCard from "./FeatureCard";
import { useTranslation } from "react-i18next";

export const CardsInicio = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Grid.Col span={12}>
      <SimpleGrid
        cols={3}
        spacing="xl"
        verticalSpacing="xl"
        breakpoints={[
          { maxWidth: 1130, cols: 2 },
          { maxWidth: 755, cols: 1 },
        ]}
      >
        <FeatureCard
          title={t("appShell.benchmark")}
          description={t("home.benchmarkDescription")}
          redirectTo="/benchmark"
          buttonText={t("home.buttonBenchmarkText")}
          badgeColor="blue"
          badgeText={t("new")}
          imageUrl="img/banners/benchmarking-rojo.png"
        />
        <FeatureCard
          title={t("appShell.actionPlan")}
          description={t("home.actionPlanDescription")}
          redirectTo="/plan-de-accion"
          buttonText={t("home.buttonActionPlanText")}
          imageUrl="img/banners/plan-accion-celeste.png"
          badgeColor="blue"
          badgeText="Beta"
        />
        <FeatureCard
          title={t("appShell.virtualClassroom")}
          description={t("home.virtualClassroomDescription")}
          redirectTo="/aula-virtual"
          buttonText={t("home.buttonVirtualClassroomText")}
          imageUrl="img/banners/aula-morado.png"
        />
      </SimpleGrid>
    </Grid.Col>
  );
};
