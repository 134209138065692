import { Flex, Text } from "@mantine/core";

import { TWitnesses } from "../../types";
import {
  PorcentajeCardLarge,
  PorcentajeCardLargeBenchmark,
  PorcentajeCardSmall,
  ResultadosCard,
  SectionTitle,
  GenderLabLoader,
} from "../../components";
import { colorAzul1 } from "../../utils";
import { useAuth, useMetrics } from "../../context";
import { useTranslation } from "react-i18next";

type Props = {
  witnesses: TWitnesses | null;
};

export const TestigosResultados = ({ witnesses }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { regionalBenchmark, countryBenchmark, previousMetrics } = useMetrics();

  if (!witnesses || !user) {
    return <GenderLabLoader />;
  } else {
    return (
      <Flex direction="column" gap="md">
        <SectionTitle
          title={t("tabs.witnesses")}
          description="Personas que han visto o escuchado situaciones de hostigamiento o acoso sexual laboral y sus reacciones al respecto."
        />
        <ResultadosCard
          title={t("benchmark.witness.title")}
          description={t("benchmark.witness.description")}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            gap="sm"
            justify="space-evenly"
            align="center"
          >
            <PorcentajeCardSmall
              description={t("benchmark.witness.percentageCardDescription")}
              percentage={witnesses.general_witness}
              color={colorAzul1}
            />
          </Flex>
        </ResultadosCard>
        <Flex direction={{ base: "column", md: "row" }} gap="sm">
          <ResultadosCard
            title="¿De qué situaciones fueron testigos?"
            description="Porcentaje de testigos que han presenciado las siguientes manifestaciones."
          >
            <Flex justify="center">
              <Flex direction="column" gap="xs" maw={600}>
                {witnesses.situations.map((situation, idx) => (
                  <div key={idx}>
                    <PorcentajeCardLarge
                      description={situation[0]}
                      percentage={situation[1]}
                      sx={{
                        backgroundColor: "#daeeed",
                        color: "#109089",
                      }}
                    />
                  </div>
                ))}
              </Flex>
            </Flex>
          </ResultadosCard>
          <ResultadosCard
            title={t("benchmark.witness.reactionsTitle")}
            description={t("benchmark.witness.reactionsDescription")}
            alignSelf="flex-start"
          >
            <Flex justify="center">
              <Flex direction="column" gap="xs" maw={600}>
                <Flex justify="flex-end" gap={10}>
                  <div style={{ width: 80 }}>
                    <Text fz={12}>{t("appShell.yourResults")}</Text>
                  </div>
                  {user.hasRegionalBenchmark && regionalBenchmark && (
                    <div style={{ width: 80 }}>
                      <Text fz={12}>{t("benchmark.regional")}</Text>
                    </div>
                  )}
                  {user.hasCountryBenchmark && countryBenchmark && (
                    <div style={{ width: 80 }}>
                      <Text fz={12}>{t("benchmark.country")}</Text>
                    </div>
                  )}
                  {user.hasEvolutionaryResults && previousMetrics && (
                    <div style={{ width: 80 }}>
                      <Text fz={12}>Medición anterior</Text>
                    </div>
                  )}
                </Flex>
                {witnesses.actions.map((action, idx) => (
                  <div key={idx}>
                    <PorcentajeCardLargeBenchmark
                      description={action[0]}
                      percentage={action[1]}
                      sx={{
                        backgroundColor: "#eaddf3",
                        color: "#6f4dbc",
                      }}
                    />
                  </div>
                ))}
              </Flex>
            </Flex>
          </ResultadosCard>
        </Flex>
      </Flex>
    );
  }
};
