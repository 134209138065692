import { Flex, Text, Image, Container } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const GenderLabFooter = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container size={1200}>
        <Flex justify="end" align="center" mt="lg" gap="xs">
          <Text>{t("footer.supportedBy")}</Text>
          <Image width={100} src="/img/bid-logo.png" />
        </Flex>
      </Container>
    </>
  );
};
