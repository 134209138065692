import {
  Button,
  Flex,
  Modal,
  NumberInput,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { closeAllModals, openConfirmModal } from "@mantine/modals";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { FormErrorText } from "../../../components";
import { useAuth } from "../../../context";
import { TPersonalizationTableData, TTag } from "../../../types";
import { AddRowButton } from "./__addRowButton";
import { EditarEliminarButtonsCombo } from "./__editarEliminarButtonsCombo";
import { useTranslation } from "react-i18next";

type Props = {
  addButtonCallback: (data: any) => Promise<AxiosResponse<any, any>>;
  deleteButtonCallback: (id: number) => Promise<AxiosResponse<any, any>>;
  putButtonCallback: (
    id: number,
    data: any
  ) => Promise<AxiosResponse<any, any>>;
  tags: TTag[];
  rows: TPersonalizationTableData[];
  name: string;
  disabled?: boolean;
  minQuantityOfRegisters: number;
  size: "medium" | "large";
};

export const TablaPersonalizacion = ({
  addButtonCallback,
  deleteButtonCallback,
  putButtonCallback,
  tags,
  rows,
  name,
  disabled,
  minQuantityOfRegisters,
  size,
}: Props) => {
  const { t } = useTranslation();
  const minPerField = size === "medium" ? 20 : 50;

  const form = useForm({
    initialValues: {
      lista: rows.map((row) => ({
        ...row,
        tag: row.tag.toString(),
      })),
      descripcionTemp: "",
      tipoTemp: "",
      hombresTemp: 0,
      mujeresTemp: 0,
      noBinarioTemp: 0,
    },
    validate: {
      descripcionTemp: (value) => !value && "Campo requerido",
      tipoTemp: (value) => !value && "Campo requerido",
      hombresTemp: (value) => value === undefined && "Campo requerido",
      mujeresTemp: (value) => value === undefined && "Campo requerido",
      noBinarioTemp: (value) => value === undefined && "Campo requerido",
      lista: {
        description: (value: string) => !value && "Campo requerido",
        tag: (value) => !value && "Campo requerido",
        men_population: (value) => value === undefined && "Campo requerido",
        women_population: (value) => value === undefined && "Campo requerido",
        non_binary_population: (value) =>
          value === undefined && "Campo requerido",
      },
    },
  });

  const { loading, setLoading } = useAuth();

  const [opened, { open, close }] = useDisclosure(false);
  const [triedToSave, setTriedToSave] = useState(false);
  const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);

  const resetTempValues = () => {
    form.setFieldValue("descripcionTemp", "");
    form.setFieldValue("tipoTemp", "");
    form.setFieldValue("hombresTemp", 0);
    form.setFieldValue("mujeresTemp", 0);
    form.setFieldValue("noBinarioTemp", 0);
  };

  const onAddRowButtonClick = () => {
    open();
  };

  const closeModal = () => {
    close();
    resetTempValues();
    setTriedToSave(false);
  };

  const saveModal = async () => {
    const saveData = async () => {
      const toInsert = {
        tag: form.values.tipoTemp,
        description: form.values.descripcionTemp,
        men_population: form.values.hombresTemp,
        women_population: form.values.mujeresTemp,
        non_binary_population: form.values.noBinarioTemp,
      };
      setLoading(true);
      if (idToEdit) {
        const response = await putButtonCallback(idToEdit, toInsert);
        form.setValues({
          ...form.values,
          lista: form.values.lista.map((item) =>
            item.id === idToEdit ? { ...item, ...response.data } : item
          ),
        });
      } else {
        const response = await addButtonCallback(toInsert);
        form.insertListItem("lista", response.data);
      }
      closeModal();
      setIdToEdit(undefined);
      setLoading(false);
    };

    if (
      !form.validate().hasErrors &&
      checkTempPopulationDifferentFromZero() &&
      checkTempPopulationGreaterThanOrEqualThan(minPerField)
    ) {
      saveData();
    } else {
      setTriedToSave(true);
    }
  };

  const onDeleteRowButtonClick = async (item: any, index: number) => {
    openConfirmModal({
      title: `${t("actions.delete")} ${name}`,
      children: `¿Estás seguro que deseas eliminar el registro ${item.description}? Esta acción no se puede deshacer.`,
      onConfirm: async () => {
        await deleteButtonCallback(item.id);
        form.removeListItem("lista", index);
        closeAllModals();
      },
      labels: {
        confirm: t("actions.delete"),
        cancel: t("actions.cancel"),
      },
    });
  };

  const checkTempPopulationDifferentFromZero = () =>
    form.values.hombresTemp +
      form.values.mujeresTemp +
      form.values.noBinarioTemp !==
    0;

  const checkTempPopulationGreaterThanOrEqualThan = (quantity: number) =>
    form.values.hombresTemp +
      form.values.mujeresTemp +
      form.values.noBinarioTemp >=
    quantity;

  const checkRowPopulationDifferentFromZero = (index: number) =>
    form.values.lista[index].men_population +
      form.values.lista[index].women_population +
      form.values.lista[index].non_binary_population !==
    0;

  return (
    <>
      {form.values.lista.length === 0 ? (
        <Flex align="center" gap="md" mt="xs">
          <Text color="dimmed">
            La tabla no cuenta con registros. Puedes agregarlos haciendo click
            en el '+'.
          </Text>
          <AddRowButton onClick={onAddRowButtonClick} disabled={disabled} />
        </Flex>
      ) : (
        <Table mt="xs">
          <thead>
            <tr>
              <th scope="col" style={{ width: "20%" }}>
                Descripción
              </th>
              <th scope="col" style={{ width: "30%" }}>
                Tipo de {name}
              </th>
              <th scope="col">{t("men")}</th>
              <th scope="col">{t("women")}</th>
              <th scope="col">{t("nonBinary")}</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            <>
              {form.values.lista.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Text>{form.values.lista[index].description}</Text>
                  </td>
                  <td>
                    <Text>
                      {
                        tags.find((tag) => tag.id === parseInt(item.tag))
                          ?.description
                      }
                    </Text>
                  </td>
                  <td>
                    <Text>{form.values.lista[index].men_population}</Text>
                  </td>
                  <td>{form.values.lista[index].women_population}</td>
                  <td>{form.values.lista[index].non_binary_population}</td>
                  <td>
                    <Flex direction="column" gap="xs">
                      <EditarEliminarButtonsCombo
                        disabled={disabled}
                        onEditarClick={() => {
                          open();
                          form.setValues({
                            ...form.values,
                            descripcionTemp: item.description,
                            tipoTemp: item.tag.toString(),
                            hombresTemp: item.men_population,
                            mujeresTemp: item.women_population,
                            noBinarioTemp: item.non_binary_population,
                          });
                          setIdToEdit(item.id);
                        }}
                        onBorrarClick={() =>
                          onDeleteRowButtonClick(item, index)
                        }
                      />
                      {!checkRowPopulationDifferentFromZero(index) && (
                        <FormErrorText>
                          El total debe ser mayor que 0.
                        </FormErrorText>
                      )}
                    </Flex>
                  </td>
                </tr>
              ))}
            </>
            <tr>
              <td colSpan={6}>
                <AddRowButton
                  onClick={onAddRowButtonClick}
                  disabled={disabled}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
      <Modal
        opened={opened}
        onClose={close}
        title={`${idToEdit ? "Editar" : "Agregar"} ${name}`}
        withCloseButton={false}
      >
        <TextInput
          placeholder="Descripción"
          label="Descripción"
          {...form.getInputProps("descripcionTemp")}
        />
        <Select
          data={tags.map((tag) => ({
            label: tag.description,
            value: tag.id.toString(),
          }))}
          label="Tipo"
          placeholder="Seleccione una opción"
          {...form.getInputProps("tipoTemp")}
          mt="md"
        />
        <Flex gap="xs" mt="md">
          <NumberInput
            min={0}
            label={t("men")}
            {...form.getInputProps("hombresTemp")}
          />
          <NumberInput
            min={0}
            label={t("women")}
            {...form.getInputProps("mujeresTemp")}
          />
          <NumberInput
            min={0}
            label={t("nonBinary")}
            {...form.getInputProps("noBinarioTemp")}
          />
        </Flex>
        {!checkTempPopulationDifferentFromZero() && triedToSave && (
          <FormErrorText>Los tres campos no pueden ser 0</FormErrorText>
        )}
        {!checkTempPopulationGreaterThanOrEqualThan(minPerField) &&
          triedToSave && (
            <FormErrorText>
              Los tres campos deben sumar al menos {minPerField.toString()}
            </FormErrorText>
          )}
        <Flex justify="end" gap="xs" mt="lg">
          <Button
            variant="outline"
            color="red"
            onClick={closeModal}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={saveModal}
            color="green"
            variant="outline"
            disabled={loading}
          >
            Guardar
          </Button>
        </Flex>
      </Modal>
      {form.values.lista.length < minQuantityOfRegisters && (
        <FormErrorText>
          Debes tener al menos {minQuantityOfRegisters.toString()}{" "}
          {minQuantityOfRegisters === 1 ? "fila guardada" : "filas guardadas"}{" "}
          en esta subsección.
        </FormErrorText>
      )}
    </>
  );
};
