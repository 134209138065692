import { Flex } from "@mantine/core";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkWithResponsiveStyleProps } from "./GeneralBenchmarkProps";
import { BarChart } from "../Charts";
import { colorAzul1 } from "../../utils";
import { useTranslation } from "react-i18next";
import { splitTextWithMaxLength } from "../../utils/splitTextWithMaxLength";

export const BenchmarkPerfilAcosador = ({
    generalBenchmark,
    responsiveStyle,
}: GeneralBenchmarkWithResponsiveStyleProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard
            title={t("benchmark.harasserProfile.title")}
            description={t("benchmark.harasserProfile.description")}
        >
            <Flex
                direction={{ base: "column", xl: "row" }}
                gap="md"
                justify="space-evenly"
                align="center"
            >
                <BarChart
                    width={450}
                    colors={[colorAzul1]}
                    categories={[
                        t("men"),
                        t("women")
                    ]}
                    responsive={responsiveStyle}
                    title={t("benchmark.harasserProfile.genderTitle")}
                    seriesData={[
                        [
                            generalBenchmark.benchmarks.RB_0160,
                            generalBenchmark.benchmarks.RB_0161,
                        ],
                    ]}
                />
                <BarChart
                    width={500}
                    categories={[
                        t("benchmark.harasserProfile.categories.colleague"),
                        t("benchmark.harasserProfile.categories.boss"),
                        t("benchmark.harasserProfile.categories.subordinate"),
                        splitTextWithMaxLength(t("benchmark.harasserProfile.categories.otherWorker")),
                        t("benchmark.harasserProfile.categories.client"),
                    ]}
                    colors={[colorAzul1]}
                    responsive={responsiveStyle}
                    title={t("benchmark.harasserProfile.relationshipTitle")}
                    seriesData={[
                        [
                            generalBenchmark.benchmarks.RB_0162,
                            generalBenchmark.benchmarks.RB_0163,
                            generalBenchmark.benchmarks.RB_0164,
                            generalBenchmark.benchmarks.RB_0165,
                            generalBenchmark.benchmarks.RB_0166,
                        ],
                    ]}
                />
            </Flex>
        </ResultadosCard>
    );
}