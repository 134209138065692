import { useTranslation } from "react-i18next";
import { colorAzul1 } from "../../utils";
import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { splitTextWithMaxLength } from "../../utils/splitTextWithMaxLength";

export const BenchmarkResolucionDeCasosCard = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ResultadosCard title={t("caseResolution")}>
            <BarChart
                categories={[
                    splitTextWithMaxLength(t("benchmark.leadership.categories.homophobicHarassment"), 20),
                    splitTextWithMaxLength(t("benchmark.leadership.categories.hostileWorkEnvironment"), 20),
                    splitTextWithMaxLength(t("benchmark.leadership.categories.sexualBlackmail"), 20),
                    splitTextWithMaxLength(t("benchmark.leadership.categories.horizontalHarassment"), 20),
                ]}
                seriesNames={[t("percentage") + "(%)"]}
                seriesData={[
                    [
                        generalBenchmark.benchmarks.RB_0035,
                        generalBenchmark.benchmarks.RB_0036,
                        generalBenchmark.benchmarks.RB_0037,
                        generalBenchmark.benchmarks.RB_0038,
                    ],
                ]}
                colors={[colorAzul1]}
                width={500}
            />
        </ResultadosCard>
    );
};