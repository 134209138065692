import {
  Flex,
  Title,
  Text,
  Table,
  createStyles,
  Overlay,
  Space,
  Badge,
} from "@mantine/core";
import Chart from "react-apexcharts";
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";

import { ResultadosCard } from "../ResultadosCard";
import {
  roundToOneDecimal,
  roundToTwoDecimals,
  indexColors,
  colorRojoClaro1,
  parsePercentage,
} from "../../../utils";
import { CustomLink, InfoTooltip } from "../..";
import { PuntajeBonusPenalidad, SubindiceCard, Tacometro, TitleCard } from "..";
import {
  TAnswerScore,
  TCase,
  TIndexHistory,
  TIndexScore,
} from "../../../types";
import { useAuth, useMetrics } from "../../../context";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  tdThicker: {
    borderTop: "2px solid #dee2e6 !important",
  },
}));

type Props = {
  indexScore?: TIndexScore | null;
  indexHistory?: TIndexHistory | null;
  overlay?: boolean;
};

function minAndMaxAxis(data: TIndexHistory) {
  let minGlobal = Math.min(
    ...Object.values(data).map((entry) =>
      Math.min(entry.total_score, entry.regional_benchmark)
    )
  );
  let maxGlobal = Math.max(
    ...Object.values(data).map((entry) =>
      Math.max(entry.total_score, entry.regional_benchmark)
    )
  );

  minGlobal = Math.floor(minGlobal / 10) * 10;
  maxGlobal = Math.ceil(maxGlobal / 10) * 10;

  minGlobal = Math.max(0, minGlobal);
  maxGlobal = Math.min(100, maxGlobal);
  return { minGlobal, maxGlobal };
}

export const IndiceContent = ({ indexScore, indexHistory, overlay }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [nivel, setNivel] = useState<{ color: string; nombre: string }>(null!);

  const { regionalBenchmark } = useMetrics();
  const { user } = useAuth();

  useEffect(() => {
    if (indexScore) {
      const totalScore = indexScore.total_score;
      if (totalScore <= 60) {
        setNivel({ color: indexColors.inicial, nombre: "Inicial" });
      } else if (totalScore <= 70) {
        setNivel({ color: indexColors.intermedio, nombre: "Intermedio" });
      } else if (totalScore <= 80) {
        setNivel({
          color: indexColors.intermedioAlto,
          nombre: "Intermedio Alto",
        });
      } else if (totalScore <= 90) {
        setNivel({ color: indexColors.alto, nombre: "Alto" });
      } else {
        setNivel({ color: indexColors.muyAlto, nombre: "Muy Alto" });
      }
    }
  }, [indexScore]);

  const refs = {
    sistemaDePrevencionRef: useRef<HTMLDivElement | null>(null),
    conocimientoRef: useRef<HTMLDivElement | null>(null),
    toleranciaRef: useRef<HTMLDivElement | null>(null),
    climaRef: useRef<HTMLDivElement | null>(null),
    bonusRef: useRef<HTMLDivElement | null>(null),
    penalidadesRef: useRef<HTMLDivElement | null>(null),
  };

  const scrollToDiv = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref.current) {
      const targetPosition =
        ref.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: targetPosition - 100,
        behavior: "smooth",
      });
    }
  };

  const Italics = ({ children }: { children: React.ReactNode }) => (
    <Text color="dimmed" fs="italic">
      {children}
    </Text>
  );

  const TdLiItalics = ({ children }: { children: React.ReactNode }) => (
    <td>
      <Italics>
        <li>{children}</li>
      </Italics>
    </td>
  );

  const TdItalics = ({ children }: { children: React.ReactNode }) => (
    <td>
      <Italics>{children}</Italics>
    </td>
  );

  const TdLiTooltipItalics = ({
    children,
    tooltipText,
  }: {
    children: React.ReactNode;
    tooltipText: string;
  }) => (
    <td>
      <Flex align="center" gap={5}>
        <Italics>
          <li>{children}</li>
        </Italics>
        <InfoTooltip tooltipText={tooltipText} width={300} />
      </Flex>
    </td>
  );

  const TdTooltip = ({
    children,
    tooltipText,
  }: {
    children: React.ReactNode;
    tooltipText: string;
  }) => (
    <td>
      <Flex align="center" gap={5}>
        {children}
        <InfoTooltip tooltipText={tooltipText} width={300} />
      </Flex>
    </td>
  );

  const italicsRowAnswer = (answer: TAnswerScore, maxScore: number) => (
    <>
      <td>
        <Italics>{answer.answer}</Italics>
      </td>
      <td>
        <Italics>{answer.score}</Italics>
      </td>
      <td>
        <Italics>{maxScore}</Italics>
      </td>
    </>
  );

  const italicsRowPercentageBasedOffMaxScore = (
    points: number,
    maxScore: number
  ) => (
    <>
      <td>
        <Italics>{parsePercentage(points / maxScore)}%</Italics>
      </td>
      <td>
        <Italics>{points}</Italics>
      </td>
      <td>
        <Italics>{maxScore}</Italics>
      </td>
    </>
  );

  const italicsRowPercentage = (points: number, maxScore: number) => (
    <>
      <td>
        <Italics>{points}</Italics>
      </td>
      <td>
        <Italics>{maxScore}</Italics>
      </td>
    </>
  );

  const regularRow = (
    answer: TAnswerScore,
    maxScore: number,
    isFirstRow?: boolean
  ) => (
    <>
      <td className={!isFirstRow ? classes.tdThicker : ""}>{answer.answer}</td>
      <td className={!isFirstRow ? classes.tdThicker : ""}>{answer.score}</td>
      <td className={!isFirstRow ? classes.tdThicker : ""}>{maxScore}</td>
    </>
  );

  const sumRow = (sum: number, maxScore: number, isFirstRow?: boolean) => (
    <>
      <td className={!isFirstRow ? classes.tdThicker : ""}>{sum}</td>
      <td className={!isFirstRow ? classes.tdThicker : ""}>{maxScore}</td>
    </>
  );

  const trCase = (title: string, tooltipText: string, data: TCase) => (
    <tr>
      <TdLiTooltipItalics tooltipText={tooltipText}>{title}</TdLiTooltipItalics>
      <td>
        <Text fw={500}>{data.case_penalty_score}</Text>
      </td>
      <TdItalics>{data.biases.exacerbated_intent}</TdItalics>
      <TdItalics>{data.biases.natural_heterosexuality}</TdItalics>
      <TdItalics>{data.biases.ulterior_motives}</TdItalics>
      <TdItalics>{data.biases.victim_responsibility}</TdItalics>
      <TdItalics>{data.biases.overreaction}</TdItalics>
    </tr>
  );

  const penaltyTable = (
    title: string,
    description: string,
    cases: TCase[],
    penaltyAverage: number
  ) => (
    <>
      <Title order={4}>{title}</Title>
      <Text color="dimmed" fz="sm">
        {description}
      </Text>
      <Table mt="xs">
        <thead>
          <tr>
            <th>Casos de la encuesta</th>
            <th>Promedio</th>
            <th>Está exagerando</th>
            <th>Así se comportan hombres y mujeres</th>
            <th>Denuncia para lograr algo a cambio</th>
            <th>
              Es su responsabilidad por no denunciar o parar la situación antes
            </th>
            <th>Nadie tenía cómo saber que eso le incomodaba</th>
          </tr>
        </thead>
        <tbody>
          {trCase(
            "Caso de Hostigamiento Homofóbico",
            "Este caso presenta comentarios y “bromas” que tienen como finalidad burlarse de la orientación sexual asumida de la persona denunciante. Estas son manifestaciones de hostigamiento o acoso sexual homofóbico por sexismo.",
            cases[0]
          )}
          {trCase(
            "Caso de Ambiente Hostil",
            "Este caso presenta comentarios a viva voz sobre encuentros sexuales con colegas y otras mujeres y la sintonización de programas de entretenimiento sexualmente explícitos que generan un ambiente hostil.",
            cases[1]
          )}
          {trCase(
            "Caso de Chantaje Sexual",
            "Este caso presenta chantaje sexual a causa de que un jefe invita a salir, de manera reiterada, a su subordinada. El cese de la relación sexual resulta en el despido de la persona subordinada.",
            cases[2]
          )}
          {trCase(
            "Caso de Hostigamiento Horizontal",
            "Este caso presenta reiteradas invitaciones a salir que buscan establecer una relación más allá de lo laboral y profesional a pesar del rechazo de la persona denunciante.",
            cases[3]
          )}
          <tr>
            <td>
              <Text fw={500}>Tu penalidad</Text>
            </td>
            <td>
              <Text fw={500}>{penaltyAverage}</Text>
            </td>
            <td colSpan={5} />
          </tr>
        </tbody>
      </Table>
    </>
  );

  const defaultAnswerScore: TAnswerScore = {
    answer: "Respuesta default",
    score: 0,
  };

  const defaultCase: TCase = {
    case_penalty_score: 0,
    biases: {
      exacerbated_intent: 0,
      natural_heterosexuality: 0,
      ulterior_motives: 0,
      victim_responsibility: 0,
      overreaction: 0,
    },
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        <Flex direction="column" gap="md" style={{ position: "relative" }}>
          <Flex gap="xs" direction={{ base: "column", md: "row" }}>
            <Flex
              w={{ base: "100%", md: "50%" }}
              align="center"
              justify="center"
              direction="column"
              gap="xs"
            >
              <ResultadosCard alignSelf="center">
                <Flex direction="column" align="center">
                  <Title order={5}>Tu puntaje</Title>
                  <Tacometro
                    value={roundToOneDecimal(indexScore?.total_score || 50)}
                    big
                  />
                  <Flex direction="column" align="center">
                    <Flex align="center" gap={5}>
                      <Title order={5}>Nivel</Title>
                      <InfoTooltip
                        tooltipText={
                          <>
                            <li>{t("level.initial")} (0 - 60 {t("points")})</li>
                            <li>{t("level.intermediate")} (61 - 70 {t("points")})</li>
                            <li>{t("level.upperIntermediate")} (71 - 80)</li>
                            <li>{t("level.high")} (81 - 90)</li>
                            <li>{t("level.veryHigh")} (91 - 100)</li>
                          </>
                        }
                      />
                    </Flex>
                    {nivel && (
                      <Badge
                        sx={{ backgroundColor: nivel.color, color: "white" }}
                      >
                        {nivel.nombre}
                      </Badge>
                    )}
                  </Flex>
                </Flex>
              </ResultadosCard>
            </Flex>
            <Flex w={{ base: "100%", md: "50%" }}>
              <ResultadosCard>
                <Chart
                  height={270}
                  type="line"
                  series={[
                    {
                      name: "Tu puntaje",
                      data: Object.keys(indexHistory || {}).map((key) => {
                        return roundToOneDecimal(
                          indexHistory ? indexHistory[key].total_score : 0
                        );
                      }),
                    },
                    ...(regionalBenchmark &&
                    user?.hasMeasurementProcess &&
                    indexHistory
                      ? [
                          {
                            name: "Benchmark Regional",
                            data: Object.keys(indexHistory || {}).map((key) => {
                              return indexHistory[key].regional_benchmark;
                            }),
                          },
                        ]
                      : []),
                  ]}
                  options={{
                    chart: {
                      type: "line",
                      toolbar: {
                        show: false,
                      },
                    },
                    title: {
                      text: t("yourProgress"),
                      align: "center",
                      style: {
                        fontSize: "16px",
                      },
                    },
                    colors: ["#99d3d0", colorRojoClaro1],
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "smooth",
                    },
                    grid: {
                      borderColor: "#e7e7e7",
                      row: {
                        colors: ["#f3f3f3", "transparent"],
                        opacity: 0.5,
                      },
                    },
                    markers: {
                      size: 1,
                    },
                    xaxis: {
                      categories: Object.keys(indexHistory || {}).map((key) => {
                        return format(
                          new Date(
                            indexHistory
                              ? indexHistory[key].ended_at
                              : "01-01-2001"
                          ),
                          "MM/yyyy"
                        );
                      }),
                      title: {
                        text: "Fecha",
                      },
                    },
                    yaxis: {
                      title: {
                        text: "Puntaje ELSA",
                      },
                      max: indexHistory
                        ? minAndMaxAxis(indexHistory).maxGlobal
                        : 0,
                      min: indexHistory
                        ? minAndMaxAxis(indexHistory).minGlobal
                        : 0,
                      tickAmount: 5,
                    },
                    legend: {
                      show: true,
                      position: "top",
                      horizontalAlign: "right",
                      floating: true,
                      offsetY: -10,
                      offsetX: -5,
                    },
                  }}
                />
              </ResultadosCard>
            </Flex>
          </Flex>

          <Flex gap="xs">
            <ResultadosCard>
              <Flex
                justify="space-around"
                align="center"
                gap="xl"
                direction={{ base: "column", md: "row" }}
              >
                <Flex direction="column" align="center" gap="xs">
                  <Title order={5}>{t("subindexes")}</Title>
                  <Flex justify="space-around" gap="xl" align="center">
                    <Flex direction="column" align="center">
                      <Tacometro
                        value={roundToOneDecimal(
                          indexScore ? indexScore.prevention_system.score : 50
                        )}
                      />
                      <CustomLink
                        to="#"
                        onClick={() => scrollToDiv(refs.sistemaDePrevencionRef)}
                      >
                        {t("tabs.preventionSystem")}
                      </CustomLink>
                    </Flex>
                    <Flex direction="column" align="center">
                      <Tacometro
                        value={roundToOneDecimal(
                          indexScore ? indexScore.knowledge.score : 50
                        )}
                      />
                      <CustomLink
                        to="#"
                        onClick={() => scrollToDiv(refs.conocimientoRef)}
                      >
                        {t("awareness")}
                      </CustomLink>
                    </Flex>
                    <Flex direction="column" align="center">
                      <Tacometro
                        value={roundToOneDecimal(
                          indexScore ? indexScore.tolerance.score : 50
                        )}
                      />
                      <CustomLink
                        to="#"
                        onClick={() => scrollToDiv(refs.toleranciaRef)}
                      >
                        {t("tabs.tolerance")}
                      </CustomLink>
                    </Flex>
                    <Flex direction="column" align="center">
                      <Tacometro
                        value={roundToOneDecimal(
                          indexScore
                            ? indexScore.organizational_environment.score
                            : 50
                        )}
                      />
                      <CustomLink
                        to="#"
                        onClick={() => scrollToDiv(refs.climaRef)}
                      >
                        {t("tabs.orgClimate")}
                      </CustomLink>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex direction="column" align="center" gap="sm">
                  <Title order={5}>{t("bonus")}</Title>
                  <PuntajeBonusPenalidad
                    value={roundToOneDecimal(
                      indexScore ? indexScore.bonus.score : 3
                    )}
                    isBonus
                  />
                  <CustomLink to="#" onClick={() => scrollToDiv(refs.bonusRef)}>
                    {t("tabs.witnesses")}
                  </CustomLink>
                </Flex>

                <Flex direction="column" align="center" gap="sm">
                  <Title order={5}>{t("penalty")}</Title>
                  <PuntajeBonusPenalidad
                    value={roundToOneDecimal(
                      indexScore ? indexScore.penalty.score : 3
                    )}
                    isPenalty
                  />
                  <CustomLink
                    to="#"
                    onClick={() => scrollToDiv(refs.penalidadesRef)}
                  >
                    {t("myths") + " " + t("and") + " " + t("biases")}
                  </CustomLink>
                </Flex>
              </Flex>
            </ResultadosCard>
          </Flex>

          <TitleCard
            title="Detalle por Subíndices"
            description="Detalle de los puntajes obtenidos por tu organización en el Índice ELSA de acuerdo a cada uno de los cuatro subíndices."
          />

          <div ref={refs.sistemaDePrevencionRef}>
            <SubindiceCard
              title={t("tabs.preventionSystem")}
              description="Detalle de tu puntaje en el subíndice Sistema de Prevención de acuerdo a la encuesta organizacional."
              value={roundToOneDecimal(
                indexScore ? indexScore.prevention_system.score : 50
              )}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Pregunta en la encuesta</th>
                    <th>Tu respuesta</th>
                    <th>Tu puntaje</th>
                    <th>Puntaje máximo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      ¿Tu organización tiene una política de prevención del
                      acoso u hostigamiento sexual laboral?
                    </td>
                    {regularRow(
                      indexScore
                        ? indexScore.prevention_system.ps_001.pol_001
                        : defaultAnswerScore,
                      100,
                      true
                    )}
                  </tr>
                  <tr>
                    <td className={classes.tdThicker}>
                      ¿Cómo se ha distribuido la política al personal?
                    </td>
                    <td className={classes.tdThicker} />
                    <td className={classes.tdThicker}>
                      {indexScore
                        ? indexScore.prevention_system.ps_002.pol_003.score +
                          indexScore.prevention_system.ps_002.pol_004.score +
                          indexScore.prevention_system.ps_002.pol_005.score +
                          indexScore.prevention_system.ps_002.pol_006.score
                        : 0}
                    </td>
                    <td className={classes.tdThicker}>100</td>
                  </tr>
                  <tr>
                    <TdLiItalics>Se le ha entregado en físico.</TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_002.pol_003
                        : defaultAnswerScore,
                      13
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Se le ha enviado por correo electrónico.
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_002.pol_004
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Se ha colgado en la intranet o una plataforma web de la
                      organización.
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_002.pol_005
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Se ha incluido la información en el proceso de inducción
                      al personal.
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_002.pol_006
                        : defaultAnswerScore,
                      37
                    )}
                  </tr>

                  <tr>
                    <td className={classes.tdThicker}>
                      ¿Qué otros mecanismos para difundir la política se
                      realizan?
                    </td>
                    <td className={classes.tdThicker} />
                    <td className={classes.tdThicker}>
                      {indexScore
                        ? indexScore.prevention_system.ps_003.pol_007.score +
                          indexScore.prevention_system.ps_003.pol_008.score +
                          indexScore.prevention_system.ps_003.pol_009.score
                        : 0}
                    </td>
                    <td className={classes.tdThicker}>100</td>
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Se le recuerda periódicamente al personal.
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_003.pol_007
                        : defaultAnswerScore,
                      34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Se elaboran y distribuyen contenidos amigables para un
                      mejor entendimiento de la política.
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_003.pol_008
                        : defaultAnswerScore,
                      33
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Se realizan campañas de comunicación interna.
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_003.pol_009
                        : defaultAnswerScore,
                      33
                    )}
                  </tr>

                  <tr>
                    <td className={classes.tdThicker}>
                      Existe un canal de denuncias de acoso y hostigamiento
                      sexual laboral.
                    </td>
                    {regularRow(
                      indexScore
                        ? indexScore.prevention_system.ps_005.cad_004
                        : defaultAnswerScore,
                      100
                    )}
                  </tr>

                  <tr>
                    <td className={classes.tdThicker}>
                      Existe alguna instancia o comité (de intervención, ética,
                      convivencia u otro) para investigar los casos que se
                      puedan presentar sobre acoso u hostigamiento sexual en el
                      trabajo.
                    </td>
                    {regularRow(
                      indexScore
                        ? indexScore.prevention_system.ps_004.cad_001
                        : defaultAnswerScore,
                      100
                    )}
                  </tr>

                  <tr>
                    <td className={classes.tdThicker}>
                      ¿Tu organización tiene un proceso de investigación?
                    </td>
                    {regularRow(
                      indexScore
                        ? indexScore.prevention_system.ps_006.cad_014
                        : defaultAnswerScore,
                      100
                    )}
                  </tr>

                  <tr>
                    <td className={classes.tdThicker}>
                      ¿El procedimiento de investigación es apropiado?
                    </td>
                    <td className={classes.tdThicker} />
                    <td className={classes.tdThicker}>
                      {indexScore
                        ? indexScore.prevention_system.ps_007.cad_015.score +
                          indexScore.prevention_system.ps_007.cad_016.score +
                          indexScore.prevention_system.ps_007.cad_017.score +
                          indexScore.prevention_system.ps_007.cad_018.score
                        : 0}
                    </td>
                    <td className={classes.tdThicker}>100</td>
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Informa a las personas que denuncian sobre el resultado de
                      la investigación.
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_007.cad_015
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Tiene un plazo máximo de duración.
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_007.cad_016
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>Es confidencial.</TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_007.cad_017
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>Se toman medidas de protección.</TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_007.cad_018
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>

                  <tr>
                    <td className={classes.tdThicker}>
                      Porcentaje de capacitaciones al personal en los 12 últimos
                      meses
                    </td>
                    <td className={classes.tdThicker} />
                    <td className={classes.tdThicker}>
                      {indexScore
                        ? indexScore.prevention_system.ps_008.cli_001.score +
                          indexScore.prevention_system.ps_008.crh_001.score +
                          indexScore.prevention_system.ps_008.cco_001.score +
                          indexScore.prevention_system.ps_008.ctg_001.score
                        : 0}
                    </td>
                    <td className={classes.tdThicker}>100</td>
                  </tr>
                  <tr>
                    <TdLiItalics>Líderes</TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_008.cli_001
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Recursos Humanos o Gestión del Talento
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_008.crh_001
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Comité de Intervención, Ética, Convivencia, u otro similar
                    </TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_008.cco_001
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>Trabajadores/as en general</TdLiItalics>
                    {italicsRowAnswer(
                      indexScore
                        ? indexScore.prevention_system.ps_008.ctg_001
                        : defaultAnswerScore,
                      25
                    )}
                  </tr>
                </tbody>
              </Table>
            </SubindiceCard>
          </div>

          <div ref={refs.conocimientoRef}>
            <SubindiceCard
              title={t("benchmark.knowledgeOfPreventionSystem")}
              description="Detalle de tu puntaje en este subíndice de acuerdo a las respuestas en la encuesta al personal. Este subíndice evalúa el nivel de conocimiento por parte de las personas encuestadas sobre el sistema de prevención en la organización."
              value={roundToOneDecimal(
                indexScore ? indexScore.knowledge.score : 50
              )}
            >
              <Table>
                <thead>
                  <tr>
                    <th>
                      Pregunta en la encuesta
                      <br />
                      <Text weight={400}>
                        De acuerdo al porcentaje de personas que conoce el
                        sistema de prevención según la encuesta al personal
                      </Text>
                    </th>
                    <th>Tu puntaje</th>
                    <th>Puntaje máximo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Conoce y ha revisado la política de acoso sexual laboral
                    </td>
                    {italicsRowPercentage(
                      indexScore?.knowledge.k_001 || 0,
                      100
                    )}
                  </tr>
                  <tr>
                    <TdTooltip tooltipText="Para el cálculo de este puntaje se asigna 1 punto a aquellas personas que marcaron “sí, más de una vez”; y 0.5 a aquellas que marcaron “sí, una vez”.">
                      Recibió capacitación en prevención del acoso sexual
                      laboral
                    </TdTooltip>
                    {italicsRowPercentage(
                      indexScore?.knowledge.k_002 || 0,
                      100
                    )}
                  </tr>
                  <tr>
                    <td>Conoce y sabe cómo acceder al canal de denuncias</td>
                    {italicsRowPercentage(
                      indexScore?.knowledge.k_003 || 0,
                      100
                    )}
                  </tr>
                  <tr>
                    <td>Conoce el procedimiento de investigación</td>
                    {italicsRowPercentage(
                      indexScore?.knowledge.k_004 || 0,
                      100
                    )}
                  </tr>
                </tbody>
              </Table>
            </SubindiceCard>
          </div>

          <div ref={refs.toleranciaRef}>
            <SubindiceCard
              title={t("tabs.tolerance")}
              description={
                <Text color="dimmed">
                  Detalle de tu puntaje en este subíndice de acuerdo a las
                  respuestas en la encuesta al personal. Este subíndice evalúa
                  las percepciones de las personas encuestadas acerca de
                  situaciones de acoso sexual laboral.
                  <InfoTooltip
                    width={300}
                    tooltipText="A menor tolerancia por parte del personal, se obtiene un mayor puntaje en el Índice ELSA. La tolerancia del personal frente al acoso sexual laboral se obtiene a partir de la capacidad de las personas de identificar situaciones típicas como acoso laboral o acoso sexual laboral. Quienes las reconocen tienen una baja tolerancia; por el contrario, quienes no las reconocen tienen una alta tolerancia."
                  />
                </Text>
              }
              value={roundToOneDecimal(
                indexScore ? indexScore.tolerance.score : 50
              )}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Pregunta en la encuesta</th>
                    <th>Resultado en tu encuesta al personal</th>
                    <th>Tu puntaje</th>
                    <th>Puntaje máximo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Nivel de tolerancia de situaciones típicas de
                      hostigamiento o acoso sexual laboral
                      <br />
                      <Text size="xs" fs="italic">
                        De acuerdo al porcentaje de personas que reconocieron
                        estas situaciones como acoso laboral o acoso sexual
                        laboral en la encuesta al personal
                      </Text>
                    </td>
                    <td />
                    {sumRow(
                      indexScore
                        ? indexScore.tolerance.t_001.tol_001 +
                            indexScore.tolerance.t_001.tol_002 +
                            indexScore.tolerance.t_001.tol_003 +
                            indexScore.tolerance.t_001.tol_004 +
                            indexScore.tolerance.t_001.tol_005 +
                            indexScore.tolerance.t_001.tol_006 +
                            indexScore.tolerance.t_001.tol_007 +
                            indexScore.tolerance.t_001.tol_008 +
                            indexScore.tolerance.t_001.tol_009 +
                            indexScore.tolerance.t_001.tol_010 +
                            indexScore.tolerance.t_001.tol_011 +
                            indexScore.tolerance.t_001.tol_012
                        : 0,
                      100,
                      true
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Hacer comentarios o bromas de contenido sexual o sexista.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_001 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Hacer comentarios o bromas sobre la orientación sexual o
                      identidad de género de una persona.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_002 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Hacer comentarios sobre la apariencia física o cuerpo de
                      una persona que generan incomodidad, incluyendo también
                      gestos, silbidos o sonidos.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_003 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Invitar reiteradamente a salir a una persona a pesar de no
                      recibir respuesta o no recibir respuesta positiva.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_004 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Contactar reiteradamente a través de mensajes
                      electrónicos, chats, WhatsApp, Facebook, entre otros, con
                      fines no laborales que generan incomodidad.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_005 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Solicitar o enviar sin consentimiento fotos o videos de
                      material sexual explícito o de connotación sexual.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_006 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Acercarse demasiado físicamente o tocar (rozar, sobar,
                      masajear, etc.) sin necesidad a una persona en alguna
                      parte del cuerpo como hombros, espalda baja, rodilla u
                      otras partes.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_007 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Tocar o rozar a una persona en zonas privadas del cuerpo
                      como los senos, genitales, etc.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_008 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Mirar persistentemente con connotación sexual a una
                      persona.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_009 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Que un jefe/a se insinúe y/o realice invitaciones a salir
                      a pesar que de alguna manera se le mostró incomodidad.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_010 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Insinuar o proponer mejoras en la situación laboral de una
                      persona a cambio de aceptar invitaciones, acercamientos o
                      encuentros sexuales.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_011 || 0,
                      8.34
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Amenazar o insinuar con despedir a una persona, retirarle
                      los beneficios laborales o tomar alguna represalia por no
                      aceptar invitaciones, acercamientos, o encuentros
                      sexuales.
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_001.tol_012 || 0,
                      8.34
                    )}
                  </tr>

                  <tr>
                    <td className={classes.tdThicker}>
                      <>
                        Personas que comprenden que los siguientes elementos{" "}
                        <b>
                          <u>no</u>
                        </b>{" "}
                        son necesarios para que se configure el acoso sexual
                        laboral
                      </>
                    </td>
                    <td className={classes.tdThicker} />
                    {sumRow(
                      indexScore
                        ? indexScore.tolerance.t_002.cpt_001 +
                            indexScore.tolerance.t_002.cpt_002 +
                            indexScore.tolerance.t_002.cpt_003 +
                            indexScore.tolerance.t_002.cpt_004
                        : 0,
                      100
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      ¿La persona afectada debe mostrar rechazo expreso?
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_002.cpt_001 || 0,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      ¿La situación debe haber ocurrido más de una vez?
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_002.cpt_002 || 0,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      Quien realiza la conducta, ¿debe tener la intención clara
                      de acosar?
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_002.cpt_003 || 0,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiItalics>
                      ¿Debe existir una relación de jerarquía entre quien
                      realiza la conducta y quien se ve afectado/a?
                    </TdLiItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_002.cpt_004 || 0,
                      25
                    )}
                  </tr>

                  <tr>
                    <td className={classes.tdThicker}>
                      Porcentaje de personas que identifica correctamente los
                      casos de acoso sexual laboral
                      <Text size="xs" fs="italic">
                        Casos resueltos solo por personas que ocupan posiciones
                        de liderazgo, pertenecen a Recursos Humanos o al Comité
                        de Intervención contra el acoso sexual laboral, Comité
                        de Ética, Convivencia o similar.
                      </Text>
                    </td>
                    <td className={classes.tdThicker} />
                    {sumRow(
                      indexScore
                        ? indexScore.tolerance.t_003.case_001_case_resolution +
                            indexScore.tolerance.t_003
                              .case_002_case_resolution +
                            indexScore.tolerance.t_003
                              .case_003_case_resolution +
                            indexScore.tolerance.t_003.case_004_case_resolution
                        : 0,
                      100
                    )}
                  </tr>
                  <tr>
                    <TdLiTooltipItalics tooltipText="Este caso presenta comentarios y “bromas” que tienen como finalidad burlarse de la orientación sexual asumida de la persona denunciante. Estas son manifestaciones de hostigamiento o acoso sexual homofóbico por sexismo.">
                      Caso de Hostigamiento Homofóbico
                    </TdLiTooltipItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_003.case_001_case_resolution || 0,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiTooltipItalics tooltipText="Este caso presenta comentarios a viva voz sobre encuentros sexuales con colegas y otras mujeres y la sintonización de programas de entretenimiento sexualmente explícitos que generan un ambiente hostil.">
                      Caso de Ambiente Hostil
                    </TdLiTooltipItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.tolerance.t_003.case_002_case_resolution || 0,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiTooltipItalics tooltipText="Este caso presenta chantaje sexual a causa de que un jefe invita a salir, de manera reiterada, a su subordinada. El cese de la relación sexual resulta en el despido de la persona subordinada.">
                      Caso de Chantaje Sexual
                    </TdLiTooltipItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore
                        ? indexScore.tolerance.t_003.case_003_case_resolution
                        : 0,
                      25
                    )}
                  </tr>
                  <tr>
                    <TdLiTooltipItalics tooltipText="Este caso presenta reiteradas invitaciones a salir que buscan establecer una relación más allá de lo laboral y profesional a pesar del rechazo de la persona denunciante.">
                      Caso de Hostigamiento Horizontal
                    </TdLiTooltipItalics>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore
                        ? indexScore.tolerance.t_003.case_004_case_resolution
                        : 0,
                      25
                    )}
                  </tr>
                </tbody>
              </Table>
            </SubindiceCard>
          </div>

          <div ref={refs.climaRef}>
            <SubindiceCard
              title={t("tabs.orgClimate")}
              description="Detalle de tu puntaje en este subíndice de acuerdo a las respuestas en la encuesta al personal. Este subíndice evalúa la percepción de las personas en relación al manejo de los casos en la organización."
              value={roundToOneDecimal(
                indexScore ? indexScore.organizational_environment.score : 50
              )}
            >
              <Table>
                <thead>
                  <tr>
                    <th>
                      Pregunta en la encuesta
                      <Text fw={400}>
                        Según el porcentaje de personas personas que está muy de
                        acuerdo o de acuerdo con las siguientes afirmaciones:
                      </Text>
                    </th>
                    <th>Tu puntaje</th>
                    <th>Puntaje máximo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Si denuncio un caso de hostigamiento o acoso sexual
                      laboral, me podría generar consecuencias en mi trabajo.
                    </td>
                    {sumRow(
                      indexScore?.organizational_environment.c_001 || 0,
                      100,
                      true
                    )}
                  </tr>
                  <tr>
                    <td>
                      Un caso de hostigamiento o acoso sexual en el que se acusa
                      a una persona que no pertenece a la plana gerencial no
                      sería investigado adecuadamente en mi trabajo.
                    </td>
                    {sumRow(
                      indexScore?.organizational_environment.c_002 || 0,
                      100,
                      true
                    )}
                  </tr>
                  <tr>
                    <td>
                      Un caso de hostigamiento o acoso sexual en el que se acusa
                      a una persona que pertenece a la plana gerencial no sería
                      investigado adecuadamente en mi trabajo.
                    </td>
                    {sumRow(
                      indexScore?.organizational_environment.c_003 || 0,
                      100,
                      true
                    )}
                  </tr>
                  <tr>
                    <td>
                      El hostigamiento o acoso sexual no es tolerado en mi
                      ambiente laboral.
                    </td>
                    {sumRow(
                      indexScore?.organizational_environment.c_004 || 0,
                      100,
                      true
                    )}
                  </tr>
                  <tr>
                    <td>
                      Las personas que hostigan o acosan a otras/os normalmente
                      se salen con la suya (por ejemplo, no son sancionadas).
                    </td>
                    {sumRow(
                      indexScore?.organizational_environment.c_005 || 0,
                      100,
                      true
                    )}
                  </tr>
                  <tr>
                    <td>
                      Se están tomando acciones efectivas contra el
                      hostigamiento o acoso sexual en mi trabajo.
                    </td>
                    {sumRow(
                      indexScore?.organizational_environment.c_006 || 0,
                      100,
                      true
                    )}
                  </tr>
                </tbody>
              </Table>
            </SubindiceCard>
          </div>

          <div ref={refs.bonusRef}>
            <SubindiceCard
              title="Bonus"
              description={
                <Text color="dimmed">
                  Detalle de los puntos adicionales recibidos por tu
                  organización. Estos puntos se otorgan de acuerdo a las
                  acciones adoptadas por las personas que reconocieron haber
                  sido testigos.
                  <InfoTooltip tooltipText="¿Por qué se otorgan puntos adicionales? Se busca promover una participación activa de las terceras personas en situaciones de acoso sexual laboral." />
                </Text>
              }
              isTitle
              value={roundToOneDecimal(
                indexScore ? indexScore.bonus.score : 50
              )}
              isBonus
            >
              <Table>
                <thead>
                  <tr>
                    <th>
                      Pregunta en la encuesta
                      <br />
                      <Text weight={400}>
                        De acuerdo a las respuestas de las personas que
                        reconocieron haber sido testigos de una situación de
                        acoso sexual laboral en la encuesta al personal
                      </Text>
                    </th>
                    <th>Resultado en tu encuesta al personal</th>
                    <th>Tu bonus</th>
                    <th>Bonus máximo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      ¿Qué hiciste cuando fuiste testigo de acoso sexual laboral
                      en tu organización?
                    </td>
                    <td />
                    {sumRow(
                      roundToTwoDecimals(indexScore?.bonus.score || 0),
                      3,
                      true
                    )}
                  </tr>
                  <tr>
                    <td>
                      <Italics>
                        <li>
                          Generé una distracción (por ejemplo, cambié el tema de
                          conversación).
                        </li>
                      </Italics>
                    </td>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.bonus.w_001.act_001 || 0,
                      1
                    )}
                  </tr>
                  <tr>
                    <td>
                      <Italics>
                        <li>
                          Confronté a la persona que estaba haciendo estas
                          conductas.
                        </li>
                      </Italics>
                    </td>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.bonus.w_001.act_002 || 0,
                      1
                    )}
                  </tr>
                  <tr>
                    <td>
                      <Italics>
                        <li>
                          Informé de lo sucedido a recursos humanos o a mi
                          jefe/a.
                        </li>
                      </Italics>
                    </td>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.bonus.w_001.act_003 || 0,
                      1
                    )}
                  </tr>
                  <tr>
                    <td>
                      <Italics>
                        <li>
                          En ese momento no intervine pero luego me acerqué a la
                          persona para ver cómo estaba.
                        </li>
                      </Italics>
                    </td>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.bonus.w_001.act_004 || 0,
                      0.25
                    )}
                  </tr>
                  <tr>
                    <td>
                      <Italics>
                        <li>
                          En ese momento no intervine pero luego me acerqué a
                          quien incurrió en acoso u hostigamiento.
                        </li>
                      </Italics>
                    </td>
                    {italicsRowPercentageBasedOffMaxScore(
                      indexScore?.bonus.w_001.act_005 || 0,
                      0.25
                    )}
                  </tr>
                </tbody>
              </Table>
            </SubindiceCard>
          </div>

          <div ref={refs.penalidadesRef}>
            <SubindiceCard
              title="Penalidades"
              description={
                <Text color="dimmed">
                  Detalle de los puntos en contra recibidos por tu organización.
                  Estos puntos se descuentan por la presencia de mitos y sesgos
                  inconscientes presentados en la resolución de casos por parte
                  de personal clave: personas en posiciones de liderazgo,
                  Recursos Humanos, Comité de Intervención, Ética, Convivencia o
                  similar.
                  <InfoTooltip tooltipText="¿Por qué se incluyen los puntos en contra? Se busca desincentivar la presencia de estos mitos y sesgos inconscientes mediante un trabajo focalizado. Al tratarse de las personas encargadas de derivar, acompañar y/o investigar y resolver casos, es importante que se disminuyan dichos mitos y sesgos." />
                </Text>
              }
              isTitle
              value={roundToOneDecimal(
                indexScore ? indexScore.penalty.score : 0
              )}
              isPenalty
            >
              {penaltyTable(
                "Penalidad 1: Por presencia de mitos sobre el acoso sexual laboral",
                "Penalidad aplicada por la presencia revelada de mitos alrededor del acoso sexual laboral. La identificación se calcula a través de las personas que respondieron estar de acuerdo o muy de acuerdo con estos mitos en la encuesta al personal.",
                [
                  indexScore?.penalty.p_001.case_001 || defaultCase,
                  indexScore?.penalty.p_001.case_002 || defaultCase,
                  indexScore?.penalty.p_001.case_003 || defaultCase,
                  indexScore?.penalty.p_001.case_004 || defaultCase,
                ],
                roundToTwoDecimals(
                  indexScore
                    ? (indexScore.penalty.p_001.case_001.case_penalty_score +
                        indexScore.penalty.p_001.case_002.case_penalty_score +
                        indexScore.penalty.p_001.case_003.case_penalty_score +
                        indexScore.penalty.p_001.case_004.case_penalty_score) /
                        4
                    : 0
                )
              )}
              <Space h="md" />
              {penaltyTable(
                "Penalidad 2: Por activación de sesgos inconscientes sobre el acoso sexual laboral",
                "Penalidad aplicada por la activación de sesgos inconscientes. La identificación de estos sesgos se calcula a través de un diseño experimental que presentó, de forma aleatoria, casos con y sin activador de sesgos inconscientes en la encuesta al personal.",
                [
                  indexScore?.penalty.p_002.case_001 || defaultCase,
                  indexScore?.penalty.p_002.case_002 || defaultCase,
                  indexScore?.penalty.p_002.case_003 || defaultCase,
                  indexScore?.penalty.p_002.case_004 || defaultCase,
                ],
                roundToTwoDecimals(
                  indexScore
                    ? (indexScore.penalty.p_002.case_001.case_penalty_score +
                        indexScore.penalty.p_002.case_002.case_penalty_score +
                        indexScore.penalty.p_002.case_003.case_penalty_score +
                        indexScore.penalty.p_002.case_004.case_penalty_score) /
                        4
                    : 0
                )
              )}
            </SubindiceCard>
          </div>
        </Flex>
        {overlay && <Overlay color="#f8f9fa" opacity={0.05} blur={4} />}
      </div>
    </div>
  );
};
