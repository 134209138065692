import { useEffect, useState } from "react";
import { createStyles, Grid, List, Paper, Text } from "@mantine/core";
import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  NoTienesMedicionesComponent,
} from "../../components";
import { clientService } from "../../api";
import { useAuth } from "../../context";
import { TMedicion } from "../../types";
import { GenderLabLoader } from "../../components";
import { useTranslation } from "react-i18next";
import { MedicionesPageCards } from "./_medicionesPageCards";

export const useStyles = createStyles((_theme, _params, getRef) => ({
  root: {
    width: "90%",
  },

  controls: {
    cursor: "pointer",
    backgroundColor: _theme.colors.gray[2],
    color: _theme.colors.gray[6],
    "&:hover": {
      opacity: 0.8,
    },
  },

  carrouselButton: {
    border: "none",
    backgroundColor: "transparent",
  },
}));

export const MedicionesPage = () => {
  const { t } = useTranslation();
  const { tryingToPersistSession, setLoading, authRequest, user } = useAuth();
  const [mediciones, setMediciones] = useState<TMedicion[]>(null!);

  useEffect(() => {
    if (!tryingToPersistSession && !mediciones) {
      setLoading(true);
      authRequest(clientService.measurements.get).then((response) => {
        const data = response.data;
        setMediciones(data);
        setLoading(false);
      });
    }
  }, [tryingToPersistSession, authRequest, mediciones, setLoading]);

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs breadcrumbs={[{ link: "", title: t('appShell.measurements') }]} />
      {user ? (
        <>
          {user.hasMeasurementProcess || user.hasExpiredMeasurementProcess ? (
            <Grid>
              <Grid.Col span={12}>
                <Paper radius="md" withBorder p="md">
                  <Text color="dimmed">
                    {t('measurements.description')}
                  </Text>
                  <List type="ordered" mt="md">
                    <Text color="dimmed">
                      <List.Item>
                        {t('measurements.stepOne')}
                      </List.Item>
                    </Text>
                    <Text color="dimmed">
                      <List.Item>
                        {t('measurements.stepTwo')}
                      </List.Item>
                    </Text>
                    <Text color="dimmed">
                      <List.Item>
                        {t('measurements.stepThree')}
                      </List.Item>
                    </Text>
                  </List>
                </Paper>
              </Grid.Col>
              <Grid.Col span={12}>
                <MedicionesPageCards mediciones={mediciones} />
              </Grid.Col>
            </Grid>
          ) : (
            <NoTienesMedicionesComponent />
          )}
        </>
      ) : (
        <GenderLabLoader />
      )}
    </GenderLabAppShell>
  );
};
