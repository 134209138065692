import { useEffect, useState } from "react";
import {
  AccesoRestringidoCard,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
} from "../../components";
import { useAuth } from "../../context";
import { Flex, Overlay } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const RedElsa = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const iframe = document.getElementById("red-elsa");

    const handleLoad = () => {
      setIsLoading(false);
    };

    iframe?.addEventListener("load", handleLoad);

    return () => {
      iframe?.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <GenderLabAppShell fullScreen>
      {(isLoading || !user) && <GenderLabLoader />}

      {user && !user.hasELSANetwork ? (
        <>
          <GenderLabBreadcrumbs
            breadcrumbs={[{ link: "", title: t("appShell.elsaNetwork") }]}
          />
          <Flex direction="column" gap="lg">
            <AccesoRestringidoCard name={t("appShell.elsaNetwork")} />
            <div
              style={{ position: "relative", width: "100%", height: "600px" }}
            >
              <iframe
                id="red-elsa"
                title="red-elsa"
                src="https://www.notion.elsa.la/Red-ELSA-a91cc2a496024fdf9692f8dce31f1f25?pvs=4"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  visibility: isLoading ? "hidden" : "visible",
                }}
              />
              <Overlay color="#f8f9fa" opacity={0.05} blur={2} />
            </div>
          </Flex>
        </>
      ) : (
        <div style={{ position: "relative" }}>
          <iframe
            id="red-elsa"
            title="red-elsa"
            src="https://www.notion.elsa.la/Red-ELSA-a91cc2a496024fdf9692f8dce31f1f25?pvs=4"
            style={{
              width: "100%",
              height: "90vh",
              border: "none",
              visibility: isLoading ? "hidden" : "visible",
            }}
          />
        </div>
      )}
    </GenderLabAppShell>
  );
};
