import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const PageTitleUpdater = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/medicion/")) {
      document.title = `ELSA - ${t("measurement")}`;
    } else if (path.startsWith("/tus-resultados/")) {
      document.title = `ELSA - ${t("appShell.yourResults")}`;
    } else if (path.startsWith("/plan-de-accion/")) {
      document.title = `ELSA - ${t("appShell.actionPlan")}`;
    } else {
      switch (path) {
        case "/inicio":
          document.title = `ELSA - ${t("appShell.home")}`;
          break;
        case "/mediciones":
          document.title = `ELSA - ${t("appShell.measurements")}`;
          break;
        case "/resultados":
          document.title = `ELSA - ${t("appShell.results")}`;
          break;
        case "/red-elsa":
          document.title = `ELSA - ${t("appShell.elsaNetwork")}`;
          break;
        case "/plan-de-accion":
          document.title = `ELSA - ${t("appShell.actionPlan")}`;
          break;
        case "/clases-en-vivo":
          document.title = `ELSA - ${t("appShell.liveClasses")}`;
          break;
        case "/aula-virtual":
          document.title = `ELSA - ${t("appShell.virtualClassroom")}`;
          break;
        case "/mi-cuenta":
          document.title = "ELSA - Cuenta";
          break;
        case "/benchmark":
          document.title = "ELSA - Benchmark";
          break;
        default:
          document.title = "ELSA App";
      }
    }
  }, [location]);

  return null;
};
