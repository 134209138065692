import { Flex, Grid } from "@mantine/core";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkWithResponsiveStyleProps } from "./GeneralBenchmarkProps";
import { colorAzul1 } from "../../utils";
import { BarChart } from "../Charts";
import { useTranslation } from "react-i18next";

export const BenchmarkPrevalenciaPerfilPersonal = ({
    generalBenchmark,
    responsiveStyle,
}: GeneralBenchmarkWithResponsiveStyleProps) => {
    const { t } = useTranslation();
    return (
        <ResultadosCard
            title={t("benchmark.prevalenceByProfileTitle")}
            description={t("benchmark.prevalenceByProfileDescription")}
        >
            <Grid>
                <Grid.Col span={12} md={6}>
                    <BarChart
                        height={300}
                        width={450}
                        responsive={responsiveStyle}
                        title={t("benchmark.prevalenceByGender")}
                        categories={[
                            t("total"),
                            t("male"),
                            t("female"),
                            t("nonBinary"),
                        ]}
                        seriesData={[
                            [
                                generalBenchmark.benchmarks.RB_0040,
                                generalBenchmark.benchmarks.RB_0204,
                                generalBenchmark.benchmarks.RB_0205,
                                generalBenchmark.benchmarks.RB_0206,
                            ],
                        ]}
                        seriesNames={[
                            t("tabs.prevalence") + " (%)",
                        ]}
                        colors={[colorAzul1]}
                    />
                </Grid.Col>
                <Grid.Col span={12} md={6}>
                    <BarChart
                        height={300}
                        width={450}
                        responsive={responsiveStyle}
                        title={t("benchmark.prevalenceByLGBT")}
                        categories={[
                            t("total"),
                            t("lgbtCommunity"),
                            t("not") + " " + t("lgbtCommunity"),
                            t("preferNotToAnswer")
                        ]}
                        seriesData={[
                            [
                                generalBenchmark.benchmarks.RB_0040,
                                generalBenchmark.benchmarks.RB_0207,
                                generalBenchmark.benchmarks.RB_0208,
                                generalBenchmark.benchmarks.RB_0209,
                            ],
                        ]}
                        seriesNames={
                            [t("tabs.prevalence") + " (%)"]
                        }
                        colors={[colorAzul1]}
                    />
                </Grid.Col>
                <Grid.Col span={12} md={6}>
                    <BarChart
                        height={300}
                        width={450}
                        responsive={responsiveStyle}
                        title={t("benchmark.prevalenceByAge")}
                        categories={[
                            t("total"),
                            "18 - 29 " + t("years"),
                            "30 - 49 " + t("years"),
                            "50 - 59 " + t("years"),
                            "60 " + t("years") + " " + t("andOlder"),
                            t("preferNotToAnswer"),
                        ]}
                        seriesData={[
                            [
                                generalBenchmark.benchmarks.RB_0040,
                                generalBenchmark.benchmarks.RB_0210,
                                generalBenchmark.benchmarks.RB_0211,
                                generalBenchmark.benchmarks.RB_0212,
                                generalBenchmark.benchmarks.RB_0213,
                                generalBenchmark.benchmarks.RB_0214,
                            ],
                        ]}
                        seriesNames={
                            [t("tabs.prevalence") + " (%)"]
                        }
                        colors={[colorAzul1]}
                    />
                </Grid.Col>
                <Grid.Col span={12} md={6}>
                    <BarChart
                        height={300}
                        width={450}
                        responsive={responsiveStyle}
                        title={t("benchmark.prevalenceByEthnic")}
                        categories={[
                            t("total"),
                            t("ethnic.asian"),
                            t("ethnic.mestizo"),
                            t("ethnic.indigenous"),
                            t("ethnic.afrodescendant"),
                            t("ethnic.white"),
                            t("other"),
                            t("preferNotToAnswer"),
                        ]}
                        seriesData={[
                            [
                                generalBenchmark.benchmarks.RB_0040,
                                generalBenchmark.benchmarks.RB_0215,
                                generalBenchmark.benchmarks.RB_0216,
                                generalBenchmark.benchmarks.RB_0217,
                                generalBenchmark.benchmarks.RB_0218,
                                generalBenchmark.benchmarks.RB_0219,
                                generalBenchmark.benchmarks.RB_0220,
                                generalBenchmark.benchmarks.RB_0221,
                            ],
                        ]}
                        seriesNames={[
                            t("tabs.prevalence") + " (%)",
                        ]}
                        colors={[colorAzul1]}
                    />
                </Grid.Col>
            </Grid>
            <Flex
                direction={{ base: "column", xl: "row" }}
                gap="sm"
                align="center"
                justify="space-evenly"
            ></Flex>
        </ResultadosCard>
    );
}
