import {
  Alert,
  Button,
  Card,
  Flex,
  RingProgress,
  Select,
  Stack,
  Text,
  createStyles,
  Title,
} from "@mantine/core";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

import {
  AccesoRestringidoCard,
  AgendaUnaDemoButton,
  ExplicacionPlanDeAccion,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
  PlanDeAccionBlurred,
  PlanDeAccionCard,
} from "../../components";
import { TActionPlan, TActionPlanMeasurement } from "../../types";
import { clientService } from "../../api";
import { parsePercentage } from "../../utils";
import { useAuth } from "../../context";
import { BsDownload } from "react-icons/bs";
import { IconAlertCircle } from "@tabler/icons";
import { useTranslation } from "react-i18next";

const useStyles = createStyles(() => ({
  hideOnSmall: {
    "@media (max-width: 1024px)": {
      display: "none",
    },
  },
}));

export const PlanDeAccionPage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { user } = useAuth();
  const { code } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [mps, setMps] = useState<TActionPlanMeasurement[] | null>(null);
  const currentMp = mps?.find((mp) => mp.code === code);
  const [actionPlanDetails, setActionPlanDetails] =
    useState<TActionPlan | null>(null);

  const getActionPlanDetails = async (code: string) => {
    const response = await clientService.actionPlan.planDetails.get(code);
    setActionPlanDetails(response.data);
  };

  const getMps = async () => {
    try {
      const { data } = await clientService.actionPlan.plans.get();
      setMps(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) getMps();
  }, [user, code]);

  useEffect(() => {
    if (!code && mps && mps.length > 0) {
      navigate(`/plan-de-accion/${mps[mps.length - 1].code}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mps]);

  useEffect(() => {
    if (code) {
      if (currentMp?.status !== "unavailable") getActionPlanDetails(code);
    }
  }, [currentMp, code]);

  const handleSelectChange = async () => await getActionPlanDetails(code || "");

  const breadcrumbs = () => (
    <GenderLabBreadcrumbs
      breadcrumbs={[{ link: "", title: t("appShell.actionPlan") }]}
    />
  );

  const generateActionPlan = async () => {
    try {
      setLoading(true);
      if (code) {
        await clientService.actionPlan.plans.post(code);
        await getMps();
      }
    } catch {
      console.error("Error al generar el plan de acción");
    } finally {
      setLoading(false);
    }
  };

  const downloadPlanExcel = async () => {
    try {
      if (code) {
        const csvResponse = await clientService.actionPlan.getExcel(code);
        const url = window.URL.createObjectURL(new Blob([csvResponse.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Plan de acción - ${user ? user.tradeName : ""}${
            actionPlanDetails ? " (" + actionPlanDetails.created_at + ")" : ""
          }.xlsx`
        );
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    } catch {
      console.error("Error al descargar el plan de acción");
    }
  };

  const loader = () => (
    <GenderLabAppShell>
      {breadcrumbs()}
      <GenderLabLoader />
    </GenderLabAppShell>
  );

  if (!user) {
    return loader();
  }

  if (!user.hasActionPlan) {
    return (
      <GenderLabAppShell>
        {breadcrumbs()}
        <AccesoRestringidoCard name="tu plan de acción" />
        <AgendaUnaDemoButton />
        <PlanDeAccionBlurred />
      </GenderLabAppShell>
    );
  }

  if (mps === null) {
    return loader();
  } else {
    if (mps.length === 0) {
      return (
        <GenderLabAppShell>
          {breadcrumbs()}
          <Text color="dimmed" fz="lg">
          {t("appShell.soon")} tu plan de acción ELSA.
          </Text>
        </GenderLabAppShell>
      );
    } else {
      if (code && currentMp) {
        return (
          <GenderLabAppShell>
            {breadcrumbs()}
            <Flex gap="sm" pb="md" align="flex-end">
              <Select
                maw={200}
                label={t("measurement")}
                value={code}
                onChange={(value: string) =>
                  navigate(`/plan-de-accion/${value}`)
                }
                data={mps.map((mp) => ({
                  value: mp.code,
                  label: mp.title,
                }))}
              />
              {currentMp.status === "generated" && (
                <Button color="blue" size="xs" onClick={downloadPlanExcel}>
                  <Flex gap="xs" align="center">
                    <BsDownload /> {t("actions.download")} {t("plan")}
                  </Flex>
                </Button>
              )}
            </Flex>

            {currentMp.status === "available" && (
              <>
                <ExplicacionPlanDeAccion />
                <Flex justify="end" my="sm">
                  <Button disabled={loading} onClick={generateActionPlan}>
                    Generar plan de acción
                  </Button>
                </Flex>
              </>
            )}

            {currentMp.status === "generated" && (
              <>
                {actionPlanDetails ? (
                  <Stack spacing="md">
                    <Text align="justify">
                      {t("actionPlan.description")}
                    </Text>
                    <Card withBorder>
                      <Title order={5}>{t("yourProgress")}</Title>
                      <Flex justify="space-around" align="center">
                        <RingProgress
                          size={150}
                          label={
                            <Stack align="center">
                              <Text align="center" fw={600} fz="lg">
                                {parsePercentage(
                                  actionPlanDetails.progress.Completed
                                )}
                                %
                              </Text>
                              <Text color="gray" mt={-15}>
                                {t("status.completed")}
                              </Text>
                            </Stack>
                          }
                          sections={[
                            {
                              value: parsePercentage(
                                actionPlanDetails.progress.Completed
                              ),
                              color: "green",
                            },
                            {
                              value: parsePercentage(
                                actionPlanDetails.progress["In progress"]
                              ),
                              color: "yellow",
                            },
                            {
                              value: parsePercentage(
                                actionPlanDetails.progress["To do"]
                              ),
                              color: "gray",
                            },
                          ]}
                        />
                        <Flex gap={4} className={classes.hideOnSmall}>
                          <Text color="gray">
                            {parsePercentage(
                              actionPlanDetails.progress["To do"]
                            )}
                            %
                          </Text>
                          <Text fw={500}>{t("status.pending")}</Text>
                        </Flex>
                        <Flex gap={4} className={classes.hideOnSmall}>
                          <Text color="yellow">
                            {parsePercentage(
                              actionPlanDetails.progress["In progress"]
                            )}
                            %
                          </Text>
                          <Text fw={500}>{t("status.inProgress")}</Text>
                        </Flex>
                        <Flex gap={4} className={classes.hideOnSmall}>
                          <Text color="green">
                            {parsePercentage(
                              actionPlanDetails.progress.Completed
                            )}
                            %
                          </Text>
                          <Text fw={500}>{t("status.completed")}</Text>
                        </Flex>
                        <Stack spacing="xs" align="center">
                          <Text fw={500} align="center">
                            {t("estimatedCompletionDate")}
                          </Text>
                          <Text color="gray">
                            {format(
                              actionPlanDetails.estimated_end_date,
                              "dd/MM/yyyy"
                            )}
                          </Text>
                        </Stack>
                      </Flex>
                    </Card>

                    <PlanDeAccionCard
                      image="/img/pda/sistema-de-prevencion.png"
                      title={t("tabs.preventionSystem")}
                      completedRecommendations={
                        actionPlanDetails.prevention_system.completed
                      }
                      totalRecommendations={
                        actionPlanDetails.prevention_system.total
                      }
                      recommendations={
                        actionPlanDetails.prevention_system.recommendations
                      }
                      onSelectChange={handleSelectChange}
                      code={code}
                    >
                      Estas recomendaciones buscan fortalecer la efectividad de
                      tu sistema de prevención mejorando cada uno de sus 4
                      elementos: política de acoso sexual laboral, canal de
                      denuncia, capacitaciones y procedimiento de investigación.
                    </PlanDeAccionCard>

                    <PlanDeAccionCard
                      image="/img/pda/conocimiento.png"
                      title={t("awareness")}
                      completedRecommendations={
                        actionPlanDetails.knowledge.completed
                      }
                      totalRecommendations={actionPlanDetails.knowledge.total}
                      recommendations={
                        actionPlanDetails.knowledge.recommendations
                      }
                      onSelectChange={handleSelectChange}
                      code={code}
                    >
                      Estas recomendaciones buscan promover un mayor
                      conocimiento del sistema de prevención de tu organización
                      por parte de tu personal. Un sistema de prevención
                      eficiente es aquel que se difunde y es conocido.
                    </PlanDeAccionCard>

                    <PlanDeAccionCard
                      image="/img/pda/tolerancia.png"
                      title={t("tabs.tolerance")}
                      completedRecommendations={
                        actionPlanDetails.tolerance.completed
                      }
                      totalRecommendations={actionPlanDetails.tolerance.total}
                      recommendations={
                        actionPlanDetails.tolerance.recommendations
                      }
                      onSelectChange={handleSelectChange}
                      code={code}
                    >
                      Estas recomendaciones tienen como objetivo abordar los
                      comportamientos de acoso sexual laboral más normalizados
                      en tu organización y a generar consciencia sobre su
                      impacto en las personas.
                    </PlanDeAccionCard>

                    <PlanDeAccionCard
                      image="/img/pda/clima-organizacional.png"
                      title={t("tabs.orgClimate")}
                      completedRecommendations={
                        actionPlanDetails.organizational_environment.completed
                      }
                      totalRecommendations={
                        actionPlanDetails.organizational_environment.total
                      }
                      recommendations={
                        actionPlanDetails.organizational_environment
                          .recommendations
                      }
                      onSelectChange={handleSelectChange}
                      code={code}
                    >
                      Estas recomendaciones buscan afianzar las percepciones
                      positivas de tu personal en relación a las acciones que
                      toma la organización para la prevención del acoso sexual
                      laboral.
                    </PlanDeAccionCard>

                    <PlanDeAccionCard
                      image="/img/pda/prevalencia.png"
                      title={t("tabs.prevalence")}
                      completedRecommendations={
                        actionPlanDetails.prevalence.completed
                      }
                      totalRecommendations={actionPlanDetails.prevalence.total}
                      recommendations={
                        actionPlanDetails.prevalence.recommendations
                      }
                      onSelectChange={handleSelectChange}
                      code={code}
                    >
                      Estas recomendaciones, de la mano con las anteriores,
                      buscan disminuir la situaciones de acoso sexual laboral
                      que ocurren en la organización, disminuir las barreras
                      para denunciar, generar mayor confianza en la denuncia,
                      etc.
                    </PlanDeAccionCard>

                    <PlanDeAccionCard
                      image="/img/pda/testigos.png"
                      title={t("tabs.witnesses")}
                      completedRecommendations={
                        actionPlanDetails.witness.completed
                      }
                      totalRecommendations={actionPlanDetails.witness.total}
                      recommendations={
                        actionPlanDetails.witness.recommendations
                      }
                      onSelectChange={handleSelectChange}
                      code={code}
                    >
                      Estas recomendaciones están basadas en el bystander
                      approach y tienen como objetivo reforzar el rol de las
                      terceras personas en los casos de acoso sexual laboral,
                      promoviendo su intervención activa.
                    </PlanDeAccionCard>
                  </Stack>
                ) : (
                  <GenderLabLoader />
                )}
              </>
            )}

            {currentMp.status === "unavailable" && (
              <Alert
                icon={<IconAlertCircle size={16} />}
                title="No disponible"
                color="yellow"
              >
                Ha pasado demasiado tiempo desde que se completó la medición,
                por lo que ya no es posible generar un plan de acción basado en
                esos datos. Te recomendamos realizar mediciones anuales para
                mantener tus datos actualizados y poder crear planes de acción
                efectivos en el futuro. No olvides que puedes realizar tus
                mediciones anuales utilizando Elsa para obtener resultados
                precisos y oportunos.
              </Alert>
            )}
          </GenderLabAppShell>
        );
      } else {
        return loader();
      }
    }
  }
};
