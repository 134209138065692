import { Card, Text, Title } from "@mantine/core";

type Props = {
  name: string;
};

export const AccesoRestringidoCard = ({ name }: Props) => (
  <Card shadow="sm" p="sm" radius="lg" withBorder>
    <Title order={4}>No tienes acceso🥺</Title>
    <Text color="dimmed">
      Por el momento, no cuentas con una suscripción activa que te brinde acceso
      a {name}. Recuerda que puedes adquirir una suscripción o renovar la que ya
      tienes poniéndote en contacto con el equipo ELSA.
    </Text>
  </Card>
);
