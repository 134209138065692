import React, { useState } from "react";
import {
  UnstyledButton,
  Group,
  Text,
  useMantineTheme,
  Flex,
  createStyles,
  Container,
} from "@mantine/core";
import { useNavigate } from "react-router";
import { RiHome2Line, RiSurveyLine, RiFlowChart } from "react-icons/ri";
import { TbSchool } from "react-icons/tb";
import { AiOutlineBarChart } from "react-icons/ai";
import { BsGlobe } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import { useTranslation } from "react-i18next";

interface MainLinkProps {
  icon: React.ReactNode;
  label: string;
  route: string;
  customLabel?: string;
}

const useStyles = createStyles((theme) => ({
  link: {
    display: "block",
    width: "100%",
    padding: theme.spacing.sm,
    borderRadius: theme.radius.sm,
    color: theme.black,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  dropdownChild: {
    display: "block",
    width: "100%",
    padding: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    color: theme.black,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
    marginLeft: "3px",
  },
}));

const MainLink = ({ icon, label, route, customLabel }: MainLinkProps) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <UnstyledButton onClick={() => navigate(route)} className={classes.link}>
      <Group position="apart">
        <Flex align="center" gap={8}>
          {icon}
          <Text size="md" fw={400}>
            {label}
          </Text>
        </Flex>

        {customLabel && (
          <Text size="xs" color="red" fw={400}>
            {customLabel}
          </Text>
        )}
      </Group>
    </UnstyledButton>
  );
};

const MainLinkDropdownChild = ({
  label,
  route,
}: {
  label: string;
  route: string;
}) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <UnstyledButton
      onClick={() => navigate(route)}
      className={classes.dropdownChild}
    >
      <Group>
        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  );
};

type MainLinkDropDownProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
  label: string;
};

const MainLinkDropDown = ({ children, icon, label }: MainLinkDropDownProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <>
      <UnstyledButton
        onClick={toggleDropdown}
        className={classes.link}
        aria-expanded={isOpen}
      >
        <Group position="apart">
          <Flex align="center" gap={8}>
            {icon}
            <Text size="md">{label}</Text>
          </Flex>
          <FiChevronDown
            size={16}
            style={{ transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)" }}
          />
        </Group>
      </UnstyledButton>
      {isOpen && (
        <Container
          style={{
            borderLeft: `1px solid ${theme.colors["genderlab-red"][5]}`,
            marginLeft: "22px",
          }}
        >
          {children}
        </Container>
      )}
    </>
  );
};

export const MainLinks = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const size = 20;
  const color = theme.colors["genderlab-red"][5];

  return (
    <>
      <MainLink
        icon={<RiHome2Line size={size} color={color} />}
        label={t('appShell.home')}
        route="/inicio"
      />
      <MainLink
        icon={<RiSurveyLine size={size} color={color} />}
        label={t('appShell.measurements')}
        route="/mediciones"
      />
      <MainLinkDropDown
        label={t('appShell.results')}
        icon={<AiOutlineBarChart size={size} color={color} />}
      >
        <MainLinkDropdownChild label={t('appShell.benchmark')} route="/benchmark" />
        <MainLinkDropdownChild label={t('appShell.yourResults')} route="/tus-resultados" />
      </MainLinkDropDown>
      <MainLink
        icon={<BsGlobe size={size} color={color} />}
        label={t('appShell.elsaNetwork')}
        route="/red-elsa"
      />
      <MainLink
        icon={<RiFlowChart size={size} color={color} />}
        label={t('appShell.actionPlan')}
        route="/plan-de-accion"
        customLabel={"(Beta)"}
      />
      <MainLinkDropDown
        label={t('appShell.education')}
        icon={<TbSchool size={size} color={color} />}
      >
        <MainLinkDropdownChild label={t('appShell.virtualClassroom')} route="/aula-virtual" />
        <MainLinkDropdownChild label={t('appShell.liveClasses')} route="/clases-en-vivo" />
      </MainLinkDropDown>
    </>
  );
};
