import { useTranslation } from "react-i18next";
import { useAuth, useMetrics } from "../../context";
import { TToleranceCategory, TToleranceOptions } from "../../types";
import { parsePercentage } from "../../utils";
import { Heatmap } from "../Charts";

type Props = {
  data: { [key: string]: TToleranceCategory };
  total: TToleranceOptions;
};

export const ToleranciaHeatmap = ({ data, total }: Props) => {
  const { t } = useTranslation();
  const { regionalBenchmark, countryBenchmark, previousMetrics } = useMetrics();
  const { user } = useAuth();

  return (
    <Heatmap
      categories={[
        "T1",
        "T2",
        "T3",
        "T4",
        "T5",
        "T6",
        "T7",
        "T8",
        "T9",
        "T10",
        "T11",
        "T12",
      ]}
      data={[
        ...(user?.hasRegionalBenchmark && regionalBenchmark
          ? [
              {
                name: t("benchmark.regional"),
                data: [
                  parsePercentage(regionalBenchmark.benchmarks.RB_0009),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0010),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0011),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0012),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0013),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0014),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0015),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0016),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0017),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0018),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0019),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0020),
                ],
              },
            ]
          : []),
        ...(user?.hasCountryBenchmark && countryBenchmark
          ? [
              {
                name: t("benchmark.country"),
                data: [
                  parsePercentage(countryBenchmark.benchmarks.CB_0009),
                  parsePercentage(countryBenchmark.benchmarks.CB_0010),
                  parsePercentage(countryBenchmark.benchmarks.CB_0011),
                  parsePercentage(countryBenchmark.benchmarks.CB_0012),
                  parsePercentage(countryBenchmark.benchmarks.CB_0013),
                  parsePercentage(countryBenchmark.benchmarks.CB_0014),
                  parsePercentage(countryBenchmark.benchmarks.CB_0015),
                  parsePercentage(countryBenchmark.benchmarks.CB_0016),
                  parsePercentage(countryBenchmark.benchmarks.CB_0017),
                  parsePercentage(countryBenchmark.benchmarks.CB_0018),
                  parsePercentage(countryBenchmark.benchmarks.CB_0019),
                  parsePercentage(countryBenchmark.benchmarks.CB_0020),
                ],
              },
            ]
          : []),
        ...(user?.hasEvolutionaryResults && previousMetrics
          ? [
              {
                name: "Medición anterior",
                data: [
                  parsePercentage(previousMetrics.metrics.TM_0001 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0002 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0003 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0004 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0005 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0006 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0007 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0008 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0009 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0010 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0011 || -1),
                  parsePercentage(previousMetrics.metrics.TM_0012 || -1),
                ],
              },
            ]
          : []),
        {
          name: t("total"),
          data: [
            parsePercentage(total.tol_001),
            parsePercentage(total.tol_002),
            parsePercentage(total.tol_003),
            parsePercentage(total.tol_004),
            parsePercentage(total.tol_005),
            parsePercentage(total.tol_006),
            parsePercentage(total.tol_007),
            parsePercentage(total.tol_008),
            parsePercentage(total.tol_009),
            parsePercentage(total.tol_010),
            parsePercentage(total.tol_011),
            parsePercentage(total.tol_012),
          ],
        },
        ...Object.keys(data).map((key) => ({
          name: key,
          data: [
            parsePercentage(data[key].situations.tol_001),
            parsePercentage(data[key].situations.tol_002),
            parsePercentage(data[key].situations.tol_003),
            parsePercentage(data[key].situations.tol_004),
            parsePercentage(data[key].situations.tol_005),
            parsePercentage(data[key].situations.tol_006),
            parsePercentage(data[key].situations.tol_007),
            parsePercentage(data[key].situations.tol_008),
            parsePercentage(data[key].situations.tol_009),
            parsePercentage(data[key].situations.tol_010),
            parsePercentage(data[key].situations.tol_011),
            parsePercentage(data[key].situations.tol_012),
          ],
        })),
      ]}
    />
  );
};
