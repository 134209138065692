import {
  Button,
  Divider,
  Flex,
  Grid,
  NumberInput,
  Skeleton,
  Text,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  FormErrorText,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  TableTitleText,
} from "../../../components";
import { useAuth, useMedicionActual } from "../../../context";
import { openConfirmModal } from "@mantine/modals";
import { useEffect, useState } from "react";
import { clientService } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { TDistribucion } from "../../../types";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

export const DistribucionPage = () => {
  const { t } = useTranslation();
  const { medicionActualCookie } = useMedicionActual();
  const { authCallbackOnPageLoad, loading } = useAuth();
  const { code } = useParams();
  const navigate = useNavigate();

  const [distribucion, setDistribucion] = useState<TDistribucion>(null!);
  const [valuesSet, setValuesSet] = useState<boolean>(false);

  const verifyNumbers = (value: number) => {
    if (value === 0) return null;
    if (!value) {
      return "Campo requerido";
    }
    if (value < 0) {
      return "El valor debe ser mayor o igual a 0";
    }
    return null;
  };

  const form = useForm({
    initialValues: {
      hombres: 0,
      mujeres: 0,
      noBinarios: 0,
    },

    validate: {
      hombres: (value) => verifyNumbers(value),
      mujeres: (value) => verifyNumbers(value),
      noBinarios: (value) => verifyNumbers(value),
    },
  });

  const calculateTotal = () => {
    return form.values.hombres + form.values.mujeres + form.values.noBinarios;
  };

  useEffect(() => {
    if (!distribucion) {
      authCallbackOnPageLoad(() => {
        clientService.measurements.distribution
          .get(code)
          .then((res) => setDistribucion(res.data));
      });
    }
  }, [authCallbackOnPageLoad, code, distribucion]);

  useEffect(() => {
    if (distribucion && !valuesSet) {
      form.setValues({
        hombres: distribucion.men_population,
        mujeres: distribucion.women_population,
        noBinarios: distribucion.non_binary_population,
      });
      setValuesSet(true);
    }
  }, [distribucion, form, valuesSet]);

  const saveDistribucion = () => {
    if (distribucion) {
      if (calculateTotal() === 0) {
        form.setTouched({ hombres: true });
      } else if (
        !form.validate().hasErrors &&
        !distribucion.completed_distribution
      ) {
        openConfirmModal({
          title: (
            <Text color="genderlab-red.6" size="xl" fw={700}>
              ¡Estás a punto de guardar tu distribución!
            </Text>
          ),
          children: (
            <Text size="sm" color="dimmed">
              No podrás editar esta sección luego, por lo que es importante que
              revises muy bien esta información antes de guardarla. ¿Deseas
              confirmar los datos ingresados?
            </Text>
          ),
          labels: {
            confirm: "Sí",
            cancel: "Atrás",
          },
          onConfirm: () => {
            clientService.measurements.distribution
              .put(code, {
                men_population: form.values.hombres,
                women_population: form.values.mujeres,
                non_binary_population: form.values.noBinarios,
              })
              .then(() => {
                const route = `/medicion/${code}`;
                navigate(route, {
                  state: {
                    from: "dist",
                  },
                });
              })
              .catch((err) =>
                showNotification({
                  title: "Error",
                  message: err.response.data.message,
                })
              );
          },
        });
      }
    }
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[
          {
            link: "/mediciones",
            title: "Mediciones",
          },
          {
            link: `/medicion/${medicionActualCookie?.code}`,
            title: medicionActualCookie?.title || "",
          },
          {
            link: "",
            title: "Distribución",
          },
        ]}
      />

      <Grid>
        <Grid.Col span={12}>
          <Skeleton visible={!distribucion}>
            <TableTitleText>
              ¿Cuál es la distribución por género del personal?
            </TableTitleText>
            <Divider mt="sm" />
            {[
              { field: "hombres", name: "hombres" },
              { field: "mujeres", name: "mujeres" },
              {
                field: "noBinarios",
                name: " personas de género no binario",
              },
            ].map((item, idx) => (
              <Flex key={idx} justify="space-between" mt="sm">
                <Text size="sm">Número de {item.name}</Text>

                <NumberInput
                  variant="filled"
                  {...form.getInputProps(item.field)}
                  min={0}
                  disabled={distribucion?.completed_distribution}
                  style={{ width: "100px" }}
                />
              </Flex>
            ))}
            <Divider mt="sm" />
            <Flex justify="space-between" mt="sm">
              <Text size="sm">{t("total")}</Text>
              <Text size="sm" style={{ width: "100px" }}>
                {calculateTotal()}
              </Text>
            </Flex>
          </Skeleton>
        </Grid.Col>

        {calculateTotal() === 0 && form.isTouched() && (
          <Grid.Col span={12}>
            <FormErrorText>
              Debes tener al menos 1 persona en tu organización.
            </FormErrorText>
          </Grid.Col>
        )}

        <Grid.Col span={12}>
          <Flex justify="center">
            {distribucion?.completed_distribution ? (
              <Tooltip label="La distribución ya ha sido guardada.">
                <Button
                  color="green"
                  onClick={saveDistribucion}
                  data-disabled
                  sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
                >
                  Guardar
                </Button>
              </Tooltip>
            ) : (
              <Button
                color="green"
                onClick={saveDistribucion}
                disabled={loading || !distribucion}
              >
                Guardar
              </Button>
            )}
          </Flex>
        </Grid.Col>
      </Grid>
    </GenderLabAppShell>
  );
};
