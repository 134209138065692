import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { showNotification } from "@mantine/notifications";
import { useLocation, useNavigate } from "react-router-dom";

import { ApiWithoutToken, ApiWithToken, BASE_URL, tokenService, clientService, userService } from "../api";
import { TUserInfo } from "../types";
import { User } from "../classes";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

type AuthContextProps = {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  authRequest: (req: any, params?: any) => Promise<any>;
  user: User | null;
  tryToPersistSession: () => void;
  tryingToPersistSession: boolean;
  authCallbackOnPageLoad: (effect: React.EffectCallback) => void;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
};

const AuthContext = createContext<AuthContextProps>(null!);

type Props = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [tryingToPersistSession, setTryingToPersistSession] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { t } = useTranslation();

  const login = useCallback(
    async (email: string, password: string) => {
      setLoading(true);
      try {
        const response = await tokenService.generateTokens(email, password);
        tokenService.setTokensToLC(response.data);
        const [responseClientData, responseUserData] = await Promise.all([
          clientService.info.get(),
          userService.get(),
        ]);
        const userInfo = responseClientData.data as TUserInfo;
        userInfo.preferred_language = responseUserData.data.preferred_language as string;
        const newUser = new User(userInfo);
        i18n.changeLanguage(newUser.userPreferredLanguage);
        setUser(newUser);
        navigate("/inicio");
      } catch {
        showNotification({
          title: "Error",
          message: t("login.error"),
          color: "red",
        });
      }
      setLoading(false);
    },
    [navigate]
  );

  const logout = useCallback(() => {
    tokenService.removeTokens();
    navigate("/");
  }, [navigate]);

  const tryToPersistSession = useCallback(async () => {
    setLoading(true);
    setTryingToPersistSession(true);

    try {
      const [responseClientData, responseUserData] = await Promise.all([
        clientService.info.get(),
        userService.get(),
      ]);

      const userInfo = responseClientData.data as TUserInfo;
      userInfo.preferred_language = responseUserData.data.preferred_language as string;
      const newUser = new User(userInfo);

      i18n.changeLanguage(newUser.userPreferredLanguage);
      setUser(newUser);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "userDataReady",
        userEmail: tokenService.getUserFromToken()?.email,
        userTradename: newUser.tradeName,
        userAccessRole: tokenService.getUserFromToken()?.access_role,
      });

      if (location.pathname === "/") {
        navigate("/inicio");
      }
    } catch (error) {
      logout();
      console.error(error);
    } finally {
      setLoading(false);
      setTryingToPersistSession(false);
    }
  }, [location.pathname, logout, navigate]);


  const authRequest = useCallback(
    async (req: any, params?: any) => {
      try {
        if (params) {
          const response = await req(params);
          return response;
        } else {
          const response = req();
          return response;
        }
      } catch {
        logout();
      }
    },
    [logout]
  );

  useEffect(() => {
    if (location.pathname === "/" && tokenService.getTokenFromLC("refresh")) {
      tryToPersistSession();
    }
  }, [location.pathname, tryToPersistSession]);

  useLayoutEffect(() => {
    if (user) {
      i18n.changeLanguage(user.userPreferredLanguage);
      ApiWithToken.defaults.baseURL = `${BASE_URL}${user.userPreferredLanguage}`;
      ApiWithoutToken.defaults.baseURL = `${BASE_URL}${user.userPreferredLanguage}`;
    }
  }, [user]);

  const authCallbackOnPageLoad = useCallback(
    (effect: React.EffectCallback) => {
      if (!tryingToPersistSession) {
        setLoading(true);
        effect();
        setLoading(false);
      }
    },
    [tryingToPersistSession]
  );

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        loading,
        setLoading,
        authRequest,
        user,
        tryToPersistSession,
        tryingToPersistSession,
        authCallbackOnPageLoad,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export { AuthContext, AuthProvider };
export type { AuthContextProps };
